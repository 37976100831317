export function formatDate (time, format = 'YY-MM-DD hh:mm:ss') {
  const date = new Date(time)

  const year = date.getFullYear(),
    month = date.getMonth() + 1,//月份是从0开始的
    day = date.getDate(),
    hour = date.getHours(),
    min = date.getMinutes(),
    sec = date.getSeconds()

  const preArr = Array.apply(null, Array(10)).map(function (elem, index) {
    return '0' + index
  })

  return format.replace(/YY/g, year)
    .replace(/MM/g, preArr[month] || month)
    .replace(/DD/g, preArr[day] || day)
    .replace(/hh/g, preArr[hour] || hour)
    .replace(/mm/g, preArr[min] || min)
    .replace(/ss/g, preArr[sec] || sec)
}

export function beautifulTime (time) {
  const dateTimeStamp = new Date(time).getTime()

  const minute = 1000 * 60
  const hour = minute * 60
  const day = hour * 24

  const month = day * 30
  const year = month * 12
  const now = new Date().getTime()
  const diffValue = now - dateTimeStamp

  if (diffValue < 0) {
    return
  }

  const monthC = diffValue / month
  const weekC = diffValue / (7 * day)
  const dayC = diffValue / day
  const hourC = diffValue / hour
  const minC = diffValue / minute
  const yearC = diffValue / year
  if (yearC >= 1) {
    return '' + parseInt(yearC) + '年前'
  }

  let result
  if (monthC >= 1) {
    result = '' + parseInt(monthC) + '月前'
  } else if (weekC >= 1) {
    result = '' + parseInt(weekC) + '周前'
  } else if (dayC >= 1) {
    result = '' + parseInt(dayC) + '天前'
  } else if (hourC >= 1) {
    result = '' + parseInt(hourC) + '小时前'
  } else if (minC >= 1) {
    result = '' + parseInt(minC) + '分钟前'
  } else {
    result = '刚刚'
  }

  return result
}


export function getHello () {
  // 获取当前时间
  let timeNow = new Date()
  // 获取当前小时
  let hours = timeNow.getHours()
  // 设置默认文字
  let text = ``
  // 判断当前时间段
  if (hours >= 0 && hours <= 10) {
    text = `早上好`
  } else if (hours > 10 && hours <= 14) {
    text = `中午好`
  } else if (hours > 14 && hours <= 18) {
    text = `下午好`
  } else if (hours > 18 && hours <= 24) {
    text = `晚上好`
  }
  return text
}


export function getDaysFromToday (time) {
  const dateTimeStamp = new Date(time).getTime()

  const minute = 1000 * 60
  const hour = minute * 60
  const day = hour * 24

  const now = new Date().getTime()

  const diffValue = dateTimeStamp - now
  let x = diffValue / day
  return Math.ceil(x)
}
