<template>
    <div class="sign-in">
        <div class="sign-in-tips">{{hello}}</div>
        <el-button class="sign-in-button"
                   type="primary"
                   @click="signIn"
                   :disabled="this.hasSignIn"
        >
            {{signInTitle}}
        </el-button>
    </div>
</template>

<script>
  import {showSuccess} from '@/utils/Message'
  import {UserModel} from '@/model/UserModel'
  import {getHello} from '@/utils/Date'

  export default {
    name: 'SignIn',
    data () {
      return {
        loading: false,
        hasSignIn: true
      }
    },
    created () {
      if (this.$store.state.login.isLogin) {
        this.refreshSignInState()
      }
    },
    computed: {
      hello () {
        return getHello()
      },
      signInTitle: {
        get () {
          return this.hasSignIn ? '已签到' : '签到'
        },
        set (newVal) {
          this.value = newVal
        }
      },
    },
    methods: {
      refreshSignInState () {
        UserModel.getSignInState()
          .then((data) => {
            this.hasSignIn = data
          })
      },
      signIn () {
        UserModel.signIn()
          .then((data) => {
            this.loading = false
            this.signInTitle = '已签到'
            this.hasSignIn = true
            showSuccess('签到成功')
          })
          .catch(error => {
            this.loading = false
          })
      }
    },
    watch: {
      '$store.state.login.isLogin': function () {
        if (this.$store.state.login.isLogin) {
          this.refreshSignInState()
        }
      }
    }
  }
</script>

<style lang="scss">
    @import "src/assets/Sizes";
    @import "src/assets/Styles";
    @import "src/assets/Colors";

    .sign-in {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        padding: 20px;
        @include home-box-shadow;
        background-color: white;

        .sign-in-tips {
            font-size: 22px;
            font-weight: bold;
        }

        .sign-in-button {
            border-radius: 22px;
            margin-top: 10px;
            padding: 10px 30px;
        }
    }
</style>
