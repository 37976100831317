import httpClient from '@/http/Axios'

export class UserInfoModel {

  static uploadAvatar (params) {
    const data = new FormData()
    data.append('file', params.file)

    return httpClient.post('/file/upload', data)
      .then((data) => {
        return httpClient.post('/user-info/upload/avatar', {
          fileId: data.id
        })
      })
  }

  static getUserInfo () {
    return httpClient.get('/user-info/query')
  }

  static uploadInfo (param) {
    return httpClient.post('/user-info/update', param)
  }
}
