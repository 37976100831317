import axios from 'axios'
import store from '@/store/index'
import {showError} from '@/utils/Message'
import {UserModel} from '@/model/UserModel'
import {baseUrl} from '@/http/Url'
import {alienBaseUrl} from "@/http/AirshipAlienUrl";
import {doAppId} from "@/http/appId";

const httpClient = axios.create({
  baseURL: baseUrl()
})

httpClient.interceptors.request.use(
  (config) => {
    let token = store.state.login.token
    if (token) {
      config.headers.token = token
    }

    config.headers.appId = doAppId

    return config
  },
  function (error) {
    // 对请求错误做些什么
    console.log('失败' + error)
    return Promise.reject(error)
  }
)

httpClient.interceptors.response.use(
  response => {

    if (response.config.url === alienBaseUrl() + '/wechat/qr-code') {
        return response
    }

    if (response.data.code === 0) {
      return response.data.data
    }

    if (response.data.code === 10001 || response.data.code === 499001) {
      showError("请先登录")
      store.commit('showLoginBox')
      return Promise.reject("请先登录")
    }

    // token 过期
    if (response.data.code === 10002 || response.data.code === 499002) {

      if (response.config.url.indexOf(UserModel.tokenRefreshUrl) >= 0) {
        console.log('跳转登录页面')
        store.commit('joinToLoginBox')
        return Promise.reject('刷新失败')
      }

      console.log('刷新token')
      return UserModel.refreshToken({
        refreshToken: store.state.login.refreshToken
      }).then(data => {
        const config = response.config
        config.headers.token = data.token

        store.commit('refreshToken', data)
        console.log('重新获取数据')
        return httpClient(config)
      })
    }

    if (response.data.code !== 0) {
      showError(response.data.message)
      return Promise.reject(response.data.message)
    }

    return response
  }, error => {
    showError("服务器异常")
    return Promise.reject(error)
  }
)



export default httpClient

