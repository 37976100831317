<template>
    <div class="do-todo-detail-container">
        <el-dialog
                title="待办详情"
                :visible.sync="dialogVisible"
                :before-close="() => this.dialogVisible = false"
                width="30%">

            <div class="todo-detail-header">
                <div>{{createTime}}</div>
            </div>

            <div class="todo-content-container">

                <el-input
                    class="todo-detail-content"
                    type="textarea"
                    v-model="todoData.content"
                    maxlength="255"
                    show-word-limit
                    resize="none"
                    rows="8"
                    @blur="modifyTodoContent"
                >
                </el-input>
            </div>

            <el-divider v-if="isFinish" content-position="right">完成时间: {{modifyTime}}</el-divider>

            <span slot="footer">
                <el-button type="danger" @click="deleteTodo">删 除</el-button>
                <el-button type="info" @click="vetoTodo">否 决</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {formatDate} from '@/utils/Date'
import TodoModel from '@/model/TodoModel'
import {showCloseDialog, showPrompt} from '@/utils/Dialog'
import {showSuccess} from '@/utils/Message'

export default {
    name: 'DoTodoDetail',
    props: {
      list: Array
    },
    data () {
      return {
        todoData: {},
        contentSize: 0,
        dialogVisible: false
      }
    },
    computed: {
      createTime () {
        const creatTime = this.todoData.createTime
        return formatDate(creatTime, 'MM月DD日')
      },
      modifyTime () {
        const creatTime = this.todoData.modifyTime
        return formatDate(creatTime, 'MM月DD日')
      },
      isFinish () {
        return this.todoData.state === 'FINISH'
      },
    },
    methods: {
      deleteTodo () {
        showCloseDialog('确定要删除吗？')
          .then(() => {
            TodoModel.delete({
              id: this.todoData.id
            }).then((data) => {
              this.dialogVisible = false
              showSuccess('删除成功')
              this.$emit('deleteTodoSuccess', this.todoData.id)
            })
          })
      },
      vetoTodo () {
        showPrompt('确定要否决吗？', '为啥咧？')
          .then(({value}) => {
            TodoModel.veto({
              id: this.todoData.id,
              vetoReason: value
            }).then((data) => {
              this.dialogVisible = false
              showSuccess('否决成功')
              this.$emit('vetoTodoSuccess', this.todoData.id, value)
            })
          })
      },
      modifyTodoContent () {
        TodoModel.modify({
          id: this.todoData.id,
          newContent: this.todoData.content
        }).then((data) => {
            showSuccess("修改成功")
        })
      },
      showDetailContent (todoData) {
        this.dialogVisible = true
        this.todoData = todoData
      },
    }
  }
</script>

<style scoped lang="scss">
    .do-todo-detail-container {


        .todo-detail-header {
            display: flex;
            justify-content: space-between;
            width: 100%;
            font-size: 14px;
            margin-bottom: 10px;
            margin-left: 4px;

            img {
                width: 18px;
                height: 18px;
                cursor: pointer;
            }
        }

        .todo-detail-border {
            display: block;
            height: 1px;
            width: 100%;
            background-color: #DCDFE6;
        }

        .todo-content-container {
            height: 200px;
            position: relative;

            .todo-detail-content {
                height: 200px;
                font-size: 16px;
                text-align: start;
                margin-top: 6px;
                padding-left: 4px;
                padding-right: 4px;
                white-space: pre-wrap;
            }

            .todo-content-limit {
                position: absolute;
                right: 6px;
                bottom: 6px;
            }
        }

    }
</style>
