<template>
    <div class="card-content-item">
        <card-content-item-top :username="cardData.username"
                               :avatarUrl="cardData.userAvatarUrl"
                               :publish-time="cardData.publishTime"
                               :rightLabel="rightLabel"
                               @rightLabelClick="rightLabelClick"/>
        <div class="card-content">
            <div class="content">{{cardData.content}}</div>

            <pic-show-area :pics="cardData.picUrls"/>

            <div class="card-tag">
                <part-tag class="cumulative-day" :label="cumulativeDayLabel"/>

                <part-tag class="card-type" :label="tagLabel"/>
            </div>
        </div>

        <div class="navigation">
            <div class="cell">
                <praise :resource-id="this.cardData.id"
                        resource-type="CARD"
                        :praise-self="this.cardData.praiseSelf"
                        :old-praise-total="this.cardData.praiseTotal"
                />
            </div>
            <div class="cell" @click="showComment">
                <div class="comments">
                    <img alt="" src="../../../../assets/icons/comments.png"/>
                    <span v-show="!showCommentTextState">评论</span>
                    <span>{{commentCount}}</span>
                </div>
            </div>
            <div class="cell">
                <div class="share">
                    <img alt="" src="../../../../assets/icons/share.png"/>
                    <span>分享</span>
                </div>
            </div>
        </div>

        <comment v-if="commentState" :card-id="this.cardData.id" @addCommentSuccess="addCommentSuccess"/>
    </div>
</template>

<script>
  import CardContentItemTop from './CardContentItemTop'
  import PicShowArea from '@/pages/home/card/pic/PicShowArea'
  import PartTag from '@/components/PartTag'
  import {showSuccess} from '@/utils/Message'
  import Comment from '@/pages/home/card/comment/Comment'
  import ResourcePraiseModel from '@/model/ResourcePraiseModel'
  import Praise from '@/components/Praise'

  export default {
    name: 'CardContentItem',
    components: {Praise, Comment, PartTag, PicShowArea, CardContentItemTop},
    props: {
      cardData: {
        id: Number,
        content: String,
        type: Object,
        picUrls: Array,
        username: String,
        userAvatarUrl: String,
        publishTime: String,
        cumulativeDay: Number,
        commentTotal: Number,
        praiseTotal: Number,
        praiseSelf: Boolean
      },
      rightLabel: String,
    },
    data () {
      return {
        commentState: false,
        commentTotal: this.cardData.commentTotal,
      }
    },
    watch: {
      cardData (newData, oldData) {
        this.commentTotal = newData.commentTotal
      }
    },
    computed: {
      cumulativeDayLabel () {
        return '打卡' + this.cardData.cumulativeDay + '天'
      },
      showCommentTextState () {
        return this.commentTotal > 0
      },
      tagLabel () {
        switch (this.cardData.type) {
          case 'STUDY':
            return '学习'
          case 'SPORT':
            return '运动'
          case 'LAZY':
            return '上班摸鱼'
          case 'LEARNED_TODAY':
            return '今天学到了'
          case 'BUG':
            return 'BUG'
          case 'ADVICE':
            return '建议'
          default:
            return '未知'
        }
      },
      commentCount () {
        return this.commentTotal > 0 ? `(${this.commentTotal})` : ''
      },
      praiseCount () {
        return this.praiseTotal > 0 ? `(${this.praiseTotal})` : ''
      }
    },
    methods: {
      rightLabelClick () {
        this.$emit('rightLabelClick', this.cardData.id)
      },
      showComment () {
        this.commentState = !this.commentState
      },
      addCommentSuccess () {
        this.commentTotal = this.commentTotal + 1
      }
    },
  }
</script>

<style lang="scss">
    @import "src/assets/Sizes";
    @import "src/assets/Colors";

    .card-content-item {
        display: flex;
        flex-direction: column;
        width: $card-input-real-width;
        margin-top: $global-content-margin-top;
        padding: 30px 30px 0 30px;
        background-color: white;
        box-shadow: 2px 2px 2px #CECECE;
        overflow: hidden;

        .card-content {
            display: flex;
            flex-direction: column;
            max-width: 620px;
            margin-left: 70px;
            text-align: start;
            margin-top: 20px;
            white-space: pre-wrap;
            word-wrap: break-word;

            .card-tag {
                display: flex;

                .cumulative-day {
                    width: fit-content;
                    margin-right: 10px;
                    font-weight: bold;
                    background-color: coral;
                }

                .card-type {
                    width: fit-content;
                    color: $primary;
                    font-weight: bold;
                    background-color: rgba(42, 61, 78, 0.1);
                }
            }
        }

        .navigation {
            display: flex;
            margin-top: 20px;
            align-items: center;
            justify-content: space-between;
            border-top: 1px solid #CECECE;

            .cell {
                flex: 1;
                display: flex;
                justify-content: center;
                align-items: center;
                cursor: pointer;
                position: relative;
                height: 42px;

                &:not(:last-child)::after {
                    content: "";
                    width: 1px;
                    height: 2rem;
                    background-color: #CECECE;
                    position: absolute;
                    right: 0;
                }

                img {
                    width: 20px;
                    margin-right: 4px;
                }

                /*.praise {*/
                /*    display: flex;*/
                /*    align-items: center;*/
                /*    justify-content: center;*/
                /*}*/

                .comments {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }

                .share {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }
        }
    }
</style>
