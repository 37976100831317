import httpClient from '@/http/Axios'

export default class TodoModel {

  static getContent (param) {
    return httpClient.post('/todo/query/today', param)
  }

  static add (param) {
    return httpClient.post('/todo/add', param)
  }

  static finish (param) {
    return httpClient.post('/todo/finish', param)
  }

  static undo (param) {
    return httpClient.post('/todo/undo', param)
  }

  static delete (param) {
    return  httpClient.post('/todo/delete', param)
  }

  static veto (param) {
    return  httpClient.post('/todo/veto', param)
  }

  static modify (param) {
    return httpClient.post('/todo/modify', param)
  }

  static focus (param) {
    return httpClient.post("/todo/focus", param)
  }

  static unfocused (param) {
    return httpClient.post("/todo/unfocused", param)
  }

  static getFinish (param) {
    return httpClient.post("/todo/query/finish", param)
  }
}
