<template>
    <div class="do-day-matter-container">

        <div class="day-matter-content" v-infinite-scroll="loadMore">
            <div v-for="(data) in dayMatterData" :key="data.id">
                <do-day-matter-item :count-down-data="data" @click.native="showDayMatterEdit(data)"/>
            </div>
        </div>

        <div class="empty-placeholder" v-show="isEmpty">
            没有倒数日？
        </div>

        <i class="add-day-matter-item el-icon-circle-plus-outline" @click="showAddDayMatter"/>

        <do-day-matter-dialog ref="dialog"
                              @addSuccess="addSuccess"
                              @updateSuccess="updateSuccess"
                              @deleteSuccess="refreshDayMatterList"
                              @hideSuccess="refreshDayMatterList"
        />
    </div>
</template>

<script>
import DoDayMatterItem from '@/pages/home/day-matter/DoDayMatterItem'
import ColumnInput from '@/components/ColumnInput'
import {showError, showSuccess} from '@/utils/Message'
import DayMatterModel from '@/pages/home/day-matter/model/DayMatterModel'
import {deleteByKey, updateByKey} from '@/utils/Array'
import RowInput from '@/components/RowInput'
import {showCloseDialog} from '@/utils/Dialog'
import DoDayMatterDialog from '@/components/DoDayMatterDialog'

const ADD = 'add'
const UPDATE = 'update'

export default {
    name: 'DoDayMatter',
    components: {DoDayMatterDialog, RowInput, ColumnInput, DoDayMatterItem},
    data () {
        return {
            dayMatterData: [],
            page: 0,
            size: 5,
            dayMatterDataTotal: 0,
            state: true,
            innerVisible: false
        }
    },
    mounted () {
        this.initDayMatter()
    },
    computed: {
        isLogin () {
            return this.$store.state.login.isLogin
        },
        isEmpty () {
            return this.dayMatterData.length === 0
        },
    },
    methods: {
        initDayMatter () {
            if (!this.isLogin) {
                return
            }

            DayMatterModel.queryShow({
                'page': this.page,
                'size': this.size
            }).then(pageResponse => {
                this.page += 1
                this.dayMatterData = pageResponse.list
                this.dayMatterDataTotal = pageResponse.total
            })
        },
        showAddDayMatter () {
            this.$refs.dialog.showAdd()
        },
        showDayMatterEdit (data) {
            this.$refs.dialog.showEdit(data)
        },
        addSuccess (newData) {
            this.dayMatterData.unshift(newData)
        },
        updateSuccess (newData) {
            updateByKey(
                this.dayMatterData,
                (task) => task.id === newData.id,
                (i) => {
                    this.dayMatterData[i] = newData
                    this.dayMatterData = [...this.dayMatterData]
                }
            )
        },
        loadMore () {
            if (this.dayMatterData.length === this.dayMatterDataTotal) {
                console.log('no more dayMatterData task')
                return
            }

            DayMatterModel.queryShow({
                'page': this.page,
                'size': this.size
            }).then(pageResponse => {
                this.page += 1
                this.dayMatterData = this.dayMatterData.concat(pageResponse.list)
            })
        },
        refreshDayMatterList (editDayMatterId) {
            deleteByKey(this.dayMatterData, (data) => data.id === editDayMatterId)
        }
    }
}
</script>

<style scoped lang="scss">
@import "src/assets/Sizes";
@import "src/assets/Styles";
@import "src/assets/Colors";

.do-day-matter-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 180px;
    padding: 10px;
    @include home-box-shadow;
    margin-top: $global-content-margin-top;
    background-color: white;

    .day-matter-content {
        max-height: 154px;
        overflow: auto;
    }

    .empty-placeholder {
        flex: 1;
        color: #999999;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .add-day-matter-item {
        font-size: 32px;
        cursor: pointer;
        margin-top: 6px;
    }
}
</style>
