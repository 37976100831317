import httpClient from '@/http/Axios'

export default class ProgressModel {

  static query (params) {
    return httpClient.post('/progress-task/query', params)
  }

  static queryFocus (params) {
    return httpClient.post('/progress-task/query/focus', params)
  }

  static add (params) {
    return httpClient.post('/progress-task/add', params)
  }

  static increment(params) {
    return httpClient.post('/progress-task/increment', params)
  }

  static decrease(params) {
    return httpClient.post('/progress-task/decrease', params)
  }

  static delete(params) {
    return httpClient.post('/progress-task/delete', params)
  }

  static deleteBatch(params) {
    return httpClient.post('/progress-task/delete/batch', params)
  }
  static update(params) {
    return httpClient.post('/progress-task/update', params)
  }

  static move(params) {
    return httpClient.post('/progress-task/move', params)
  }

  static moveBatch(params) {
    return httpClient.post('/progress-task/move/batch', params)
  }

  static focus(params) {
    return httpClient.post('/progress-task/focus', params)
  }

  static unfocused(params) {
    return httpClient.post('/progress-task/unfocused', params)
  }
}
