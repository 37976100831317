import { Message } from 'element-ui';

export function showError (message) {
  Message.error(message)
}

export function showTip (message) {
  Message.info(message);
}

export function showSuccess (message) {
  Message.success(message)
}
