<template>
    <div class="notice-detail-container">
        <card-content-item :card-data="cardData" right-label="关注"/>
    </div>
</template>

<script>
  import CardContentItem from '@/pages/home/card/content/CardContentItem'
  import CardModel from '@/model/CardModel'

  export default {
    name: 'NoticeDetail',
    components: {CardContentItem},
    data () {
      return {
        cardData: {}
      }
    },
    created () {
      this.loadCardDetail()
    },
    methods: {
      loadCardDetail () {
        CardModel.getDetail({
          noticeId: this.noticeId,
          cardId: this.cardId
        }).then(data => {
          this.cardData =  data
        })
      }
    },
    computed: {
      noticeId () {
        return this.$route.query.noticeId
      },
      cardId() {
        return this.$route.query.cardId
      }
    }
  }
</script>

<style scoped lang="scss">
    .notice-detail-container {

    }

</style>
