import httpClient from '@/http/Axios'

export default class BbTodoModel {

  static getContent (params) {
    return httpClient.get('/todo/query/all', {params: params})
  }

  static move(params) {
    return httpClient.post('/todo/move', params)
  }

  static moveBatch(params) {
    return httpClient.post('/todo/move/batch', params)
  }

  static deleteBatch(params) {
    return httpClient.post('/todo/delete/batch', params)
  }
}
