<template>
    <div class="personal-setting">
        <div class="personal-setting-title">个人设置</div>

        <personal-setting-item ref="username"/>

        <div class="personal-setting-introduction">
            <div class="name">个人介绍</div>
            <el-input
                    type="textarea"
                    placeholder="不说点啥吗？"
                    v-model="introduction"
                    maxlength="100"
                    show-word-limit
                    resize="none"
            >
            </el-input>
        </div>

        <el-button type="primary" :loading="loading" @click="uploadUserInfo">更新</el-button>
    </div>
</template>

<script>
  import PersonalSettingItem from '@/pages/personal/setting/PersonalSettingItem'
  import {UserInfoModel} from '@/model/UserInfoModel'
  import {UserModel} from "@/model/UserModel";

  export default {
    name: 'PersonalSetting',
    components: {PersonalSettingItem},
    data () {
      return {
        loading: false,
      }
    },
    computed: {
      introduction: {
        get() {
          return this.$store.state.login.introduction
        },
        set(newVal) {
          this.$store.commit('updateIntroduction', newVal)
        }
      }
    },
    methods: {
      uploadUserInfo () {
        const name = this.$refs.username.username
        UserModel.updateName({
            name: name,
        }).then((data) => {
            this.$store.commit('updateUsername', name)
        }).catch((error) => {
            console.log(error)
        })

        UserInfoModel.uploadInfo({
          introduction: this.introduction
        }).then((data) => {
          this.$store.commit('updateIntroduction', data.introduction)
        }).catch((error) => {
          console.log(error)
        })
      }
    },
  }
</script>

<style lang="scss">
    .personal-setting {
        height: 340px;
        display: flex;
        flex-direction: column;
        align-items: flex-start;
        padding: 30px;

        .personal-setting-title {
            font-size: 24px;
            font-weight: bold;
            margin-bottom: 20px;
        }

        .personal-setting-introduction {
            display: flex;
            margin-top: 10px;

            .name {
                margin-right: 20px;
            }

            .el-textarea {
                width: 400px;

                .el-textarea__inner {
                    height: 130px;
                }
            }
        }

        .el-button {
            margin-top: 40px;
            margin-left: 84px;
        }
    }
</style>
