<template>
    <viewer class="pic-show-area" :images="pics">
        <div v-for="(picUrl, index) in pics" :key="index">
            <div class="pic" v-show="showPics">
                <img class="pic-content" :src="picUrl" alt=""/>
            </div>
        </div>
    </viewer>
</template>

<script>

  export default {
    name: 'PicShowArea',
    props: {
      pics: Array
    },
    methods: {
      showPics () {
        return this.pics.length > 0
      },
    }
  }
</script>

<style scoped lang="scss">
    @import "src/assets/Sizes";
    @import "src/assets/Styles";

    .pic-show-area {
        @include pic-area;
    }
</style>
