<template>
    <div class="bb-sidebar-container">

        <div class="sidebar-title-area">
            <span>{{ menuTitle }}</span>
            <i v-if="showAddMenuIcon"
               class="el-icon-circle-plus-outline"
               @click="addMenuClick"
            />
        </div>

        <el-menu
            :default-active="defaultActive"
            text-color="#2c3e50"
            @select="changeMenuStyle"
            :router="isRouter"
        >
            <div v-for="(menu) in menus" :key="menu.id">
                <el-menu-item :index="menu.id.toString()">
                    <template slot="title">
                        <div class="menu-item-select-background"
                             :class="{select:menuSelectId === menu.id.toString()}">
                            <span>{{ menu.name }}</span>
                            <i v-show="showMenuItemEditIcon && menu.id !== '0'"
                               class="el-icon-edit-outline"
                               @click="editMenuItemClick(menu)"
                            />
                        </div>
                    </template>
                </el-menu-item>
            </div>
        </el-menu>

    </div>
</template>

<script>

export default {
    name: 'DoSidebar',
    props: {
        menuTitle: String,
        showAddMenuIcon: {
            type: Boolean,
            default: true
        },
        menus: Array,
        defaultActive: {
            type: String,
            default: '0'
        },
        isRouter: {
            type: Boolean,
            default: false
        },
        addMenuClick: Function,
        editMenuItemClick: Function,
        showMenuItemEditIcon: {
            type: Boolean,
            default: false
        },
        menuItemClick: Function
    },
    data () {
        return {
            menuSelectId: this.defaultActive,
        }
    },
    methods: {
        changeMenuStyle (curMenuId) {
            if (curMenuId === this.menuSelectId) {
                return
            }

            this.menuSelectId = curMenuId
            this.menuItemClick && this.menuItemClick(curMenuId)
        },
    }
}
</script>

<style scoped lang="scss">
@import "src/assets/Colors";

.bb-sidebar-container {
    width: 180px;
    height: 800px;
    padding-left: 10px;
    padding-top: 20px;
    background-color: white;

    .sidebar-title-area {
        display: flex;
        align-items: center;
        justify-content: space-evenly;

        span {
            font-size: 18px;
            font-weight: bold;
        }

        .el-icon-circle-plus-outline {
            font-size: 24px;
            cursor: pointer;
        }
    }

    .el-menu {
        margin-top: 14px;
    }

    .el-menu-item {
        text-align: left;
        margin-bottom: 6px !important;
        font-weight: bold !important;

        &:hover {
            animation: myTranslateX 0.8s;
            background-color: rgba(0, 0, 0, 0) !important;
        }

        &.is-active {
            background-color: rgba(0, 0, 0, 0) !important;
        }

        @keyframes myTranslateX {
            0% {
                transform: translateX(4px);
            }
            100% {
                transform: translateX(0);
            }
        }
    }

    .menu-item-select-background {
        padding-left: 10px;
        border-radius: 12px;
        display: flex;
        align-items: center;
        justify-content: space-between;

        &:hover {
            background-color: #f3f3f3;
        }

        &.select {
            color: white;
            background-color: $primary;
        }
    }
}
</style>
