<template>
    <div class="row-input-container">
        <span class="title">{{ title }}</span>
        <el-input
            v-bind="$attrs"
            type="text"
            :placeholder="titlePlaceholder"
            :value="content"
            :maxlength="maxlength"
            show-word-limit
            :style="inputStyle"
            @input="handleInput"
        >
        </el-input>
    </div>
</template>

<script>
export default {
    name: 'RowInput',
    model: {
        prop: 'content',
    },
    props: {
        title: String,
        titlePlaceholder: String,
        content: {
            type: [Number, String],
        },
        inputWidth: {
            type: Number,
            default: 270,
        },
        maxlength: [String, Number]
    },
    computed: {
        inputStyle() {
            return {
                width: `${this.inputWidth}px`,
            };
        },
    },
    methods: {
        handleInput ($event) {
            this.$emit('input', $event)
        },
    }
}
</script>

<style lang="scss">
.row-input-container {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-bottom: 10px;

    .title {
        font-size: 16px;
        font-weight: bold;
        text-align: start;
        margin-right: 10px;
    }
}
</style>
