<template>
    <div class="column-input-container">
        <div class="title">{{ title }}</div>
        <el-input
            v-bind="$attrs"
            :type="type"
            :placeholder="titlePlaceholder"
            :value="content"
            :style="inputStyle"
            :maxlength="maxlength"
            show-word-limit
            @input="handleInput"
            :required="true"
        >
        </el-input>
    </div>
</template>

<script>
export default {
    name: 'ColumnInput',
    model: {
        prop: 'content',
    },
    props: {
        width: {
            type: [Number, String],
            default: 270,
        },
        type: {
            type: String,
            default: 'text'
        },
        title: String,
        titlePlaceholder: String,
        content: {
            type: [String, Number],
        },
        maxlength: [String, Number]
    },
    data () {
        return {}
    },
    computed: {
        inputStyle () {
            return {
                width: `${this.width}px`,
            }
        },
    },
    methods: {
        handleInput ($event) {
            this.$emit('input', $event)
        },
    }
}
</script>

<style scoped lang="scss">
.column-input-container {
    display: flex;
    flex-direction: column;

    .title {
        font-size: 16px;
        font-weight: bold;
        text-align: start;
        margin-bottom: 6px;
    }
}
</style>
