<template>
    <div class="progress-item-container">

        <div class="progress-item-content-container" @click="showProgressDetail">

            <div>
                <do-overflow-tooltip
                    class="progress-item-title"
                    :content="progressData.title"
                    :class-name="['content-title']"
                />

                <div class="progress-item-remark">{{progressData.remark}}</div>
            </div>

            <div class="content-progress-txt">{{this.progressData.progress}}/{{progressData.total}}{{progressData.unit}}</div>

            <el-progress :text-inside="true" :stroke-width="26" :percentage="percentage"/>

            <div class="progress-time">{{progressCreateTime}}</div>

            <div class="content-right">
                <i class="el-icon-circle-plus-outline" @click="addProgress" @click.stop=""/>
                <i class="el-icon-remove-outline" @click="removeProgress" @click.stop=""/>
                <i class="el-icon-circle-close" @click="deleteProgress" @click.stop=""/>
            </div>
        </div>

    </div>
</template>

<script>
  import ProgressModel from '@/model/ProgressModel'
  import {formatDate} from '@/utils/Date'
  import DoOverflowTooltip from '@/components/DoOverflowTooltip'

  export default {
    name: 'DoProgressItem',
    components: {DoOverflowTooltip},
    props: {
      progressData: Object
    },
    data () {
      return {
        percentage: 0,
      }
    },
    watch: {
      progressData (newProgressData) {
        this.refreshPercentage()
      },
    },
    created () {
      this.refreshPercentage()
    },
    methods: {
      addProgress () {
        if (this.progressData.progress >= this.progressData.total) {
          return
        }

        ProgressModel.increment({
          'progressTaskId': this.progressData.id
        }).then(data => {
          this.progressData.progress += parseInt(this.progressData.increment)
          this.refreshPercentage()
        })
      },
      removeProgress () {
        if (this.progressData.progress <= 0) {
          return
        }

        ProgressModel.decrease({
          'progressTaskId': this.progressData.id
        }).then(data => {
          this.progressData.progress -= parseInt(this.progressData.increment)
          this.refreshPercentage()
        })
      },
      refreshPercentage () {
        this.percentage = Math.round(100 / this.progressData.total * this.progressData.progress)
      },
      showProgressDetail () {
        this.$emit('showProgressAddItem', this.progressData)
      },
      deleteProgress () {
        this.$emit('deleteProgress', this.progressData.id)
      },
    },
    computed: {
      progressCreateTime () {
        const creatTime = this.progressData.createTime
        return formatDate(creatTime, 'MM月DD日')
      },
    }
  }
</script>

<style scoped lang="scss">
    @import "src/assets/Styles";

    .progress-item-container {
        display: flex;
        flex-direction: column;
        padding-bottom: 8px;
        padding-top: 8px;
        margin-bottom: 10px;

        &:hover {
            background-color: #f6f6f6;
            cursor: pointer;
        }

        .progress-header {
            width: 100%;
            height: 36px;
            display: flex;
            justify-content: space-between;
            align-items: center;
            font-size: 14px;
            border-bottom: 1px solid #CECECE;
            margin-bottom: 6px;

            img {
                width: 20px;
                height: 20px;
                cursor: pointer;
                margin-left: 10px;
            }
        }

        .progress-item-content-container {
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: space-between;

            .progress-item-title {
                width: 300px;
            }

            .progress-item-remark {
                width: 300px;
                @include ellipsis-txt
            }

            .el-progress {
                width: 200px;
            }

            .content-title {
                font-weight: bold;
                font-size: 20px;
            }

            .content-progress-txt {
                width: 100px;
                font-size: 14px;
                margin-right: 10px;
            }

            .progress-time {
                width: 100px;
                text-align: center;
                margin-left: 10px;
            }

            .content-right {
                width: 150px;
                display: flex;
                justify-content: center;
                margin-left: 10px;

                i {
                    font-size: 32px;
                    cursor: pointer;
                }
            }
        }
    }
</style>
