import {showError} from '@/utils/Message'

export function clipboardAction (e, picAction, otherAction = () => {
}) {
  const items = (e.clipboardData || window.clipboardData).items
  let file = null

  if (!items || items.length === 0) {
    showError('当前浏览器不支持本地')
    return
  }
  // 搜索剪切板items
  for (let i = 0; i < items.length; i++) {
    if (items[i].type.indexOf('image') !== -1) {
      file = items[i].getAsFile()
      break
    }
  }
  if (!file) {
    otherAction()
    return
  }

  //cancel paste, 移除粘贴的图片
  e.preventDefault()
  picAction(file)
}
