
export function sortDescByKey (ary, key) {
  return sortAscByKey(ary, key).reverse()
}

export function sortAscByKey (ary, key) {
  return ary.sort((a, b) => {
    let x = a[key]
    let y = b[key]
    return ((x < y) ? -1 : (x > y) ? 1 : 0)
  })
}
