<template>
    <div class="notice-container" v-loading="loading" v-infinite-scroll="loadMoreNotice">
        <el-tabs v-model="activeName" @tab-click="handleTabClick">
            <el-tab-pane
                    v-for="(header, index) in navigationHeaders" :key="index"
                    :label="header.label"
                    :name="header.name"
            >
                <div v-for="(data, index) in noticeData" :key="index">
                    <notice-item :notice-data="data"/>
                </div>

                <div v-show="showEmpty">没有通知～</div>

                <div class="no-more" v-show="_showNoMore">我们是有底线的 ：)</div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
  import NoticeItem from '@/pages/notice/NoticeItem'
  import NoticeModel from '@/model/NoticeModel'

  export default {
    name: 'Notice',
    components: {NoticeItem},
    data () {
      return {
        activeName: 'ALL',
        navigationHeaders: [
          {
            label: '全部',
            name: 'ALL',
          },
          {
            label: '点赞',
            name: 'PRAISE',
          },
          {
            label: '评论',
            name: 'COMMENT',
          },
        ],
        noticeData: [],
        noticeTotal: 0,
        page: 0,
        size: 10,
        showNoMore: false,
        loading: true
      }
    },
    created () {
      this.showNoMore = false
      this.loadNotice()
    },
    methods: {
      handleTabClick (tab) {
        this.page = 0
        this.loading = true
        this.showNoMore = false

        switch (tab.name) {
          case 'ALL':
            this.loadNotice('')
            break
          case 'PRAISE':
            this.loadNotice('PRAISE')
            break
          case 'COMMENT':
            this.loadNotice('COMMENT')
            break
        }
      },
      loadNotice (type) {
        NoticeModel.query({
          page: this.page,
          size: this.size,
          type: type
        }).then(data => {
          this.loading = false
          this.noticeTotal = data.total
          this.noticeData = data.list
        }).catch((error) => {
          this.loading = false
        })
      },
      loadMoreNotice () {
        if (this.noticeData.length === this.noticeTotal) {
          console.log('no more notice data')
          this.showNoMore = true
          return
        }

        this.page += 1
        NoticeModel.query({
          page: this.page,
          size: this.size
        }).then((data) => {
          this.loading = false
          this.noticeTotal = data.total
          this.noticeData = this.noticeData.concat(data.list)
        }).catch((error) => {
          this.loading = false
        })
      }
    },
    computed: {
      showEmpty () {
        return this.noticeData.length <= 0
      },
      _showNoMore () {
        return this.showNoMore && this.total !== 0 && this.noticeData.length !== 0
      }
    }
  }
</script>

<style lang="scss">
    @import "../../assets/Sizes";
    @import "../../assets/Styles";

    .notice-container {
        width: $global-content-width;
        min-height: 400px;
        margin-top: 12px;
        background-color: white;
        overflow: auto;

        .el-tabs__header {
            @include custom-tab
        }

        .el-tabs__content {
            min-height: 200px;

            .no-more {
                margin-bottom: 20px;
            }
        }
    }
</style>
