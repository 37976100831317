<template>
    <div class="bb-luck-draw-container">

        <do-sidebar
            :show-add-menu-icon="false"
            :menus="menus"
            :default-active="menuSelectViewPath"
            :is-router="true"
        />

        <router-view/>
    </div>
</template>

<script>
import ColumnInput from '@/components/ColumnInput'
import DoSidebar from '@/components/DoSidebar'

export default {
    name: 'BbLuckDrawManagement',
    components: {DoSidebar, ColumnInput},
    data () {
        return {
            menus: [
                {
                    id: '/bulletin-board/bb-luck-draw-management/bb-luck-draw',
                    name: '幸运抽奖',
                },
                {
                    id: '/bulletin-board/bb-luck-draw-management/bb-gift-management',
                    name: '礼物管理',
                },
                {
                    id: '/bulletin-board/bb-luck-draw-management/bb-winning-record',
                    name: '中奖记录',
                },
            ],
            menuSelectViewPath: this.$route.path.toString(),
        }
    },

}
</script>

<style scoped lang="scss">

.bb-luck-draw-container {
    display: flex;
    height: 820px;
    background-color: white;
}
</style>
