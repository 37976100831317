<template>
    <el-dialog
            class="el-dialog-add-container"
            title="添加待办"
            :visible.sync="dialogVisible"
            width="500px"
            :before-close="() => this.dialogVisible = false"
    >

        <div class="dialog-content-container">
            <div class="flag-container">
                <div class="flag-title">专注</div>
                <el-switch
                        v-model="flag"
                        active-value="FOCUS"
                        :inactive-value="null"
                        active-color="#2c3e50"
                        inactive-color="#dcdfe6"
                >
                </el-switch>
            </div>

            <el-input
                      ref="todoInput"
                      v-model="content"
                      placeholder="请输入待办内容"
                      maxlength="255"
                      type="textarea"
                      rows="8"
                      clearable
                      show-word-limit
                      resize="none"
            />
        </div>

        <span slot="footer" class="dialog-footer">
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="confirm">确 定</el-button>
        </span>
    </el-dialog>
</template>

<script>
  import {showError} from '@/utils/Message'
  import TodoModel from '@/model/TodoModel'

  export default {
    name: 'BbTodoAddDialog',
    data () {
      return {
        dialogVisible: false,
        content: '',
        folderId: 0,
        flag: 'FOCUS',
      }
    },
    methods: {
      showDialogContent (content) {
        this.dialogVisible = true
        this.content = content
        this.folderId = 0
        this.$nextTick(() => {
          this.$refs.todoInput.focus()
        })
      },
      showDialog (folderId) {
        this.dialogVisible = true
        this.folderId = folderId
      },
      confirm () {
        if (this.content === '') {
          showError('内容不能为空')
          return
        }

        TodoModel.add({
          content: this.content,
          flag: this.flag,
          folderId: this.folderId
        }).then((data) => {
          this.dialogVisible = false
          this.$emit('addTodoContentSuccess', data)
          this.content = ''
        })
      },
    }
  }
</script>

<style scoped lang="scss">
    .dialog-content-container {
        display: flex;
        flex-direction: column;
        align-items: flex-start;

        .flag-container {
            display: flex;
            align-items: center;
            padding-bottom: 10px;

            .flag-title {
                text-align: left;
                font-size: 16px;
                font-weight: bold;
                margin-right: 10px;
            }
        }
    }
</style>
