<template>
    <div class="bb-winning-record-container">
        <div class="bb-winning-record-content">

            <el-table
                ref="multipleTable"
                :data="tableData"
                stripe
                height="300"
                style="width: 100%">

                <el-table-column
                    align="center"
                    label="名称"
                    prop="name">
                </el-table-column>

                <el-table-column
                    align="center"
                    label="中奖概率"
                    prop="prob">
                </el-table-column>

                <el-table-column
                    align="center"
                    property="createTime"
                    label="日期"
                    width="200">
                    <template slot-scope="scope">
                        {{ formatCreateTime(scope.row.createTime) }}
                    </template>
                </el-table-column>

                <el-table-column
                    align="center"
                    label="是否兑现"
                    width="100">
                    <template slot-scope="scope">
                        <el-switch
                            :value="scope.row.state"
                            :active-value="activeFlagValue"
                            :inactive-value="inactiveFlagValue"
                            :active-color="primaryColor"
                            inactive-color="#dcdfe6"
                            @change="changeState($event, scope.row)"
                        >
                        </el-switch>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination
                background
                @current-change="getWinningRecords"
                :current-page.sync="page"
                :page-size="size"
                layout="total, prev, pager, next"
                :total="total"
            >
            </el-pagination>
        </div>
    </div>
</template>

<script>
import BbWinningRecordModel from '@/pages/bulletin-board/luck-draw/model/BbWinningRecordModel'
import {formatDate} from '@/utils/Date'
import {getByKey} from '@/utils/Array'
import WinningRecordModel from '@/pages/bulletin-board/luck-draw/page/WinningRecordModel'

export default {
    name: 'BbWinningRecord',
    data () {
        return {
            tableData: [],
            total: 0,
            page: 1,
            size: 20,
            activeFlagValue: 'REALIZED',
            inactiveFlagValue: 'PENDING',
            primaryColor: '#2c3e50',
        }
    },
    mounted () {
        this.getWinningRecords(1)
    },
    methods: {
        formatCreateTime (createTime) {
            return formatDate(createTime)
        },
        getWinningRecords (page) {
            BbWinningRecordModel.getContent({
                page: page - 1,
                size: this.size,
            }).then((data) => {
                this.total = data.total
                this.tableData = data.list
            })
        },
        changeState (newState, row) {
            this.updateRecordState(newState, row)
        },
        updateRecordState (newState, row) {
            WinningRecordModel.update({
                id: row.id,
                state: newState
            }).then(() => {
                const curData = getByKey(this.tableData, (data) => data.id === row.id)
                curData.state = newState
            })
        }
    }
}
</script>

<style scoped lang="scss">
.bb-winning-record-container {
    flex: 1;

    .bb-winning-record-content {
        display: flex;
        flex-direction: column;
        height: 820px;

        .el-pagination {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
}

</style>
