<template>
    <div class="bb-day-matter-container">
        <el-table
            ref="multipleTable"
            @row-click="showEditDialog"
            :data="tableData"
            stripe
            height="800"
            style="width: 100%">

            <el-table-column
                align="center"
                label="名称"
                prop="title">
            </el-table-column>

            <el-table-column
                align="center"
                prop="state"
                label="状态"
                width="70"
            >
                <template slot-scope="scope">
                    <div class="state-container" :class="[scope.row.state]">
                        {{ formatState(scope.row.state) }}
                    </div>
                </template>
            </el-table-column>

            <el-table-column
                align="center"
                property="date"
                label="天数"
                width="200">
                <template slot-scope="scope">
                    <div class="day-matter-day">
                        <span class="small-text">{{ dayPrefix(scope.row.date) }}</span>
                        <span class="day"
                              :class="{active:dayPrefix(scope.row.date)==='已经'}">{{ dayStr(scope.row.date) }}</span>
                        <span class="small-text">天</span>
                    </div>
                </template>
            </el-table-column>

            <el-table-column
                align="center"
                property="date"
                label="日期"
                width="200">
                <template slot-scope="scope">
                    {{ formatDate(scope.row.date) }}
                </template>
            </el-table-column>

        </el-table>

        <do-pagination
            ref="doPagination"
            :request-method=requestMethod
            @updateData="(data) => this.tableData = data"
        />

        <do-day-matter-dialog ref="dialog"
                              @updateSuccess=updateSuccess
                              @deleteSuccess="deleteSuccess"
                              @hideSuccess="hideSuccess"
                              @showSuccess="showSuccess"
        />
    </div>
</template>

<script>
import {formatDate, getDaysFromToday} from '@/utils/Date'
import DoPagination from '@/components/DoPagination'
import DayMatterModel from '@/pages/home/day-matter/model/DayMatterModel'
import {deleteByKey, getByKey, updateByKey} from '@/utils/Array'
import DoDayMatterDialog from '@/components/DoDayMatterDialog'
import {showSuccess} from '@/utils/Message'

export default {
    name: 'BbDayMatter',
    components: {DoDayMatterDialog, DoPagination},
    data () {
        return {
            tableData: [],
            stateOptions: [
                {
                    label: '显示',
                    key: 'SHOW'
                },
                {
                    label: '隐藏',
                    key: 'HIDE'
                }
            ]
        }
    },
    mounted () {
        this.$refs.doPagination.changeData(1)
    },
    methods: {
        dayPrefix (date) {
            return getDaysFromToday(date) >= 0 ? '还有' : '已经'
        },
        dayStr (date) {
            return Math.abs(getDaysFromToday(date))
        },
        formatDate (date) {
            return formatDate(date, 'YY年 MM月DD日')
        },
        formatState (selectState) {
            const curState = getByKey(this.stateOptions, (state) => state.key === selectState)
            return curState.label
        },
        requestMethod () {
            return DayMatterModel.query
        },
        showEditDialog (row, column, event) {
            this.$refs.dialog.showEdit(row)
        },
        updateSuccess (newData) {
            updateByKey(
                this.tableData,
                (dayMatter) => dayMatter.id === newData.id,
                (i) => {
                    this.tableData[i] = newData
                    this.tableData = [...this.tableData]
                }
            )
        },
        deleteSuccess (dayMatterId) {
            deleteByKey(this.tableData, (data) => data.id === dayMatterId)
        },
        hideSuccess (dayMatterId) {
            const curDayMatter = getByKey(this.tableData, (data) => data.id === dayMatterId)
            curDayMatter.state = 'HIDE'
        },
        showSuccess (dayMatterId) {
            const curDayMatter = getByKey(this.tableData, (data) => data.id === dayMatterId)
            curDayMatter.state = 'SHOW'
        }
    }
}
</script>

<style scoped lang="scss">
@import "src/assets/Colors";

.bb-day-matter-container {
    flex: 1;

    .state-container {
        text-align: center;
        border-radius: 4px;
        color: white;

        &.SHOW {
            background-color: #2c3e50;
        }

        &.HIDE {
            background-color: #cecece;
        }
    }

    .day-matter-day {

        .day {
            height: 16px;
            line-height: 16px;
            font-weight: bold;
            margin-left: 4px;
            margin-right: 4px;
            font-size: 16px;
            color: white;
            border-radius: 4px;
            padding: 2px 4px;
            background-color: $primary;

            &.active {
                background-color: coral;
            }
        }


        .small-text {
            font-size: 14px;
            vertical-align: bottom;
        }
    }
}
</style>
