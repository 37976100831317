<template>
    <div class="rank" v-infinite-scroll="loadRank" v-loading="loading">
        <div class="rank-title">完整打卡榜</div>
        <div v-for="(rank, index) in ranks" :key="index" class="list-style">
            <div class="rank-item">
                <span class="rank-index">{{index+1}}</span>
                <!--suppress HtmlUnknownTarget -->
                <el-avatar :src="rank.avatarUrl"/>

                <el-tooltip effect="dark" :content="rank.name" placement="bottom-start">
                    <div class="rank-name">{{rank.name}}</div>
                </el-tooltip>
                <div class="rank-cumulative-day">
                    {{rank.cumulativeDay}}天
                </div>
            </div>
        </div>

        <div class="end-border" v-show="noMore">—— 我是有底线的 ——</div>
    </div>
</template>

<script>
  import CardRankModel from '@/model/CardRankModel'

  export default {
    name: 'Rank',
    data () {
      return {
        ranks: [],
        page: -1,
        total: 1000000,
        size: 20,
        noMore: false,
        loading: false
      }
    },
    methods: {
      loadRank () {
        if (this.ranks.length === this.total) {
          console.log('no more')
          this.noMore = true
          return
        }

        this.page = this.page + 1
        CardRankModel.query({page: this.page, size: this.size})
          .then(data => {
            this.total = data.total
            this.ranks = this.ranks.concat(data.list)
          })
      }
    }
  }
</script>

<style scoped lang="scss">
    @import "src/assets/Sizes";
    @import "src/assets/Styles";

    .rank {
        width: 800px;
        overflow: auto;
        background-color: white;
        margin-top: $global-content-margin-top;
        padding-bottom: 10px;
        @include home-box-shadow;

        .rank-title {
            font-size: 30px;
            font-weight: bold;
            padding: 10px;
        }

        .list-style {
            &:nth-child(odd) {
                background: #f8fbfd;
            }
        }

        .rank-item {
            display: flex;
            align-items: center;
            padding: 8px;
            margin-top: 10px;

            .rank-index {
                width: 70px;
                font-size: 24px;
                font-weight: bold;
                margin-left: 10px;
                margin-right: 4px;
            }

            &:hover {
                background-color: #e0e0e0;
            }

            .el-avatar {
                width: 50px;
                height: 50px;
                margin-left: 10px;
                margin-right: 20px;
            }

            .rank-name {
                width: 400px;
                font-size: 20px;
                font-weight: bold;
                margin-right: 20px;
                text-align: start;
                overflow: hidden;
                white-space: nowrap;
                text-overflow: ellipsis;
            }

            .rank-cumulative-day {
                flex: 1;
                text-align: right;
                font-size: 24px;
                font-weight: bold;
                color: #ff7f50;
                margin-right: 20px;
            }
        }

        .end-border {
            margin-top: 20px;
        }
    }
</style>
