<template>
    <div class="bb-survey-statistics-item-container">
        <div class="statistics-desc-container">
            <span class="statistics-number">{{number}}</span>
            <span>{{desc}}</span>
        </div>

        <div class="statistics-icon-container">
            <img class="statistics-icon" alt="" :src="iconPath"/>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'BbSurveyStatisticsItem',
    props: {
      number: Number,
      desc: String,
      iconPath: String
    }
  }
</script>

<style scoped lang="scss">
    .bb-survey-statistics-item-container {
        width: 200px;
        height: 80px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        padding: 20px;
        text-align: center;
        border-radius: 14px;
        box-shadow: 2px 2px 2px 2px #e7e7e7;
        background-color: white;

        .statistics-desc-container {
            display: flex;
            flex-direction: column;

            .statistics-number {
                font-size: 50px;
                font-weight: bold;
            }
        }

        .statistics-icon-container {
            display: flex;
            align-items: center;
            justify-content: center;
            padding: 16px;
            border-radius: 50%;
            background-color: #fef6d9;

            .statistics-icon {
                width: 40px;
                height: 40px;
            }

        }
    }
</style>
