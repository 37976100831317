<template>
    <div class="almanac-content-container">

        <div class="almanac-header-container">
            <div class="almanac-title">学习统计</div>

            <div class="almanac-desc">
                <div>统计每天进度, 如待办完成、进度更新</div>
                <almanac-desc-item name="middle"/>
                <almanac-desc-item name="more"/>
                <almanac-desc-item name="most"/>
            </div>
        </div>

        <div class="almanac-content">
            <el-calendar v-model="today">
                <template
                        slot="dateCell"
                        slot-scope="{date, data}">
                    <div class="day-background">
                        <el-tooltip :disabled="getContentByDate(date)===''" effect="dark" placement="bottom">
                            <div v-html="getContentByDate(date)" slot="content"/>
                            <p :class="getClassByDate(date)">
                                {{data.day.split('-').slice(2).join('-')}}
                            </p>
                        </el-tooltip>
                    </div>
                </template>
            </el-calendar>
        </div>
    </div>
</template>

<script>
  import BbSurveyModel from '@/pages/bulletin-board/survey/BbSurveyModel'
  import {formatDate} from '@/utils/Date'
  import AlmanacDescItem from '@/pages/bulletin-board/survey/components/almanac-desc-item'

  export default {
    name: 'DoAlmanac',
      components: {AlmanacDescItem},
      data () {
      return {
        today: new Date(),
        studyRecords: [],
        studyRecordMap: new Map()
      }
    },
    created () {
      this.getStudyRecord()
      this.initHeaderButton()
    },
    methods: {
      initHeaderButton () {
        this.$nextTick(() => {
          this.addHeaderBtnClick(1)
          this.addHeaderBtnClick(2)
          this.addHeaderBtnClick(3)
        })
      },
      addHeaderBtnClick (number) {
        const prevBtn = document.querySelector('.el-calendar__button-group .el-button-group>button:nth-child(' + number + ')')
        prevBtn.addEventListener('click', () => this.getStudyRecord())
      },
      getClassByDate (date) {
        if (this.studyRecordMap.size === 0) {
          return 'less'
        }

        const curDate = formatDate(date, 'YY-MM-DD')
        const studyRecord = this.studyRecordMap.get(curDate)

        if (studyRecord) {
          const count = studyRecord.finishTodoCount + studyRecord.progressCount
          if (count > 10) {
            return 'most'
          }

          if (count > 5) {
            return 'more'
          }

          if (count > 0) {
            return 'middle'
          }
        }
        return 'less'
      },
      getContentByDate (date) {
        if (this.studyRecordMap.size === 0) {
          return ''
        }

        const curDate = formatDate(date, 'YY-MM-DD')
        const studyRecord = this.studyRecordMap.get(curDate)
        if (studyRecord) {
          return '进度次数: ' + studyRecord.progressCount + '<br/>'
            + '待办完成次数: ' + studyRecord.finishTodoCount
        } else {
          return ''
        }
      },
      getStudyRecord () {
        BbSurveyModel.getStudyRecord({
          time: formatDate(this.today)
        }).then((response) => {
          this.studyRecords = response

          const map = new Map()
          for (let i = 0; i < response.length; i++) {
            const studyRecord = response[i]
            map.set(studyRecord.refDate, studyRecord)
          }

          this.studyRecordMap = map
        })
      }
    }
  }
</script>

<style scoped lang="scss">
    @import "src/assets/Colors";

    .almanac-content-container {
        width: 100%;
        background-color: white;
        border-radius: 14px;
        box-shadow: 2px 2px 2px 2px #e7e7e7;

        .almanac-header-container {
            display: flex;
            justify-content: space-between;
            align-items: center;
            margin-left: 20px;
            margin-right: 20px;
            padding-top: 20px;

            .almanac-title {
                text-align: left;
                font-size: 30px;
                font-weight: bold;
            }

            .almanac-desc {
                display: flex;

                .middle {
                    width: 20px;
                    height: 20px;
                    line-height: 20px;
                    padding: 10px;
                    color: white;
                    border-radius: 50%;
                    background-color: rgba(44, 62, 80, 0.5);
                }
            }
        }


        .almanac-content {
            display: flex;
            flex-direction: column;
            background-color: white;

          ::v-deep .el-calendar-table .el-calendar-day {
                height: auto !important;
                padding: 0 !important;

                .day-background {
                    width: 130px;
                    height: 100px;
                    font-size: 18px;
                    text-align: center;
                    font-weight: bold;
                    display: flex;
                    align-items: center;
                    justify-content: center;

                    .most {
                        width: 60px;
                        height: 60px;
                        line-height: 60px;
                        font-size: 40px;
                        padding: 10px;
                        color: white;
                        border-radius: 50%;
                        background-color: $primary;
                    }

                    .more {
                        width: 40px;
                        height: 40px;
                        line-height: 40px;
                        font-size: 30px;
                        padding: 10px;
                        color: white;
                        border-radius: 50%;
                        background-color: rgba(44, 62, 80, 0.8);
                    }

                    .middle {
                        width: 20px;
                        height: 20px;
                        line-height: 20px;
                        padding: 10px;
                        color: white;
                        border-radius: 50%;
                        background-color: rgba(44, 62, 80, 0.5);
                    }

                    .less {
                    }
                }
            }


            .block-row {
                display: flex;
                align-items: center;
                justify-content: center;

                .block-row-title {
                    margin-bottom: 10px;
                    margin-right: 10px;
                }

                .almanac-block {
                    width: 24px;
                    height: 24px;
                    margin-right: 10px;
                    margin-bottom: 10px;
                    border-radius: 4px;
                    background-color: #f3f5f9;
                }
            }
        }
    }
</style>
