import {
  Avatar,
  Badge,
  Button,
  Calendar, Checkbox,
  Col,
  DatePicker,
  Dialog,
  Divider,
  Dropdown,
  DropdownItem,
  DropdownMenu,
  InfiniteScroll,
  Input,
  Loading,
  Menu,
  MenuItem,
  MenuItemGroup,
  Option,
  Pagination,
  Progress,
  RadioButton,
  RadioGroup,
  Row,
  Select,
  Submenu,
  Switch,
  Table,
  TableColumn,
  TabPane,
  Tabs,
  Tag,
  Tooltip,
  Tree,
} from 'element-ui'
import CollapseTransition from 'element-ui/lib/transitions/collapse-transition'

export default {
  install (Vue) {
    Vue.component(CollapseTransition.name, CollapseTransition)


    Vue.use(InfiniteScroll)
    Vue.use(Loading)
    Vue.use(Button)
    Vue.use(Tree)
    Vue.use(Divider)
    Vue.use(Tabs)
    Vue.use(TabPane)
    Vue.use(Input)
    Vue.use(Dialog)
    Vue.use(Row)
    Vue.use(Col)
    Vue.use(Menu)
    Vue.use(Submenu)
    Vue.use(MenuItem)
    Vue.use(MenuItemGroup)
    Vue.use(Pagination)
    Vue.use(Avatar)
    Vue.use(Tooltip)
    Vue.use(Badge)
    Vue.use(Progress)
    Vue.use(DatePicker)
    Vue.use(Calendar)
    Vue.use(Table)
    Vue.use(TableColumn)
    Vue.use(Tag)
    Vue.use(Dropdown)
    Vue.use(DropdownItem)
    Vue.use(DropdownMenu)
    Vue.use(Select)
    Vue.use(Option)
    Vue.use(RadioGroup)
    Vue.use(RadioButton)
    Vue.use(Switch)
    Vue.use(Checkbox)
  }
}
