import httpClient from '@/http/Axios'

export default class NoticeModel {

  static query (params) {
    return httpClient.post('/notice/query/user', params)
  }

  static queryUnread () {
    return httpClient.get('/notice/query/unread/count', {})
  }
}
