import httpClient from '@/http/Axios'

export default class BbSurveyModel {

  static getStudyRecord (params) {
    return httpClient.get('/study-record/query/all', {params: params})
  }

  static getTip() {
    return httpClient.get('/tip/query')
  }
}
