import httpClient from '@/http/Axios'

export default class ResourcePraiseModel {

  static praise (param) {
    return httpClient.post('/resource/praise', param)
  }

  static cancelPraise (param) {
    return httpClient.post('/resource/cancel/praise', param)
  }
}
