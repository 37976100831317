<template>
    <div class="todo" v-show="isLogin" @click.stop>
        <div class="todo-content">
            <div class="title-container">
                <span @click="showTodoNavigation">{{this.todoTitle}}</span>
                <i @click="showTodoNavigation" class="el-icon-arrow-down"/>
                <i @click="goToBbTodo" class="el-icon-full-screen"/>
            </div>

            <div class="todo-navigation" v-show="showTodoNavigationState">
                <div v-for="(todoNavigation, index) in todoNavigations" :key="index">
                    <div class="todo-navigation-item"
                         :class="{select:todoSelectIndex===index}"
                         @click="changeTodoNavigation(index, todoNavigation)">{{todoNavigation}}
                    </div>
                </div>
            </div>

            <input class="progress-input"
                   type="text"
                   v-model="content"
                   placeholder="添加任务？(回车即可创建)"
                   @keypress.enter="addTodoContent"/>

            <todo-card :tag="todayTag" :contents="pendingTodo" @finishTodo="finishTodo"/>

            <todo-card :tag="finishTag"
                       :contents="todayFinishTodo"
                       @undoTodo="undoTodo"
                       @loadMore="loadMoreFinishTodo"/>

            <div class="empty-placeholder" v-show="isEmpty">
                今天要做点啥？
            </div>
        </div>

        <BbTodoAddDialog ref="addDialog"
                         @addTodoContentSuccess="addTodoContentSuccess"/>
    </div>
</template>

<script>
  import TodoItem from '@/pages/home/todo/TodoItem'
  import TodoCard from '@/pages/home/todo/TodoCard'
  import Checkbox from '@/pages/home/todo/Checkbox'
  import {sortDescByKey} from '@/utils/Sort'
  import {showError} from '@/utils/Message'
  import TodoModel from '@/model/TodoModel'
  import BbTodoAddDialog from '@/pages/bulletin-board/todo/BbTodoAddDialog'

  export default {
    name: 'Todo',
    components: {BbTodoAddDialog, Checkbox, TodoCard, TodoItem},
    data () {
      return {
        content: '',
        todayTag: '今天',
        finishTag: '已完成',
        pendingTodo: [],
        todayFinishTodo: [],
        page: 0,
        pageSize: 6,
        finishTotal: 0,
        canEditDetailContent: false,
        todoTitle: '今天',
        showTodoNavigationState: false,
        todoSelectIndex: 0,
        todoNavigations: ['今天', '完成']
      }
    },
    computed: {
      isEmpty () {
        return this.pendingTodo.length === 0 && this.todayFinishTodo.length === 0
      },
      isLogin () {
        return this.$store.state.login.isLogin
      },
    },
    created () {
      this.getTodoContent()
    },
    methods: {
      getTodoContent () {

        if (!this.isLogin) {
          return
        }

        TodoModel.getContent()
          .then((data) => {
            this.pendingTodo = this.pendingTodo.concat(data.pendingTodo)
            this.todayFinishTodo = this.todayFinishTodo.concat(data.todayFinishTodo)
          })
      },
      refreshTodo () {
        TodoModel.getContent()
          .then((data) => {
            this.pendingTodo = data.pendingTodo
            this.todayFinishTodo = data.todayFinishTodo
          })
      },
      addTodoContent () {

        if (this.content === '') {
          showError('内容不能为空')
          return
        }

        this.$refs.addDialog.showDialogContent(this.content)
      },
      addTodoContentSuccess (newData) {
        this.pendingTodo.unshift(newData)
        this.content = ''
      },
      finishTodo (newTodo) {
        newTodo.state = 'FINISH'
        this.todayFinishTodo.unshift(newTodo)
      },
      undoTodo (newTodo) {
        newTodo.state = 'CREATE'
        this.pendingTodo.unshift(newTodo)
        this.pendingTodo = sortDescByKey(this.pendingTodo, 'id')
      },
      showTodoNavigation () {
        this.showTodoNavigationState = !this.showTodoNavigationState
      },
      goToBbTodo () {
        this.$router.push('/bulletin-board/bb-todo')
      },
      changeTodoNavigation (index, todoNavigation) {

        if (index === this.todoSelectIndex) {
          this.showTodoNavigationState = false
          return
        }

        if (index === 1) {
          this.page = 0
          TodoModel.getFinish({
            page: this.page,
            size: this.pageSize
          }).then((data) => {
            this.pendingTodo = []
            this.finishTotal = data.total
            console.log('this.finishTotal = ' + this.finishTotal)
            this.todayFinishTodo = data.list
          })
        }

        if (index === 0) {
          TodoModel.getContent()
            .then((data) => {
              this.pendingTodo = data.pendingTodo
              this.todayFinishTodo = data.todayFinishTodo
            })
        }

        this.todoSelectIndex = index
        this.todoTitle = todoNavigation
        this.showTodoNavigationState = false
      },
      loadMoreFinishTodo () {
        if (this.todoSelectIndex === 1) {
          this.page += 1

          if (this.todayFinishTodo.length === this.finishTotal) {
            console.log('no more todo finish')
            return
          }

          TodoModel.getFinish({
            page: this.page,
            size: this.pageSize
          }).then((data) => {
            this.todayFinishTodo = this.todayFinishTodo.concat(data.list)
          })
        }
      }
    },
    watch: {
      isLogin (value) {
        if (value) {
          this.refreshTodo()
        }
      }
    }
  }
</script>

<style lang="scss">
    @import "src/assets/Colors";
    @import "src/assets/Sizes";
    @import "src/assets/Styles";

    .todo {
        display: flex;
        margin-top: $global-content-margin-top;

        .todo-content {
            width: 260px;
            height: 450px;
            right: 0;
            display: flex;
            flex-direction: column;
            background-color: white;
            overflow: auto;
            padding: 10px;
            @include home-box-shadow;

            .title-container {
                display: flex;
                align-items: center;
                justify-content: center;
                font-size: 30px;
                font-weight: bold;
                margin-bottom: 12px;
                cursor: pointer;
                position: relative;

                .el-icon-arrow-down {
                    font-size: 18px;
                    margin-left: 6px;
                    padding: 2px;

                    &:hover {
                        border-radius: 50%;
                        color: white;
                        background-color: #b3b3b3;
                    }
                }

                .el-icon-full-screen {
                    position: absolute;
                    right: 6px;
                    font-size: 24px;
                }
            }

            .todo-navigation {
                width: 240px;
                margin-top: 50px;
                color: $primary;
                background-color: white;
                position: absolute;
                border-radius: 6px;
                box-shadow: 2px 2px 2px 2px #CECECE;

                .todo-navigation-item {
                    padding: 10px;
                    cursor: pointer;

                    &:hover {
                        color: white;
                        border-radius: 4px;
                        background-color: $primary;
                    }

                    &.select {
                        font-size: 18px;
                        font-weight: bold;
                    }
                }
            }

            .progress-input {
                margin-bottom: 12px;
                background: none;
                outline: none;
                border: 1px solid #CECECE;
                padding: 10px;
                border-radius: 6px;
            }

            .empty-placeholder {
                flex: 1;
                color: #999999;
                display: flex;
                align-items: center;
                justify-content: center;
            }

            .tag {
                font-size: 12px;
                color: #acacac;
                text-align: start;
            }
        }

        .todo-content-detail-box {
            width: 268px;
            height: 300px;
            display: flex;
            position: absolute;
            right: -300px;
            flex-direction: column;
            align-items: flex-start;
            margin-left: 10px;
            padding: 10px;
            background-color: white;

            .todo-detail-header {
                display: flex;
                justify-content: space-between;
                width: 100%;
                font-size: 14px;
                margin-bottom: 10px;
                margin-left: 4px;

                img {
                    width: 18px;
                    height: 18px;
                    cursor: pointer;
                }
            }

            .todo-detail-border {
                display: block;
                height: 1px;
                width: 100%;
                background-color: #DCDFE6;
            }

            .todo-detail-content {
                width: 260px;
                flex: 1;
                font-size: 16px;
                text-align: start;
                margin-top: 6px;
                padding-left: 4px;
                padding-right: 4px;
            }
        }
    }
</style>
