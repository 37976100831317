<template>
    <div class="bb-survey-container">

        <div class="bb-survey-content-container">

            <div class="tips-container">
                <span class="tips-title">小帖士</span>
                <span>{{ tip }}</span>
                <span class="tips-author">—— {{ author }}</span>
            </div>

            <div class="progress-container">
                <div class="progress-title-container">
                    <div class="progress-title">专注进度</div>
                    <i class="el-icon-circle-plus" @click="showProgressAddDialog"/>
                </div>
                <do-progress ref="progress"/>
            </div>

            <div class="almanac-container">
                <do-almanac/>
            </div>

            <div class="filing-no-container">
                <a href="https://beian.miit.gov.cn/" target="_blank">粤ICP备2022029769号-1</a>
            </div>
        </div>

        <div class="bb-survey-right-container">
            <sign-in/>

            <do-day-matter/>

            <todo class="todo"/>
        </div>
    </div>
</template>

<script>
import BbSurveyStatisticsItem from '@/pages/bulletin-board/survey/BbSurveyStatisticsItem'
import DoProgress from '@/pages/home/progress/DoProgress'
import Todo from '@/pages/home/todo/Todo'
import SignIn from '@/pages/home/sign-in/SignIn'
import DoDayMatter from '@/pages/home/day-matter/DoDayMatter'
import DoAlmanac from '@/pages/bulletin-board/survey/DoAlmanac'
import BbSurveyModel from '@/pages/bulletin-board/survey/BbSurveyModel'

export default {
    name: 'BbSurvey',
    components: {DoAlmanac, DoDayMatter, SignIn, Todo, DoProgress, BbSurveyStatisticsItem},
    data () {
        return {
            tip: '',
            author: ''
        }
    },
    created () {
        this.getTip()
    },
    methods: {
        showProgressAddDialog () {
            this.$refs.progress.showProgressAddDialog()
        },
        getTip () {
            BbSurveyModel.getTip()
                .then((response) => {
                    this.tip = response.content
                    this.author = response.author
                })
        }
    }
}
</script>

<style scoped lang="scss">
.bb-survey-container {
    display: flex;
    margin-right: 20px;

    .bb-survey-content-container {
        flex: 1;
        display: flex;
        flex-direction: column;
        margin-right: 10px;

        .tips-container {
            display: flex;
            flex-direction: column;
            text-align: left;
            font-size: 18px;
            background-color: white;
            padding: 20px;
            border-radius: 14px;
            box-shadow: 2px 2px 2px 2px #e7e7e7;

            .tips-title {
                font-size: 26px;
                font-weight: bold;
                margin-bottom: 10px;
            }

            .tips-author {
                text-align: right;
                margin-top: 4px;
            }
        }

        .progress-container {
            width: 100%;
            text-align: left;
            margin-top: 10px;
            background-color: white;
            border-radius: 14px;
            box-shadow: 2px 2px 2px 2px #e7e7e7;

            .progress-title-container {
                display: flex;
                align-items: center;
                margin-bottom: 10px;
                margin-left: 12px;
                padding-top: 20px;

                .progress-title {
                    font-size: 30px;
                    font-weight: bold;
                }

                .el-icon-circle-plus {
                    font-size: 30px;
                    margin-left: 10px;
                    cursor: pointer;
                }
            }
        }

        .almanac-container {
            width: 100%;
            margin-top: 10px;
        }

        .filing-no-container {
            margin-top: 20px;

            a {
                text-decoration: none;
                cursor: pointer;
                color: #909090;
            }
        }
    }

    .bb-survey-right-container {
        width: 260px;
    }
}
</style>
