import httpClient from '@/http/Axios'

export default class DayMatterModel {

  static queryShow (params) {
    return httpClient.get('/day-matter/query/show', {params: params})
  }

  static query (params) {
    return httpClient.get('/day-matter/query', {params: params})
  }

  static add (params) {
    return httpClient.post('/day-matter/add', params)
  }

  static update (params) {
    return httpClient.post('/day-matter/update', params)
  }

  static delete (params) {
    return httpClient.delete('/day-matter', {data: params})
  }

  static show (params) {
    return httpClient.post('/day-matter/show', params)
  }

  static hide (params) {
    return httpClient.post('/day-matter/hide', params)
  }
}
