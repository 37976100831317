<template>
    <div class="part-tag">
        {{label}}
        <div v-if="showClose" class="part-tag-close" @click="deleteClick"/>

        <div class="tag-selector" v-show="showSelector">
            <div class="tag-title">请选择标签</div>
            <div v-for="(tag, index) in tags" :key="index">
                <part-tag @click.native="selectTag(tag)"
                          class="tag"
                          :label="tag"
                          :show-close="false"
                          :show-list="false"
                />
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'PartTag',
    props: {
      label: String,
      tags: Array,
      showClose: {
        type: Boolean,
        default: false
      },
      showSelector: {
        type: Boolean,
        default: false
      }
    },
    methods: {
      deleteClick () {
        this.$emit('deleteClick')
      },
      selectTag (tag) {
        this.$emit('selectTag', tag)
      }
    }
  }
</script>

<style scoped lang="scss">
    @import "src/assets/Colors";

    .part-tag {
        height: 30px;
        display: flex;
        justify-content: center;
        align-items: center;
        line-height: 30px;
        font-size: 14px;
        padding: 0 20px;
        border-radius: 20px;
        color: white;
        background-color: $primary;
        cursor: pointer;
        position: relative;

        .part-tag-close {
            width: 14px;
            height: 14px;
            margin-left: 6px;
            padding: 2px;
            border-radius: 50%;
            content: url("../assets/icons/white_close.png");

            &:hover {
                background-color: white;
                content: url("../assets/icons/primary_close.png");
            }
        }

        .tag-selector {
            width: 200px;
            top: 0;
            margin-top: 34px;
            background-color: white;
            box-shadow: 2px 2px 2px 2px #CECECE;
            text-align: center;
            position: absolute;
            border-radius: 10px;
            z-index: 10;

            .tag-title {
                margin-top: 10px;
                font-size: 14px;
                color: $primary;
            }

            .tag {
                margin: 10px;
                background-color: white;
                color: $primary;
                border: 1px solid $primary;

                &:hover {
                    background-color: $primary;
                    color: white;
                }
            }
        }
    }
</style>
