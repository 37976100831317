<template>
    <el-dialog
        class="el-dialog-add-folder-container"
        title="编辑倒计时"
        :visible.sync="dialogVisible"
        width="30%"
        :before-close="() => this.dialogVisible = false">

        <div class="row-input-container">
            <span class="title">标题</span>
            <el-input
                type="text"
                placeholder="请输入标题"
                v-model="editTitle"
                :maxlength="8"
                show-word-limit/>
        </div>

        <div class="edit-date-and-state-container">
            <div class="edit-date-container">
                <span>选择日期</span>
                <el-date-picker
                    class="edit-date"
                    v-model="editDate"
                    type="date"
                    placeholder="选择日期"
                    format="yyyy 年 MM 月 dd 日"
                >
                </el-date-picker>
            </div>
        </div>

        <div slot="footer" class="dialog-footer">
            <div class="footer-left-container">
                <el-button type="danger" @click="deleteDayMatter" v-show="isUpdate">删 除</el-button>
                <el-button type="info" @click="changeState" v-show="isUpdate">{{ stateStr }}</el-button>
            </div>
            <el-button @click="dialogVisible = false">取 消</el-button>
            <el-button type="primary" @click="confirmEdit">确 定</el-button>
        </div>
    </el-dialog>
</template>

<script>
import ColumnInput from '@/components/ColumnInput'
import {showCloseDialog} from '@/utils/Dialog'
import DayMatterModel from '@/pages/home/day-matter/model/DayMatterModel'
import {showError, showSuccess} from '@/utils/Message'
import {deleteByKey} from '@/utils/Array'
import RowInput from '@/components/RowInput'

const ADD = 'add'
const UPDATE = 'update'

export default {
    name: 'DoDayMatterDialog',
    components: {RowInput, ColumnInput},
    data () {
        return {
            dialogVisible: false,
            editTitle: '',
            editDate: '',
            editDayMatterId: 0,
            editState: 'SHOW',
            actionType: 'ADD',
        }
    },
    computed: {
        stateStr () {
            return this.editState === 'HIDE' ? '显示' : '隐 藏'
        },
        isUpdate() {
            return this.actionType === 'UPDATE'
        }
    },
    methods: {
        showEdit (dayMatterData) {
            this.dialogVisible = true
            this.editState = dayMatterData.state
            this.editDate = dayMatterData.date
            this.editTitle = dayMatterData.title
            this.editDayMatterId = dayMatterData.id
            this.actionType = 'UPDATE'
        },
        showAdd () {
            this.dialogVisible = true
            this.editDate = ''
            this.editTitle = ''
            this.editDayMatterId = 0
            this.actionType = 'ADD'
        },
        deleteDayMatter () {
            showCloseDialog('确定要删除吗？')
                .then(() => {
                    DayMatterModel.delete({id: this.editDayMatterId})
                        .then((data) => this.triggerRefresh('删除成功', 'deleteSuccess'))
                })
        },
        changeState () {
            if (this.editState === 'HIDE') {
                this.showDayMatter()
                return
            }

            this.hideDayMatter()
        },
        showDayMatter () {
            showCloseDialog('确定要显示在列表吗？')
                .then(() => {
                    DayMatterModel.show({id: this.editDayMatterId})
                        .then((data) => this.triggerRefresh('隐藏成功', 'showSuccess'))
                })
        },
        hideDayMatter () {
            showCloseDialog('确定要隐藏（不显示在列表）吗？')
                .then(() => {
                    DayMatterModel.hide({id: this.editDayMatterId})
                        .then((data) => this.triggerRefresh('隐藏成功', 'hideSuccess'))
                })
        },
        triggerRefresh (message, event) {
            showSuccess(message)
            this.dialogVisible = false
            this.$emit(event, this.editDayMatterId)
        },
        confirmEdit () {
            if (this.editTitle === '') {
                showError('标题不能为空')
                return
            }

            if (this.editDate === '') {
                showError('时间有误, 请重新选择')
                return
            }

            if (this.actionType === 'ADD') {
                this.addDayMatter()
                return
            }

            this.updateDayMatter()
        },
        addDayMatter () {
            DayMatterModel.add({
                date: this.editDate,
                title: this.editTitle
            }).then(data => {
                this.dialogVisible = false
                this.$emit('addSuccess', data)
            })
        },
        updateDayMatter () {
            DayMatterModel.update({
                id: this.editDayMatterId,
                date: this.editDate,
                title: this.editTitle
            }).then(newData => {
                showSuccess('更新成功')
                this.dialogVisible = false
                this.$emit('updateSuccess', newData)
            })
        },
    }
}
</script>

<style scoped lang="scss">
.el-dialog-add-folder-container {

    .row-input-container {
        display: flex;
        justify-content: space-between;
        align-items: center;

        .title {
            font-size: 16px;
            font-weight: bold;
            text-align: end;
            margin-right: 10px;
        }

        .el-input {
            width: 360px;
        }
    }

    .edit-date-container {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 20px;

        span {
            font-size: 16px;
            font-weight: bold;
        }

        .edit-date {
            width: 360px;
        }
    }

    .dialog-footer {
        display: flex;

        .footer-left-container {
            display: flex;
            flex: 1;
        }
    }
}
</style>
