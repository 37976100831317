import httpClient from '@/http/Axios'

export default class BbGiftModel {

  static getContent (params) {
    return httpClient.get('/gift/query', {params: params})
  }

  static add(params) {
    return httpClient.post('/gift/add', params)
  }

  static update(params) {
    return httpClient.post('/gift/update', params)
  }

  static delete(params) {
    return httpClient.post('/gift/delete', params)
  }

  static getTotalProb() {
    return httpClient.get('/gift/query/prob')
  }
}
