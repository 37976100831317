<template>
    <div class="card-content-item-top">
        <el-avatar :src="avatarUrl" :size="60"/>
        <card-summary :username="username" :publish-time="publishTime"/>
        <div class="right-label" @click="rightLabelClick">{{rightLabel}}</div>
    </div>
</template>

<script>
  import CardSummary from './CardSummary'
  import {showSuccess} from '@/utils/Message'

  export default {
    name: 'CardContentItemTop',
    components: {CardSummary},
    props: {
      username: String,
      avatarUrl: String,
      publishTime: String,
      rightLabel: String
    },
    methods: {
      rightLabelClick () {
        this.$emit('rightLabelClick')
      }
    }
  }
</script>

<style scoped lang="scss">
    @import "src/assets/Colors";

    .card-content-item-top {
        display: flex;
        align-items: center;
        justify-content: flex-start;

        .right-label {
            width: 60px;
            height: 30px;
            border: 1px solid $primary;
            color: $primary;
            font-size: 14px;
            font-weight: bold;
            line-height: 30px;
            cursor: pointer;

            &:hover {
                color: white;
                background-color: $primary;
            }
        }
    }
</style>
