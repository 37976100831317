<template>
    <div class="bulletin-board-container">

        <div class="bulletin-board-left-container">

            <el-menu @select="changeMenuStyle"
                     collapse
                     router
            >
                <div v-for="(menu, index) in menus" :key="index">
                    <el-menu-item :index="menu.viewPath">
                        <el-tooltip effect="dark" :content="menu.title" placement="right">
                            <div class="menu-item-select-background" :class="{select:isSelectMenu(menu.viewPath) }">
                                <img v-show="!isSelectMenu(menu.viewPath)" :src="menu.image" alt=""/>
                                <img v-show="isSelectMenu(menu.viewPath)" :src="menu.activeImage" alt=""/>
                            </div>
                        </el-tooltip>
                    </el-menu-item>
                </div>
            </el-menu>
        </div>

        <div class="bulletin-board-content-container">
            <router-view/>
        </div>
    </div>
</template>

<script>
export default {
    name: 'BulletinBoard',
    data () {
        return {
            input: '',
            selectIndex: this.$route.path,
            menus: [
                {
                    title: '概况',
                    viewPath: '/bulletin-board/bb-survey',
                    image: require('../../assets/icons/bulletin-board/ic_survey.png'),
                    activeImage: require('../../assets/icons/bulletin-board/ic_active_survey.png'),
                },
                // {
                //   title: '工作台',
                //   viewPath: '/bulletin-board/bb-workbench',
                //   image: require('../../assets/icons/bulletin-board/ic_workbench.png'),
                //   activeImage: require('../../assets/icons/bulletin-board/ic_active_workbench.png'),
                // },
                {
                    title: '待办',
                    viewPath: '/bulletin-board/bb-todo',
                    image: require('../../assets/icons/bulletin-board/ic_todo.png'),
                    activeImage: require('../../assets/icons/bulletin-board/ic_active_todo.png'),
                },
                {
                  title: '倒数日',
                  viewPath: '/bulletin-board/bb-day-matter',
                  image: require('../../assets/icons/bulletin-board/ic_day_matter.png'),
                  activeImage: require('../../assets/icons/bulletin-board/ic_active_day_matter.png'),
                },
                {
                    title: '进度',
                    viewPath: '/bulletin-board/bb-progress',
                    image: require('../../assets/icons/bulletin-board/ic_progress.png'),
                    activeImage: require('../../assets/icons/bulletin-board/ic_active_progress.png'),
                },
                {
                    title: '抽奖',
                    viewPath: '/bulletin-board/bb-luck-draw-management',
                    image: require('../../assets/icons/bulletin-board/ic_luck_draw.png'),
                    activeImage: require('../../assets/icons/bulletin-board/ic_active_luck_draw.png'),
                },
                {
                    title: '金币',
                    viewPath: '/bulletin-board/bb-coin',
                    image: require('../../assets/icons/bulletin-board/ic_coin.png'),
                    activeImage: require('../../assets/icons/bulletin-board/ic_active_coin.png'),
                },
            ]
        }
    },
    methods: {
        isSelectMenu (curViewPath) {
            return this.selectIndex.indexOf(curViewPath) !== -1
        },
        changeMenuStyle (index) {
            if (index === this.selectIndex) {
                return
            }

            this.selectIndex = index
        },
    },
}
</script>

<style scoped lang="scss">
@import "src/assets/Sizes";
@import "src/assets/Colors";

.bulletin-board-container {
    width: 1400px;
    margin-top: $global-content-margin-top;
    display: flex;

    .bulletin-board-left-container {
        margin-right: 10px;

        .el-menu {
            padding-top: 20px;
            padding-bottom: 10px;
            border-radius: 4px;
            box-shadow: 2px 2px 2px 2px #e7e7e7;
            border-right: none !important;
        }

        .el-menu-item {
            padding: 0 !important;

            &:hover {
                animation: myTranslateX 0.5s;
                background-color: rgba(0, 0, 0, 0) !important;
            }

            &.is-active {
                background-color: rgba(0, 0, 0, 0) !important;
            }

            @keyframes myTranslateX {
                0% {
                    transform: translateX(4px);
                }
                100% {
                    transform: translateX(0);
                }
            }
        }

        .menu-item-select-background {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            padding-top: 10px;
            padding-bottom: 10px;
            border-radius: 6px;

            img {
                width: 26px;
                height: 26px;
            }

            &:hover {
                background-color: #f6f6f6;
            }

            &.select {
                background-color: $primary;
            }
        }
    }

    .bulletin-board-content-container {
        flex: 1;
        margin-bottom: 20px;
    }
}
</style>
