<template>
    <div class="todo-card" v-infinite-scroll="loadMore">
        <span class="tag" v-if="contents.length > 0">{{tag}}</span>
        <div v-for="(content) in contents" :key="content.id">
            <todo-item :todoData="content"
                       @refreshTodo="refreshTodo"
                       @showTodoDetail="showTodoDetail"
            />
        </div>

        <do-todo-detail
                ref="todoDetail"
                :list="contents"
                @deleteTodo="deleteTodo"
                @vetoTodo="vetoTodo"
        />

    </div>
</template>

<script>
  import TodoItem from '@/pages/home/todo/TodoItem'
  import {deleteByKey, getByKey} from '@/utils/Array'
  import DoTodoDetail from '@/pages/home/todo/DoTodoDetail'
  import TodoModel from '@/model/TodoModel'
  import {showCloseDialog, showPrompt} from '@/utils/Dialog'

  export default {
    name: 'TodoCard',
    components: {DoTodoDetail, TodoItem},
    props: {
      tag: String,
      contents: Array,
    },
    data () {
      return {}
    },
    methods: {
      refreshTodo (todoId, event) {
        deleteByKey(
          this.contents,
          (content) => content.id === todoId,
          (curTodo) => this.$emit(event, curTodo)
        )
      },
      loadMore () {
        this.$emit('loadMore')
      },
      showTodoDetail (todoData) {
        this.$refs.todoDetail.showDetailContent(todoData)
      },
      deleteTodo (todoId) {
        deleteByKey(this.contents, (curTodo) => curTodo.id === todoId)
      },
      vetoTodo (todoId) {
        deleteByKey(this.contents, (curTodo) => curTodo.id === todoId)
      }
    },
  }
</script>

<style scoped lang="scss">
    .todo-card {
        display: flex;
        flex-direction: column;

        .tag {
            font-size: 12px;
            color: #acacac;
            text-align: start;
            margin-bottom: 4px;
        }
    }
</style>
