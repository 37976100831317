<template>
    <div class="do-day-matter-item-container">
        <span class="day-matter-title">{{this.countDownData.title}}</span>

        <div class="day-matter-day">
            <span class="small-text">{{dayPrefix}}</span>
            <span class="day" :class="{active:dayPrefix==='已经'}">{{dayStr}}</span>
            <span class="small-text">天</span>
        </div>
    </div>
</template>

<script>
  import {getDaysFromToday} from '@/utils/Date'

  export default {
    name: 'DoDayMatterItem',
    props: {
      countDownData: Object
    },
    computed: {
      day () {
        return getDaysFromToday(this.countDownData.date)
      },
      dayStr () {
        return Math.abs(this.day)
      },
      dayPrefix () {
        return this.day >= 0 ? '还有' : '已经'
      }
    }
  }
</script>

<style scoped lang="scss">
    @import "src/assets/Colors";

    .do-day-matter-item-container {
        width: 210px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 8px;
        padding: 8px;
        border-radius: 10px;
        border: 2px solid #f3f4f6;
        box-shadow: 2px 2px 2px #f3f4f6;
        cursor: pointer;

        .day-matter-title {
            font-weight: bold;
            margin-right: 4px;
            font-size: 16px;
        }

        .day-matter-day {

            .day {
                height: 16px;
                line-height: 16px;
                font-weight: bold;
                margin-left: 4px;
                margin-right: 4px;
                font-size: 16px;
                color: white;
                border-radius: 4px;
                padding: 2px 4px;
                background-color: $primary;

                &.active {
                    background-color: coral;
                }
            }

            .small-text {
                font-size: 14px;
                vertical-align: bottom;
            }
        }
    }
</style>
