<template>
    <div class="statistics">
        <div class="statistics-title">统计信息</div>

        <div class="statistics-content" v-for="(data, index) in quantityData" :key="index">
            <div class="statistics-item">
                <span class="statistics-item-title">{{data.title}}:</span>
                <span class="statistics-item-number">{{data.quantity}}</span>
            </div>
        </div>
    </div>
</template>

<script>
  import StatisticsModel from '@/model/StatisticsModel'

  export default {
    name: 'Statistics',
    data () {
      return {
        quantityData: [{
          title: String,
          quantity: Number
        }]
      }
    },
    created () {
      StatisticsModel.getQuantity()
        .then((data) => {
            this.quantityData = data
        })
    }
  }
</script>

<style scoped lang="scss">
    @import "src/assets/Sizes";
    @import "src/assets/Styles";

    .statistics {
        background-color: white;
        margin-top: $global-content-margin-top;
        padding-top: 10px;
        padding-bottom: 4px;
        @include home-box-shadow;

        .statistics-title {
            font-size: 20px;
            font-weight: bold;
        }

        .statistics-content {

            .statistics-item {
                padding: 10px 20px;
                text-align: left;

                .statistics-item-number {
                    margin-left: 10px;
                }
            }

            &:not(:last-child) {
                border-bottom: 1px solid #eeeeee;
            }
        }
    }
</style>
