<template>
    <div class="blog-list">
        <div v-for="(data, index) in blogData" :key="index">
            <div class="blog-item" @click="toUrl(index)">
                <div class="blog-title">{{data.name}}</div>
                <div class="blog-introduction">{{data.introduction}}</div>
            </div>
        </div>
    </div>
</template>

<script>
  import BlogModel from '@/model/BlogModel'

  export default {
    name: 'BlogList',
    data () {
      return {
        blogData: []
      }
    },
    created () {
      BlogModel.getList({})
        .then(data => {
          this.blogData = this.blogData.concat(data.list)
        })
    },
    methods: {
      toUrl (index) {
        window.open(this.blogData[index].url,"_blank")
      }
    }
  }
</script>

<style scoped lang="scss">
    @import "src/assets/Colors";

    .blog-list {
        padding: 20px;
        margin-right: 20px;
        background-color: white;

        .blog-item {
            text-align: start;
            padding: 14px 10px 10px;
            background-color: white;
            cursor: pointer;
            border-bottom: 1px solid #e0e0e0;

            &:hover {
                background-color: $hover-color;
            }

            .blog-title {
                font-size: 30px;
                font-weight: bold;
            }

            .blog-introduction {
                color: #828282;
                margin-top: 6px;
            }
        }
    }
</style>
