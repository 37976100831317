export function deleteByKey (array, predicate, deleteSuccess = (curData) => {
}) {
  for (let i = 0; i < array.length; i++) {
    let curData = array[i]
    if (predicate(curData)) {
      array.splice(i, 1)
      deleteSuccess(curData)
      break
    }
  }
}

export function getByKey (array, predicate) {
  for (let i = 0; i < array.length; i++) {
    let curData = array[i]
    if (predicate(curData)) {
      return curData
    }
  }
}

export function updateByKey (array, predicate, updateSuccessCallback) {
  for (let i = 0; i < array.length; i++) {
    let curData = array[i]
    if (predicate(curData)) {
      updateSuccessCallback(i)
    }
  }
}
