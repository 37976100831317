<template>
    <label class="wrapper">
        <input class="checkbox" type="checkbox" :checked="checked" @click="finishTodo"/>
        <span class="checkmark"/>
    </label>
</template>

<script>
  export default {
    name: 'Checkbox',
    props: {
      checked: Boolean,
      finishTodo: Function
    },
  }
</script>

<style scoped lang="scss">
    @import "src/assets/Colors";

    .wrapper {
        background-color: blueviolet;
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 6px;
        cursor: pointer;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
        font-size: 16px;

        /* On mouse-over, add a grey background color */
        &:hover input ~ .checkmark {
            background-color: #ccc;
        }

        /* Hide the browser's default checkbox */
        input {
            position: absolute;
            opacity: 0;
            cursor: pointer;
            height: 0;
            width: 0;

            /* Show the checkmark when checked */
            &:checked ~ .checkmark:after {
                display: block;
            }

            /* When the checkbox is checked, add a blue background */
            &:checked ~ .checkmark {
                background-color: $primary;
            }
        }

        /* Create a custom checkbox */
        .checkmark {
            position: absolute;
            top: 0;
            left: 0;
            height: 14px;
            width: 14px;
            border-radius: 2px;
            background-color: #eee;
            border: 1px solid #ccc;

            /* Create the checkmark/indicator (hidden when not checked) */
            &:after {
                content: "";
                position: absolute;
                display: none;
                left: 4px;
                top: 0;
                width: 4px;
                height: 8px;
                border: solid white;
                border-width: 0 2px 2px 0;
                -webkit-transform: rotate(45deg);
                -ms-transform: rotate(45deg);
                transform: rotate(45deg);
            }
        }
    }

</style>
