<template>
    <div class="share-blog">
        <div class="blog-title">分享个人博客</div>

        <blog-single-input
                title="博客名"
                placeholder="请输入博客名"
                maxlength="20"
                show-word-limit
                v-model="blogName"
        />

        <blog-single-input title="博客链接"
                           placeholder="请输入链接"
                           v-model="blogUrl"
        />

        <div class="blog-introduction">
            <div class="name">博客介绍</div>
            <el-input
                    type="textarea"
                    placeholder="不说点啥吗？"
                    v-model="introduction"
                    maxlength="100"
                    show-word-limit
            >
            </el-input>
        </div>

        <el-button type="primary" :loading="loading" @click="apply">提交</el-button>
    </div>
</template>

<script>
  import BlogSingleInput from '@/pages/blog/BlogSingleInput'
  import BlogModel from '@/model/BlogModel'
  import {showError, showSuccess} from '@/utils/Message'

  export default {
    name: 'ApplyBlog',
    components: {BlogSingleInput},
    data () {
      return {
        introduction: '',
        blogName: '',
        blogUrl: '',
        loading: false
      }
    },
    methods: {
      apply () {
        if (this.blogName === '') {
          showError('博客名称不能为空')
          return
        }

        if (this.blogUrl === '') {
          showError('博客链接不能为空')
          return
        }

        if (this.introduction === '') {
          showError('博客介绍不能为空')
          return
        }

        this.loading = true
        BlogModel.apply({
          name: this.blogName,
          url: this.blogUrl,
          introduction: this.introduction
        }).then((data) => {
          this.loading = false
          this.blogName = ''
          this.blogUrl = ''
          this.introduction = ''
          showSuccess('提交成功, 等待后续审核')
        })
      }
    }
  }
</script>

<style lang="scss">

    .share-blog {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        padding: 20px;
        margin-right: 20px;
        background-color: white;

        .blog-title {
            font-size: 22px;
            font-weight: bold;
            margin-bottom: 20px;
        }

        .blog-introduction {
            width: 500px;
            display: flex;
            justify-content: space-between;
            align-items: flex-start;
            margin-top: 10px;

            .name {
                margin-right: 20px;
                margin-bottom: 20px;
            }

            .el-textarea {
                width: 400px;

                .el-textarea__inner {
                    height: 130px;
                }
            }
        }

        .el-button {
            width: 100px;
            margin-top: 20px;
        }
    }
</style>
