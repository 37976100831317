<template>
    <div class="bb-coin-container">
        <do-sidebar :show-add-menu-icon="false"
                    :menus="menus"/>
        <router-view/>
    </div>
</template>

<script>
import DoSidebar from '@/components/DoSidebar'

export default {
    name: 'BbCoin',
    components: {DoSidebar},
    data () {
        return {
            menus: [
                {
                    name: '金币记录',
                    viewPath: '/bulletin-board/bb-coin/bb-coin-record',
                },
            ]
        }
    },
}
</script>

<style scoped lang="scss">
.bb-coin-container {
    display: flex;
    height: 820px;
    background-color: white;
}
</style>
