import httpClient from '@/http/Axios'

export default class BlogModel {

  static apply (params) {
    return httpClient.post('/blog/apply', params)
  }

  static getList (params) {
    return httpClient.post('/blog/query', params)
  }

  static share (params) {
    return httpClient.post('/blog/share', params)
  }
}
