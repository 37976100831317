import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'

Vue.use(Vuex)

export default new Vuex.Store({
  plugins: [createPersistedState({
    paths: ['login', 'image', 'notice']
  })],
  modules: {
    login: {
      state: {
        loginBoxState: false,
        username: '登录',
        introduction: '',
        token: '',
        refreshToken: '',
        isLogin: false,
        userNavigationState: false,
      },
      mutations: {
        showLoginBox (state) {
          state.loginBoxState = true
        },
        closeLoginBox (state) {
          state.loginBoxState = false
        },
        hideUserNavigation (state) {
          state.userNavigationState = false
        },
        updateUserNavigationState (state) {
          state.userNavigationState = !state.userNavigationState
        },
        loginSuccess (state, data) {
          state.isLogin = true
          state.loginBoxState = false
          state.username = data.name
          state.token = data.token
          state.refreshToken = data.refreshToken
        },
        logout (state) {
          state.isLogin = false
          state.userNavigationState = false
          state.username = '登录'
          state.token = ''
          state.refreshToken = ''
          state.introduction = ''
        },
        refreshToken (state, data) {
          state.token = data.token
          state.refreshToken = data.refreshToken
        },
        joinToLoginBox (state) {
          state.isLogin = false
          state.userNavigationState = false
          state.username = '登录'
          state.token = ''
          state.refreshToken = ''
          state.loginBoxState = true
        },
        updateUserInfo (state, data) {
          state.username = data.username
          state.introduction = data.introduction
        },
        updateUsername(state, name) {
          state.username = name
        },
        updateIntroduction (state, introduction) {
          state.introduction = introduction
        },
      }
    },
    image: {
      state: {
        files: []
      },
      mutations: {
        clearImageFiles (state) {
          state.files = []
        },
        addCardImage (state, file) {
          state.files.push(file)
        },
        publishSuccess (state) {
          state.files = []
        },
        deleteCardImage (state, index) {
          state.files.splice(index, 1)
        }
      }
    },
    todo: {
      state: {},
      mutations: {}
    },
    card: {
      state: {
        cardTags: ['学习', '运动', '上班摸鱼', '今天学到了', 'BUG', '建议'],
        cardTag: '学习',
        navigations: ['最新', '学习', '运动', '上班摸鱼', '今天学到了', 'BUG', '建议'],
        selectTag: '最新',
        selectIndex: 0
      },
      mutations: {
        selectCardTag (state, data) {
          state.cardTag = data.tag === '最新' ? '学习' : data.tag
          state.selectTag = data.tag
          state.selectIndex = data.index
        },
        setPublishTag (state, tag) {
          state.cardTag = tag
        }
      },
      getters: {
        getTagType: state => {
          switch (state.selectTag) {
            case '学习':
              return 'STUDY'
            case '运动':
              return 'SPORT'
            case '上班摸鱼':
              return 'LAZY'
            case '今天学到了':
              return 'LEARNED_TODAY'
            case 'BUG':
              return 'BUG'
            case '建议':
              return 'ADVICE'
            default:
              return ''
          }
        }
      }
    },
    notice: {
      state: {
        showUpdateContent: true,
        unreadCount: 0
      },
      mutations: {
        hideUpdateContent (state) {
          state.showUpdateContent = !state.showUpdateContent
        },
        refreshUnreadNotice (state) {
          state.unreadCount = state.unreadCount - 1
        },
        updateNoticeCount (state, unreadCount) {
          state.unreadCount = unreadCount
        }
      }
    },
  }

})
