<template>
    <div class="bb-progress-container">

        <do-sidebar
            ref="sidebar"
            :menus="menus"
            menuTitle="进度列表"
            menu-type="index"
            show-menu-item-edit-icon
            :add-menu-click="showAddDialog"
            :edit-menu-item-click="showEditFolderDialog"
            :menu-item-click="menuItemClick"
        />

        <div class="bb-progress-content">

            <do-table-option-header
                :add-click="handleAdd"
                :batch-move-click="handleBatchMove"
                :batch-delete-click="handleBatchDelete"
                :options="progressStateOptions"
                v-model="progressSelectState"
                @input="progressStateChange"
            />

            <el-table
                ref="multipleTable"
                :data="tableData"
                @row-click="showEditDialog"
                stripe
                height="300"
                style="width: 100%"
            >
                <el-table-column
                    align="center"
                    type="selection"
                    prop="date">
                </el-table-column>

                <el-table-column
                    prop="title"
                    label="进度名称"
                >
                </el-table-column>
                <el-table-column
                    label="进度"
                    width="100"
                >
                    <template slot-scope="scope">
                        {{ formatProgress(scope.row) }}
                    </template>
                </el-table-column>

                <el-table-column
                    align="center"
                    label="进度条"
                    width="170"
                >
                    <template slot-scope="scope">
                        <el-progress :text-inside="true" :stroke-width="26" :percentage="formatProgressBar(scope.row)"/>
                    </template>
                </el-table-column>

                <el-table-column
                    align="center"
                    property="createTime"
                    label="日期"
                    width="100">
                    <template slot-scope="scope">
                        {{ formatCreateTime(scope.row.createTime) }}
                    </template>
                </el-table-column>


                <el-table-column
                    align="center"
                    label="是否专注"
                    width="100">
                    <template slot-scope="scope">
                        <el-switch
                            :value="scope.row.flag"
                            :active-value="activeFlagValue"
                            :active-color="globalColors.primaryColor"
                            inactive-color="#dcdfe6"
                            @change="changeFocusState($event, scope.row)"
                        >
                        </el-switch>
                    </template>
                </el-table-column>


                <el-table-column align="center" label="操作" width="100">
                    <template slot-scope="scope">
                        <el-dropdown>
                            <i class="el-icon-more-outline"/>

                            <el-dropdown-menu slot="dropdown">

                                <div v-for="(data) in operationData" :key="data.state">
                                    <el-dropdown-item
                                        v-show="scope.row.state !== data.state"
                                        @click.native="handleOperation(data, scope.$index, scope.row)"
                                    >
                                        {{ data.content }}
                                    </el-dropdown-item>
                                </div>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination
                background
                @current-change="getProgress"
                :current-page.sync="page"
                :page-size="size"
                layout="total, prev, pager, next"
                :total="total"
            >
            </el-pagination>
        </div>

        <el-dialog
            title="添加列表"
            :visible.sync="folderDialogVisible"
            width="30%"
            :before-close="() => this.folderDialogVisible = false"
            @keyup.enter.native="confirmFolder"
        >
            <column-input title="列表名称"
                          title-placeholder="请输入列表名称"
                          v-model="editTitle"
                          width="auto"
                          maxlength="8"/>

            <span slot="footer">
                <el-button type="danger" @click="deleteFolder">删 除</el-button>
                <el-button @click="folderDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmFolder">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog
            class="el-dialog-move-container"
            title="移动进度"
            :visible.sync="moveDialogVisible"
            width="30%"
            :before-close="() => this.moveDialogVisible = false"
            @keyup.enter="confirmMove"
        >

            <div class="folder-container">
                <div class="folder-title">文件夹</div>

                <el-select v-model="moveFolderId">
                    <el-option
                        v-for="item in folderData"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </div>

            <span slot="footer">
                <el-button @click="moveDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmMove">确 定</el-button>
            </span>
        </el-dialog>

        <progress-add-item ref="progressAddItem"
                           :list="tableData"/>

    </div>
</template>

<script>
import ProgressModel from '@/model/ProgressModel'
import {formatDate} from '@/utils/Date'
import {showCloseDialog} from '@/utils/Dialog'
import {deleteByKey, getByKey} from '@/utils/Array'
import {showError, showSuccess} from '@/utils/Message'
import ProgressAddItem from '@/pages/home/progress/ProgressAddItem'
import ColumnInput from '@/components/ColumnInput'
import FolderModel from '@/pages/bulletin-board/todo/FolderModel'
import Colors from '@/assets/Colors.scss'
import DoSidebar from '@/components/DoSidebar'
import DoTableOptionHeader from '@/components/DoTableOptionHeader'
import CardModel from '@/model/CardModel'

export default {
    name: 'BbProgress',
    components: {DoTableOptionHeader, DoSidebar, ColumnInput, ProgressAddItem},
    data () {
        return {
            activeFlagValue: 'FOCUS',
            primaryColor: '#2c3e50',
            menuSelectId: '0',
            menusSelectName: '',
            menus: [
                {
                    id: '0',
                    name: '默认',
                },
            ],
            moveFolderId: '',
            folderData: [],
            progressSelectState: 'IN_PROGRESS',
            progressStateOptions: [
                {
                    label: '全部',
                    key: 'ALL'
                }, {
                    label: '进行中',
                    key: 'IN_PROGRESS'
                }, {
                    label: '完成',
                    key: 'FINISH'
                },
            ],
            page: 1,
            size: 10,
            tableData: [],
            total: 0,
            operationData: [
                {
                    content: '编辑',
                    state: 'EDIT'
                },
                {
                    content: '增加',
                    state: 'INCREMENT'
                },
                {
                    content: '减少',
                    state: 'DECREMENT'
                },
                {
                    content: '删除',
                    state: 'DELETE'
                },
                {
                    content: '移动',
                    state: 'MOVE'
                },
            ],
            folderDialogVisible: false,
            folderType: 'ADD',
            editTitle: '',
            moveDialogVisible: false,
            moveProgressIds: [],
            moveProgressId: 0,
            folderId: 0
        }
    },
    mounted () {
        this.menusSelectName = this.menus[0].name
        this.getMenu()
        this.getProgress(1)
    },
    computed: {
        globalColors () {
            return Colors
        }
    },
    methods: {
        formatCreateTime (createTime) {
            return formatDate(createTime, 'YY-MM-DD')
        },
        formatProgressBar (data) {
            return Math.round(100 / data.total * data.progress)
        },
        formatProgress (data) {
            return data.progress + '/' + data.total + '' + data.unit
        },
        getMenu () {
            FolderModel.getMenu({
                page: 0,
                size: this.menusSize,
                type: 'PROGRESS'
            }).then((response) => {
                this.menus = this.menus.concat(response.list)
            })
        },
        getProgress (page) {
            ProgressModel.query({
                page: page - 1,
                size: this.size,
                state: this.progressSelectState === 'ALL' ? null : this.progressSelectState,
                folderId: this.menuSelectId,
            }).then(pageResponse => {
                this.tableData = pageResponse.list
                this.total = pageResponse.total
            })
        },
        showAddDialog () {
            this.folderType = 'ADD'
            this.folderDialogVisible = true
        },
        confirmFolder () {

            if (this.folderType === 'EDIT') {
                FolderModel.update({
                    id: this.folderId,
                    name: this.editTitle,
                }).then((data) => {
                    const curData = getByKey(this.menus, (data) => data.id === this.folderId)
                    curData.name = data.name

                    this.editTitle = ''
                    this.folderDialogVisible = false
                })

                return
            }

            FolderModel.add({
                name: this.editTitle,
                type: 'PROGRESS'
            }).then((data) => {

                this.menus.splice(1, 0, {
                    id: data.id,
                    name: this.editTitle
                })
                this.editTitle = ''
                this.folderDialogVisible = false
            })

        },
        deleteFolder () {
            showCloseDialog('删除当前目录，目录下进度归档到「默认」目录下，确定要删除吗？')
                .then(() => {
                    FolderModel.delete({
                        id: this.folderId,
                        type: 'PROGRESS'
                    }).then(data => {
                        deleteByKey(this.menus, (menu) => menu.id === this.folderId)
                        showSuccess('删除成功')

                        // 切换到 默认目录显示
                        this.$refs.sidebar.changeMenuStyle('0')
                        this.folderId = 0
                        this.editTitle = ''
                        this.folderDialogVisible = false
                    })
                })
        },
        showEditFolderDialog (menu) {
            this.folderDialogVisible = true
            this.editTitle = menu.name
            this.folderType = 'EDIT'
            this.folderId = menu.id
        },
        menuItemClick (curMenuId) {
            if (curMenuId === this.menuSelectId) {
                return
            }

            this.menuSelectId = curMenuId

            const menu = getByKey(this.menus, (menu) => menu.id.toString() === curMenuId)
            this.menusSelectName = menu.name
            this.getProgress(1)
        },
        handleAdd () {
            this.$refs.progressAddItem.showDialog(this.menuSelectId)
        },
        handleBatchMove () {
            const selectData = this.$refs.multipleTable.selection
            if (selectData.length <= 0) {
                showError('请先勾选进度 再操作')
                return
            }

            FolderModel.getMenu({
                page: 0,
                size: this.menusSize,
                type: 'PROGRESS'
            }).then((response) => {
                this.refreshFolderData(response.list)
                this.moveDialogVisible = true
                this.moveProgressIds = selectData.map((data) => data.id)
                this.moveType = 'BATCH'
            })
        },
        confirmMove () {
            if (this.moveFolderId === '') {
                showError('请选择移动目录')
                return
            }

            if (this.moveType === 'SINGLE') {
                ProgressModel.move({
                    folderId: this.moveFolderId,
                    progressId: this.moveProgressId,
                }).then((data) => {
                    this.tableData = this.tableData.filter(data => this.moveProgressId !== data.id)

                    this.moveFolderId = ''
                    this.moveProgressId = 0
                    this.moveDialogVisible = false
                })
                return
            }

            ProgressModel.moveBatch({
                folderId: this.moveFolderId,
                progressIds: this.moveProgressIds,
            }).then((data) => {
                this.tableData = this.tableData.filter(data => this.moveProgressIds.indexOf(data.id) === -1)

                this.moveFolderId = ''
                this.moveTodoIds = []
                this.moveDialogVisible = false
            })
        },
        handleBatchDelete () {
            const selectData = this.$refs.multipleTable.selection
            if (selectData.length <= 0) {
                showError('请先勾选进度 再操作')
                return
            }

            const taskIds = selectData.map((data) => data.id)

            showCloseDialog('确定要删除吗？')
                .then(() => {
                    ProgressModel.deleteBatch({
                        taskIds: taskIds
                    }).then((data) => {
                        showSuccess('删除成功')
                        const newTotal = this.total - taskIds.length
                        this.total = newTotal > 0 ? newTotal : 0
                        this.tableData = this.tableData.filter(data => taskIds.indexOf(data.id) === -1)
                    })
                })
        },
        progressStateChange () {
            this.getProgress(1)
        },
        handleOperation (operation, index, progressData) {
            switch (operation.state) {
                case 'EDIT':
                    this.handleEdit(index, progressData)
                    break
                case 'DELETE':
                    this.handleDelete(index, progressData)
                    break
                case 'MOVE':
                    this.handleMove(index, progressData)
                    break
                case 'INCREMENT':
                    this.handleIncrement(index, progressData)
                    break
                case 'DECREMENT':
                    this.handleDecrement(index, progressData)
                    break
            }
        },
        handleEdit (index, progressData) {
            this.$refs.progressAddItem.showEditContent(progressData)
        },
        showEditDialog (row, column) {
            if (column && column.label === '操作') {
                return
            }

            this.$refs.progressAddItem.showEditContent(row)
        },
        handleMove (index, progressData) {
            FolderModel.getMenu({
                page: 0,
                size: this.menusSize,
                type: 'PROGRESS'
            }).then((response) => {
                this.refreshFolderData(response.list)
                this.moveDialogVisible = true
                this.moveProgressId = progressData.id
                this.moveType = 'SINGLE'
            })
        },
        handleDelete (index, progressData) {
            showCloseDialog('确定要删除吗？')
                .then(() => {
                    ProgressModel.delete({
                        id: progressData.id
                    }).then((data) => {
                        this.total = this.total - 1
                        deleteByKey(this.tableData, (task) => task.id === progressData.id, (curData) => showSuccess('删除成功'))
                    })
                })
        },
        handleIncrement (index, progressData) {
            if (progressData.percentage >= 100) {
                return
            }

            ProgressModel.increment({
                progressTaskId: progressData.id
            }).then(data => {
                const curProgressData = getByKey(this.tableData, (data) => data.id === progressData.id)
                curProgressData.progress += parseInt(progressData.increment)
            })
        },
        handleDecrement (index, progressData) {
            if (progressData.percentage <= 0) {
                return
            }

            ProgressModel.decrease({
                progressTaskId: progressData.id
            }).then(data => {
                const curProgressData = getByKey(this.tableData, (data) => data.id === progressData.id)
                curProgressData.progress -= parseInt(progressData.increment)
            })
        },
        changeFocusState (curFlag, progressData) {
            if (curFlag === this.activeFlagValue) {
                ProgressModel.focus({progressId: progressData.id})
                    .then(data => this.refreshFlag(progressData.id, curFlag))
                return
            }

            ProgressModel.unfocused({id: progressData.id})
                .then(data => this.refreshFlag(progressData.id, curFlag))
        },
        refreshFlag (id, curFlag) {
            const curData = getByKey(this.tableData, (data) => data.id === id)
            curData.flag = curFlag
        },
        refreshFolderData (curFolderData) {
            curFolderData.unshift({
                name: '默认',
                id: 0
            })
            this.folderData = curFolderData
        }
    }
}
</script>

<style scoped lang="scss">
@import "src/assets/Colors";

.bb-progress-container {
    width: 1300px;
    height: 800px;
    display: flex;
    background-color: white;

    .bb-progress-sidebar {
        width: 180px;
        height: 800px;
        padding-left: 10px;
        padding-top: 20px;

        .sidebar-title-area {
            display: flex;
            align-items: center;
            justify-content: space-evenly;

            span {
                font-size: 18px;
                font-weight: bold;
            }

            .el-icon-circle-plus-outline {
                font-size: 24px;
                cursor: pointer;
            }
        }

        .el-menu {
            margin-top: 14px;
        }

        .el-menu-item {
            text-align: left;
            margin-bottom: 6px !important;
            font-weight: bold !important;

            &:hover {
                animation: myTranslateX 0.8s;
                background-color: rgba(0, 0, 0, 0) !important;
            }

            &.is-active {
                background-color: rgba(0, 0, 0, 0) !important;
            }

            @keyframes myTranslateX {
                0% {
                    transform: translateX(4px);
                }
                100% {
                    transform: translateX(0);
                }
            }
        }

        .menu-item-select-background {
            padding-left: 10px;
            border-radius: 12px;
            display: flex;
            align-items: center;
            justify-content: space-between;

            &:hover {
                background-color: #f3f3f3;
            }

            &.select {
                color: white;
                background-color: $primary;
            }
        }
    }

    .bb-progress-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;

        .state-container {
            text-align: center;
            border-radius: 4px;
            color: white;

            &.FINISH {
                background-color: #00b8a9;
            }

            &.VETO {
                background-color: #f5acac;
            }

            &.CREATE {
                background-color: #cecece;
            }
        }


        .el-icon-more-outline {
            padding: 6px;
            border-radius: 6px;
            font-size: 20px;

            &:hover {
                background-color: #dcdcdc;
            }
        }

        .el-pagination {
            margin-top: 10px;
        }
    }

    .el-dialog-move-container {

        .folder-container {
            display: flex;
            align-items: center;

            .folder-title {
                margin-right: 10px;
            }
        }
    }
}
</style>
