<template>
    <div class="comment">
        <div class="comment-input-area">
            <el-avatar :size="50" :src="circleUrl"/>
            <el-input @keypress.enter.native="addComment"
                      v-model="commentInput"
                      placeholder="输入评论 (Enter 回复)"
            />
        </div>

        <comment-item :card-id="cardId" :comments="comments" :is-comment-item="false"/>

        <el-pagination
                :hide-on-single-page="!showPagination"
                background
                @current-change="getMoreComments"
                :current-page.sync="page"
                :page-size="size"
                layout="total, prev, pager, next"
                :total="total"
        >
        </el-pagination>
    </div>
</template>

<script>
  import {showSuccess} from '@/utils/Message'
  import CommentModel from '@/pages/home/card/comment/model/CommentModel'
  import {beautifulTime} from '@/utils/Date'
  import CommentItem from '@/pages/home/card/comment/CommentItem'

  export default {
    name: 'Comment',
    components: {CommentItem},
    props: {
      cardId: Number,
    },
    data () {
      return {
        commentInput: '',
        circleUrl: 'https://cube.elemecdn.com/3/7c/3ea6beec64369c2642b92c6726f1epng.png',
        replyInput: '',
        replyCommentInput: '',
        comments: [],
        page: 0,
        size: 10,
        total: 10
      }
    },
    computed: {
      showPagination () {
        return this.total / this.size > 1
      }
    },
    created () {
      CommentModel.get({
        page: this.page,
        size: this.size,
        cardId: this.cardId
      }).then((data) => {
        this.total = data.total
        this.comments = data.list
        this.comments.forEach((value) => {
          value.commentReplyState = false
        })
      })
    },
    methods: {
      addComment () {
        CommentModel.publish({
          content: this.commentInput,
          cardId: this.cardId
        }).then((data) => {
          this.comments.unshift(data)
          showSuccess('回复成功')
          this.commentInput = ''
          this.$emit('addCommentSuccess')
        })
      },
      getMoreComments (page) {
        CommentModel.get({
          page: page - 1,
          size: this.size,
          cardId: this.cardId
        }).then((data) => {
          this.total = data.total
          this.comments = data.list
          this.comments.forEach((value) => {
            value.commentReplyState = false
          })
        })
      }
    }
  }
</script>

<style lang="scss">
    @import "src/assets/Styles";

    .comment {
        border-top: 1px solid #CECECE;
        padding: 20px;

        .comment-input-area {
            display: flex;
            align-items: center;

            .el-avatar {
                margin-right: 20px;
            }

            .el-input {
                flex: 1;
            }
        }

        .el-pagination {
            margin-top: 10px;
        }
    }
</style>
