<template>
    <div class="card-content">
        <vue-data-loading :listens="['infinite-scroll']" @infinite-scroll="infiniteScroll">
            <div v-for="(content) in cardContents" :key="content.id">
                <card-content-item :card-data="content"
                                   :right-label="rightLabel"
                                   @rightLabelClick="rightLabelClick"/>
            </div>
        </vue-data-loading>
        <div class="no-more" v-show="_showNoMore">我们是有底线的 ：)</div>
    </div>
</template>

<script>
  import CardContentItem from '@/pages/home/card/content/CardContentItem'
  import VueDataLoading from 'vue-data-loading'

  export default {
    name: 'CardContent',
    components: {CardContentItem, VueDataLoading},
    data () {
      return {
        showNoMore: false,
      }
    },
    props: {
      rightLabel: String,
      cardContents: Array,
      total: Number,
      page: Number
    },
    created () {
      this.showNoMore = false
      this.$emit('initData', 0)
    },
    computed: {
      _showNoMore () {
        return this.showNoMore && this.total !== 0 && this.cardContents.length !== 0
      }
    },
    methods: {
      infiniteScroll () {
        if (this.cardContents.length === this.total) {
          console.log('no more')
          this.showNoMore = true
          return
        }

        let newPage = this.page + 1
        this.$emit('infiniteScroll', newPage)
      },
      rightLabelClick (id) {
        this.$emit('rightLabelClick', id)
      }
    }
  }
</script>

<style lang="scss">
    .card-content {

        .no-more {
            margin-top: 20px;
            margin-bottom: 20px;
        }
    }
</style>
