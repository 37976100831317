<template>
    <div class="home">
        <div class="home-navigation">
            <div v-for="(navigation, index) in navigations" :key="index">
                <div class="home-navigation-item"
                     @click="selectNavigation(index)"
                     :class="{select:selectIndex===index}"
                >{{navigation}}
                </div>
            </div>
        </div>

        <card/>

        <div class="right-box">

            <div class="rank">
                <div class="rank-title">打卡榜</div>
                <span class="rank-tips">(每天凌晨2点更新)</span>

                <div v-for="(rank, index) in ranks" :key="index">
                    <div class="rank-item">
                        <span>{{index+1}}</span>
                        <el-avatar :src="rank.avatarUrl"/>

                        <do-overflow-tooltip
                                :content="rank.name"
                                :class-name="['rank-name']"
                        />

                        <div class="rank-cumulative-day">
                            {{rank.cumulativeDay}}天
                        </div>
                    </div>
                </div>

                <div class="rank-more" @click="toFullRank">完整排行榜</div>
            </div>

            <statistics/>
        </div>
    </div>
</template>

<script>
  import Card from '@/pages/home/card/Card'
  import Todo from '@/pages/home/todo/Todo'
  import {Notification} from 'element-ui'
  import CardRankModel from '@/model/CardRankModel'
  import Statistics from '@/pages/home/statistics/Statistics'
  import SignIn from '@/pages/home/sign-in/SignIn'
  import DoProgress from '@/pages/home/progress/DoProgress'
  import DoOverflowTooltip from '@/components/DoOverflowTooltip'
  import DoDayMatter from '@/pages/home/day-matter/DoDayMatter'

  export default {
    name: 'Home',
    components: {DoDayMatter, DoOverflowTooltip, SignIn, Statistics, Todo, Card, DoProgress},
    data () {
      return {
        navigations: this.$store.state.card.navigations,
        selectIndex: 0,
        ranks: []
      }
    },
    created () {
      if (this.$store.state.notice.showUpdateContent) {
        Notification.success({
          title: 'Do - V1.0.4',
          dangerouslyUseHTMLString: true,
          message: '1. 新增每日签到<br>' +
            '2. 新增评论功能<br>' +
            '3. 优化亿点细节<br>',
          duration: 0,
          onClose: () => {
            this.$store.commit('hideUpdateContent')
          }
        })
      }

      this.selectIndex = this.$store.state.card.selectIndex
      this.getRankTop()
    },
    methods: {
      selectNavigation (index) {
        this.selectIndex = index
        this.$store.commit('selectCardTag', {
          tag: this.navigations[index],
          index
        })
      },

      getRankTop () {
        CardRankModel.query({page: 0, size: 5})
          .then(data => {
            this.ranks = data.list
          })
      },
      toFullRank () {
        this.$router.push('/rank')
      },
    }
  }
</script>

<style lang="scss">
    @import "src/assets/Sizes";
    @import "src/assets/Colors";

    .home {
        width: $global-content-width;
        display: flex;
        position: relative;

        .home-navigation {
            width: 120px;
            height: 300px;
            display: flex;
            flex-direction: column;
            position: sticky;
            margin-right: 20px;
            padding-top: 20px;
            padding-bottom: 20px;
            margin-top: $global-content-margin-top;
            background-color: white;
            box-shadow: 2px 2px 2px #CECECE;
            border-radius: 4px;

            .home-navigation-item {
                vertical-align: center;
                padding-top: 10px;
                padding-bottom: 10px;
                cursor: pointer;

                &:hover {
                    background-color: #f6f6f6;
                }

                &.select {
                    color: white;
                    background-color: $primary;
                }
            }
        }

        .right-box {
            width: 260px;
            right: 0;
            display: flex;
            flex-direction: column;
            position: absolute;
            margin-left: 30px;

            .rank {
                height: 390px;
                background-color: white;
                margin-top: $global-content-margin-top;
                padding-top: 10px;
                padding-bottom: 10px;
                border-radius: 6px;
                box-shadow: 2px 2px 2px #CECECE;

                .rank-title {
                    font-size: 20px;
                    font-weight: bold;
                }

                .rank-tips {
                    font-size: 14px;
                    color: #979797;
                }

                .rank-item {
                    display: flex;
                    align-items: center;
                    padding: 8px;
                    margin-top: 10px;

                    span {
                        margin-left: 6px;
                        margin-right: 4px;
                    }

                    &:hover {
                        background-color: #e7e7e7;
                    }

                    .el-avatar {
                        width: 36px;
                        height: 36px;
                        margin-right: 6px;
                    }

                    .rank-name {
                        flex: 1;
                        font-weight: bold;
                        margin-right: 4px;
                        text-align: start;
                        overflow: hidden;
                        white-space: nowrap;
                        text-overflow: ellipsis;
                    }

                    .rank-cumulative-day {
                        max-width: 54px;
                        text-align: right;
                        font-weight: bold;
                        color: #ff7f50;
                    }
                }

                .rank-more {
                    height: 30px;
                    line-height: 30px;
                    margin-top: 10px;

                    &:hover {
                        cursor: pointer;
                        background-color: #e7e7e7;
                    }
                }
            }
        }
    }
</style>
