<template>
    <div class="comment-item">
        <div v-for="(comment, index) in comments" :key="comment.id">
            <div class="comment-item-area">
                <el-avatar :size="40" :src="comment.userAvatarUrl"/>
                <div class="comment-item-child">
                    <div class="comment-item-name-area">
                        <div class="comment-item-name">{{comment.username}}</div>
                        <div v-if="comment.targetUsername !== null" class="comment-item-target-name">
                            回复 {{comment.targetUsername}}
                        </div>
                    </div>
                    <div class="comment-item-content">{{comment.content}}</div>
                    <div class="comment-item-footer">
                        <div class="comment-item-time">{{commentTime(comment.createTime)}}</div>

                        <praise :resource-id="comment.id"
                                resource-type="COMMENT"
                                :praise-self="comment.praiseSelf"
                                :old-praise-total="comment.praiseTotal"
                                :show-text="false"
                        />
                        <img alt="" src="@/assets/icons/comments.png" @click="showCommentReply(index)"/>
                    </div>

                    <el-input
                            v-show="comment.commentReplyState"
                            @keypress.enter.native="replyComment(index)"
                            v-model="replyInput"
                            placeholder="输入评论 (Enter 回复)"
                    />

                    <comment-item :card-id="cardId"
                                  :comments="comment.replyComments"
                                  :is-comment-item="true"
                                  :comment-id="comment.id"
                    />

                    <el-pagination
                            background
                            :hide-on-single-page="comment.replyCommentTotal <= size"
                            :current-page.sync="page"
                            :page-size="size"
                            @current-change="getMoreReplyComments(page, index)"
                            layout="total, prev, pager, next"
                            v-show="!isCommentItem"
                            :total="comment.replyCommentTotal"
                    >
                    </el-pagination>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import {beautifulTime} from '@/utils/Date'
  import CommentModel from '@/pages/home/card/comment/model/CommentModel'
  import {showSuccess} from '@/utils/Message'
  import ResourcePraiseModel from '@/model/ResourcePraiseModel'
  import Praise from '@/components/Praise'

  export default {
    name: 'CommentItem',
    components: {Praise},
    props: {
      cardId: Number,
      comments: Array,
      isCommentItem: Boolean,
      commentId: Number,
      praiseTotal: Number,
      praiseSelf: Boolean
    },
    data () {
      return {
        replyInput: '',
        page: 0,
        size: 10,
        commonPraiseState: false,
        praiseState: true
      }
    },
    methods: {
      commentTime (time) {
        return beautifulTime(time)
      },
      showCommentReply (index) {
        const curComment = this.comments[index]
        curComment.commentReplyState = !curComment.commentReplyState
        this.$set(this.comments, index, curComment)
      },
      replyComment (index) {

        if (!this.isCommentItem) {
          CommentModel.reply({
            content: this.replyInput,
            cardId: this.cardId,
            commentId: this.comments[index].id,
          }).then((data) => {
            const comment = this.comments[index]

            if (comment.replyComments === null) {
              comment.replyComments = [data]
            } else {
              comment.replyComments.push(data)
            }

            showSuccess('回复成功')
            this.replyInput = ''
          })
        } else {
          CommentModel.reply({
            content: this.replyInput,
            cardId: this.cardId,
            replyCommentId: this.comments[index].id,
            commentId: this.commentId
          }).then((data) => {
            this.comments.push(data)
            showSuccess('回复成功')
            this.replyInput = ''
          })
        }
      },
      getMoreReplyComments (page, index) {
        CommentModel.getReplyComments({
          page: page - 1,
          size: this.size,
          cardId: this.cardId,
          commentId: this.comments[index].id
        }).then((data) => {
          this.comments[index].replyComments = data.list
        })
      },
    }
  }
</script>

<style scoped lang="scss">
    @import "src/assets/Styles";

    .comment-item-area {
        width: 600px;
        display: flex;
        margin-top: 20px;
        margin-left: 70px;

        .comment-item-child {
            flex: 1;
            display: flex;
            flex-direction: column;
            text-align: start;

            .comment-item-name-area {
                display: flex;

                .comment-item-name {
                    font-weight: bold;
                    margin-left: 10px;
                }

                .comment-item-target-name {
                    color: #a7a7a7;
                    margin-left: 6px;
                }
            }

            .comment-item-content {
                @include auto-wrap;
                flex: 1;
                margin-top: 6px;
                margin-left: 10px;
            }

            .comment-item-footer {
                display: flex;
                align-items: center;
                margin-top: 6px;

                .comment-item-time {
                    flex: 1;
                    color: #a7a7a7;
                    margin-left: 10px;
                }

                img {
                    width: 20px;
                    margin-left: 30px;
                    cursor: pointer;
                }
            }

            .el-input {
                margin-left: 10px;
                margin-top: 10px;
            }

            .comment-item-area {
                width: 520px;
                margin-left: 10px;
            }

            .el-pagination {
                margin-left: 10px;
            }
        }
    }
</style>
