<template>
    <div class="blog">

        <div class="blog-content">
            <router-view/>
        </div>

        <div class="blog-right-box">
            <div class="blog-navigation-area">
                <div class="blog-tips">博客是一个人的狂欢</div>
                <div v-show="this.$store.state.login.isLogin"
                     class="blog-button"
                     @click="toApplyBlog">分享个人博客
                </div>
                <div class="blog-button"
                     @click="toShareBlog">推荐他人博客
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import BlogList from '@/pages/blog/BlogList'

  export default {
    name: 'Blog',
    components: {BlogList},
    created () {
      this.$router.push('/blog-list')
    },
    methods: {
      toApplyBlog () {
        this.$router.push('/apply')
      },
      toShareBlog () {
        this.$router.push('/share')
      }
    }
  }
</script>

<style scoped lang="scss">
    @import "src/assets/Colors";
    @import "src/assets/Sizes";

    .blog {
        width: $global-content-width;
        display: flex;
        margin-top: $global-content-margin-top;

        .blog-content {
            flex: 1;
        }

        .blog-right-box {
            width: 260px;

            .blog-navigation-area {
                width: 100%;
                height: 140px;
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                padding-top: 20px;
                padding-bottom: 20px;
                background-color: white;

                .blog-tips {
                    font-weight: bold;
                }

                .blog-button {
                    width: 160px;
                    height: 30px;
                    line-height: 30px;
                    padding: 4px;
                    margin-top: 10px;
                    color: white;
                    cursor: pointer;
                    border-radius: 40px;
                    background-color: $primary;
                }
            }

        }
    }
</style>
