import httpClient from '@/http/Axios'

export default class FolderModel {

  static add (params) {
    return httpClient.post('/folder/add', params)
  }

  static getMenu (params) {
    return httpClient.get('/folder/page', {params: params})
  }

  static update (params) {
    return httpClient.put('/folder', params)
  }

  static delete (params) {
    return httpClient.post('/folder/delete', params)
  }
}
