<template>
    <div class="container" @click.stop>
        <div class="progress-content">

            <progress-add-item ref="progressAddItem"
                               :list="progressData"/>

            <div class="progress-list-container" v-infinite-scroll="loadMore">

                <div class="progress-item-header-container" v-show="!isEmpty">
                    <div class="progress-name">名称</div>
                    <div class="progress-txt-title">进度</div>
                    <div class="progress-title">进度条</div>
                    <div class="progress-time">时间</div>
                    <div class="progress-btn-title">功能</div>
                </div>

                <div v-for="(data) in effectiveProgressData" :key="data.id">
                    <do-progress-item :progress-data="data"
                                      @showProgressAddItem="showProgressAddItem"
                                      @deleteProgress="deleteProgress"
                    />
                </div>

                <div class="empty-placeholder" v-show="isEmpty">
                    没有进度？
                </div>
            </div>

        </div>

    </div>
</template>

<script>
  import DoProgressItem from '@/pages/home/progress/DoProgressItem'
  import ProgressAddItem from '@/pages/home/progress/ProgressAddItem'
  import ProgressModel from '@/model/ProgressModel'
  import {showCloseDialog} from '@/utils/Dialog'
  import {deleteByKey} from '@/utils/Array'
  import {showSuccess} from '@/utils/Message'

  export default {
    name: 'DoProgress',
    components: {ProgressAddItem, DoProgressItem},
    data () {
      return {
        progressData: [],
        page: 0,
        size: 10,
        progressDataTotal: 0
      }
    },
    created () {
      if (!this.isLogin) {
        return
      }

      ProgressModel.queryFocus({
        page: this.page,
        size: this.size,
      }).then(pageResponse => {
        this.page += 1
        this.progressData = pageResponse.list
        this.progressDataTotal = pageResponse.total
      })
    },
    methods: {
      showProgressAddDialog () {
        this.$refs.progressAddItem.showDialog()
      },
      deleteProgress (progressTaskId) {
        showCloseDialog('确定要删除吗？')
          .then(() => {
            ProgressModel.delete({
              id: progressTaskId
            }).then((data) => {
              deleteByKey(this.progressData, (task) => task.id === progressTaskId, (curData) => {
                showSuccess('删除成功')
              })
            })
          })
      },
      loadMore () {
        if (this.progressData.length === this.progressDataTotal) {
          console.log('no more progress task')
          return
        }

        ProgressModel.queryFocus({
          page: this.page,
          size: this.size,
        }).then(pageResponse => {
          this.page += 1
          this.progressData = this.progressData.concat(pageResponse.list)
        })
      },
      showProgressAddItem (progressData) {
        this.$refs.progressAddItem.showEditContent(progressData)
      }
    },
    computed: {
      isEmpty () {
        return this.progressData.length === 0
      },
      isLogin () {
        return this.$store.state.login.isLogin
      },
      effectiveProgressData () {
        return this.progressData.filter(data => data.flag === 'FOCUS')
      }
    },
  }
</script>

<style scoped lang="scss">
    @import "src/assets/Styles";
    @import "src/assets/Colors";

    .container {
        width: 100%;
        display: flex;
        margin-top: $global-content-margin-top;

        .progress-content {
            width: 100%;
            display: flex;
            flex-direction: column;
            overflow: auto;

            .progress-list-container {
                display: flex;
                flex-direction: column;
                height: 350px;
                padding-left: 16px;

                .progress-item-header-container {
                    width: 100%;
                    height: 40px;
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    background-color: white;
                    color: $primary;
                    position: sticky;
                    top: 0;
                    font-size: 18px;
                    font-weight: bold;
                    z-index: 100;
                    padding-top: 14px;
                    padding-bottom: 14px;
                    border-bottom: 1px solid #e7e7e7;

                    .progress-name {
                        width: 300px;
                    }

                    .progress-txt-title {
                        width: 100px;
                        margin-right: 10px;
                    }

                    .progress-title {
                        width: 200px;
                        text-align: center;
                    }

                    .progress-time {
                        width: 100px;
                        margin-left: 10px;
                        text-align: center;
                    }

                    .progress-btn-title {
                        width: 150px;
                        margin-left: 10px;
                        text-align: center;
                    }
                }
            }


            .empty-placeholder {
                flex: 1;
                color: #999999;
                display: flex;
                align-items: center;
                justify-content: center;
            }
        }
    }
</style>
