import httpClient from '@/http/Axios'

export default class LuckDrawModel {

  static getPrize () {
    return httpClient.get('/gift/query/prize')
  }

  static luckDraw () {
    return httpClient.post('/gift/luck-draw')
  }
}
