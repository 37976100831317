<template>
    <div class="praise-container" @click="clickPraise">
        <img v-show="praiseState" alt="" src="@/assets/icons/has_praise.png"/>
        <img v-show="!praiseState" alt="" src="@/assets/icons/praise.png"/>
        <span v-show="showText & !praiseState">点赞</span>
        <span>{{praiseCount}}</span>
    </div>
</template>

<script>
  import ResourcePraiseModel from '@/model/ResourcePraiseModel'
  import {showSuccess} from '@/utils/Message'

  export default {
    name: 'Praise',
    props: {
      resourceId: Number,
      resourceType: String,
      praiseSelf: Boolean,
      oldPraiseTotal: Number,
      showText: {
        type: Boolean,
        default: true
      }
    },
    data () {
      return {
        praiseState: this.praiseSelf,
        praiseTotal: this.oldPraiseTotal,
      }
    },
    watch: {
      praiseSelf (newData, oldData) {
        this.praiseState = newData
      },
      oldPraiseTotal (newData, oldData) {
        this.praiseTotal = newData
      }
    },
    computed: {
      praiseCount () {
        return this.praiseTotal > 0 ? `(${this.praiseTotal})` : ''
      }
    },
    methods: {
      clickPraise () {

        if (this.praiseState) {
          this.cancelPraise()
          return
        }

        ResourcePraiseModel.praise({
          resourceId: this.resourceId,
          contentResourceType: this.resourceType
        }).then((data) => {
          this.praiseTotal = this.praiseTotal + 1
          this.praiseState = !this.praiseState
          showSuccess('点赞成功')
        })
      },
      cancelPraise () {
        ResourcePraiseModel.cancelPraise({
          resourceId: this.resourceId,
          contentResourceType: this.resourceType
        }).then((data) => {
          let newPraiseTotal = this.praiseTotal - 1
          this.praiseTotal = newPraiseTotal > 0 ? newPraiseTotal : 0
          this.praiseState = !this.praiseState
          showSuccess('取消点赞成功')
        })
      },
    }
  }
</script>

<style scoped lang="scss">
    .praise-container {
        display: flex;
        align-items: center;
        justify-content: center;

        &:hover {
            cursor: pointer;
        }

        img {
            width: 20px;
            margin-right: 4px;
        }
    }
</style>
