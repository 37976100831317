import httpClient from '@/http/Axios'

export default class CardModel {

  static getContent (param) {
    return httpClient.post('/card/query/all', param)
  }

  static getDetail (param) {
    return httpClient.post('/card/query/detail', param)
  }

  static publish (param) {
    return httpClient.post('/card/publish', param)
  }

  static async uploadImages (files) {
    const data = new FormData()

    files.forEach(file => {
      data.append('files', file)
    })

    return httpClient.post('/file/upload/batch', data)
  }

  static getContentByUser (param) {
    return httpClient.post('/card/query/user', param)
  }

  static delete (param) {
    return httpClient.post('/card/delete', param)
  }
}
