<template>
    <div class="table-option-header-container">
        <div class="option-title">{{ title }}</div>
        <div class="option-content-container">
            <el-button size="small" @click="addClick">新增</el-button>
            <el-button size="small" @click="batchMoveClick">批量移动</el-button>
            <el-button size="small" @click="batchDeleteClick">批量删除</el-button>

            <div class="option-button-container">
                <div class="state-button-title">状态:</div>
                <el-radio-group :value="state" size="small" @input="(newState) => $emit('input', newState)">
                    <el-radio-button v-for="option in options" :label="option.key" :key="option.key">
                        {{ option.label }}
                    </el-radio-button>
                </el-radio-group>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'DoTableOptionHeader',
    model: {
        prop: 'state',
    },
    props: {
        title: String,
        addClick: Function,
        batchMoveClick: Function,
        batchDeleteClick: Function,
        options: Array,
        state: String
    },
}

</script>

<style scoped lang="scss">
.table-option-header-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-left: 20px;
    margin-top: 10px;

    .option-title {
        font-size: 24px;
        font-weight: bold;
        margin-bottom: 14px;
    }

    .option-content-container {
        width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 10px;

        .option-button-container {
            flex: 1;
            display: flex;
            align-items: center;
            margin-left: 30px;

            .state-button-title {
                font-size: 14px;
                color: #575757;
                margin-right: 4px;
            }
        }
    }
}
</style>
