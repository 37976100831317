<template>
    <el-button @click="sendEmailCode" :disabled="!show">
        <span v-show="show">发送</span>
        <span v-show="!show" class="count">{{count}}s</span>
    </el-button>
</template>

<script>
  import {showError, showSuccess} from '@/utils/Message'
  import {UserModel} from '@/model/UserModel'

  const TIME_COUNT = 60

  export default {
    name: 'CountdownCode',
    props: {
      email: String
    },
    data () {
      return {
        show: true,
        count: TIME_COUNT,
        timer: null,
      }
    },
    methods: {
      startCountDown () {
        if (!this.timer) {
          this.show = false
          this.count = TIME_COUNT
          this.timer = setInterval(() => {
            if (this.count > 0 && this.count <= TIME_COUNT) {
              this.count--
            } else {
              this.resetCountDown()
            }
          }, 1000)
        }
      },
      resetCountDown () {
        this.show = true
        clearInterval(this.timer)
        this.timer = null
      },
      sendEmailCode () {
        if (this.email === '') {
          showError('邮箱不能为空')
          return
        }
        this.startCountDown()
        UserModel.sendEmailCode({
          'email': this.email,
        }).then((data) => {
          showSuccess('发送成功')
        }).catch((error) => {
          this.resetCountDown()
        })
      }
    }
  }
</script>

<style scoped>

</style>
