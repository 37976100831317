<template>
    <div class="todo-item">
        <input class="todo-checkbox"
               type="checkbox"
               :checked="isFinish"
               v-model="checked"
               @change="changeTodoState"/>
        <div class="todo-item-boxed" @click="showTodoDetail">

            <do-overflow-tooltip
                    :content="this.todoData.content"
                    :class-name="[{active:isFinish}, 'todo-item-content']"/>
        </div>
    </div>
</template>

<script>
  import Checkbox from '@/pages/home/todo/Checkbox'
  import {showSuccess, showTip} from '@/utils/Message'
  import TodoModel from '@/model/TodoModel'
  import DoOverflowTooltip from '@/components/DoOverflowTooltip'

  export default {
    name: 'TodoItem',
    components: {DoOverflowTooltip, Checkbox},
    props: {
      todoData: {
        id: Number,
        content: String,
        state: String,
        createTime: String,
      },
    },
    computed: {
      isFinish () {
        return this.todoData.state === 'FINISH'
      },
    },
    data () {
      return {
        checked: this.todoData.state === 'FINISH'
      }
    },
    methods: {
      showTodoDetail () {
        this.$emit('showTodoDetail', this.todoData)
      },
      changeTodoState () {

        if (this.checked) {
          this.finishTodo()
        } else {
          this.undoTodo()
        }
      },
      undoTodo () {
        TodoModel.undo({
          id: this.todoData.id
        }).then((data) => {
          this.$emit('refreshTodo', this.todoData.id, 'undoTodo')
        })
      },
      finishTodo () {
        TodoModel.finish({id: this.todoData.id})
          .then((data) => {
            this.$emit('refreshTodo', this.todoData.id, 'finishTodo')
          })
      }
    },
  }
</script>

<style scoped lang="scss">

    .todo-item {
        display: flex;
        justify-content: center;
        align-items: center;
        padding-top: 2px;
        padding-bottom: 2px;

        &:hover {
            background-color: #e8e8e8;
        }

        .todo-checkbox {
            vertical-align: center;
            margin-left: 10px;
            margin-right: 10px;
        }

        .todo-item-boxed {
            width: 100%;
            padding-top: 4px;
            padding-bottom: 4px;

            &:hover {
                cursor: pointer;
            }

            .todo-item-content {
                flex: 1;
                max-width: 180px;
                outline: none;
                border: none;
                font-size: 15px;
                text-align: start;

                &.active {
                    color: #CECECE;
                }
            }
        }
    }
</style>
