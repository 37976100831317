import Vue from 'vue'
import App from './App.vue'
import store from '@/store/index'
import Viewer from 'v-viewer'
import 'viewerjs/dist/viewer.css'
import '@/element/element-variables.scss'
import ElementUI from '@/element/element-config'

import VueRouter from 'vue-router'
import router from '@/routers'
Vue.use(VueRouter)

Vue.config.productionTip = false
Vue.use(Viewer)
Vue.use(ElementUI)

// 完整加载
import VueLuckyCanvas from '@lucky-canvas/vue'
Vue.use(VueLuckyCanvas)

new Vue({
  render: h => h(App),
  store,
  router
}).$mount('#app')
