<template>
    <div class="login-box-background">
        <div class="login-box">
            <form class="login-form" :class="{active:leftStyle}">
                <div class="login-bar">
                    <span>{{ boxTitle }}</span>
                    <img @click="closeLoginBox" src="../../assets/icons/close.png" alt="">
                </div>

                <div class="input" v-show="isRegister">
                    <img src="../../assets/icons/name.png" alt="">
                    <input type="text" v-model="nickname" placeholder="请输入昵称">
                </div>

                <div class="input" v-show="!isWechatLogin">
                    <img src="../../assets/icons/email.png" alt="">
                    <input type="text" v-model="email" placeholder="请输入邮箱" @keyup.enter="clickActionButton"/>
                </div>

                <div class="input" v-show="isResetPassword || !isLogin">
                    <img src="../../assets/icons/email_code.png" alt="">
                    <input type="text" v-model="emailCode" placeholder="请输入验证码" @keyup.enter="clickActionButton"/>
                    <countdown-code :email="email" class="email-code"/>
                </div>

                <div class="input" v-show="!isWechatLogin">
                    <img src="../../assets/icons/password.png" alt="">
                    <input type="password" v-model="password" placeholder="请输入密码" @keyup.enter="clickActionButton">
                </div>

                <div class="input" v-show="!isLogin">
                    <img src="../../assets/icons/password.png" alt="">
                    <input type="password" v-model="confirmPassword" placeholder="请输入确认密码"
                           @keyup.enter="clickActionButton">
                </div>

                <div v-show="isWechatLogin"
                     class="code-container"
                >
                    <div class="code-tips">请使用微信扫一扫登录</div>
                    <img class="code" :src="codeImg" alt=""/>

                    <div class="code-scan-success-container" v-show="isScanWechatSuccess">
                        <div class="scan-success-title-container">
                            <img src="../../assets/icons/wechat_right.png" alt=""/>
                            <div class="scan-success-title">扫码成功</div>
                        </div>
                        <div class="scan-tips">请在手机微信上确认登录</div>
                    </div>

                    <div class="code-expired-container"
                         v-show="isWechatCodeExpired"
                         @click="refreshWechatCode"
                    >
                        <div class="code-expired-title">二维码已失效</div>
                        <div class="code-expired-tips">点击刷新</div>
                    </div>
                </div>

                <div class="btn-action"
                     @click="clickActionButton"
                     v-show="!isWechatLogin"
                >
                    {{ buttonText }}
                </div>

                <div class="or-text" v-show="!isWechatLogin">OR</div>
                <div class="other-login-container">
                    <div class="wechat-button-container"
                         @click="toWechatPage"
                         v-show="!isWechatLogin"
                    >
                        <img class="wechat-icon" src="../../assets/icons/wechat.png" alt=""/>
                        <div class="wechat-button">微信登录</div>
                    </div>

                    <div class="qq-button-container"
                         id="qqLoginBtn"
                         @click="toQQPage"
                         v-show="!isQQLogin"
                    >
                        <img class="qq-icon" src="../../assets/icons/qq.png" alt=""/>
                        <div class="qq-button">QQ登录</div>
                    </div>
                </div>
            </form>

            <div class="second-sheets" :class="{active:leftStyle}">
                <span class="title">{{ secondTitle }}</span>
                <span class="friends">{{ secondSubTitle }}</span>
                <div class="action-area">
                    <span v-show="!isEmailLogin" class="action-content" @click="showLogin">邮箱登录</span>
                    <span v-show="!isRegister" class="action-content" @click="showRegister">加入我们吧！</span>
                    <span v-show="!isResetPassword" class="action-content" @click="showResetPassword">忘记密码?</span>
                </div>
            </div>
        </div>
    </div>
</template>

<script>

import {showError, showSuccess} from '@/utils/Message'
import {UserModel} from '@/model/UserModel'
import CountdownCode from '@/components/CountdownCode'

export default {
    name: 'LoginBox',
    components: {CountdownCode},
    inject: ['reload'],
    data () {
        return {
            boxTitle: '账号登录',
            secondTitle: '欢迎',
            secondSubTitle: '回来',
            buttonText: '登录',
            email: '',
            password: '',
            emailCode: '',
            confirmPassword: '',
            nickname: '',
            type: 'EmailLogin',
            leftStyle: true,
            codeImg: '',
            codeStatus: 'INIT',
            pollingTask: null
        }
    },
    computed: {
        isRegister () {
            return this.type === 'Register'
        },
        isLogin () {
            return this.isEmailLogin || this.isWechatLogin
        },
        isEmailLogin () {
            return this.type === 'EmailLogin'
        },
        isResetPassword () {
            return this.type === 'ResetPassword'
        },
        isWechatLogin () {
            return this.type === 'Wechat'
        },
        isQQLogin () {
            return this.type === 'QQ'
        },
        isScanWechatSuccess () {
            return this.codeStatus === 'SCAN'
        },
        isWechatCodeExpired () {
            return this.codeStatus === 'EXPIRED'
        }
    },
    methods: {
        clickActionButton () {
            if (this.isLogin) {
                this.login()
                return
            }

            if (this.isRegister) {
                this.register()
                return
            }

            this.resetPassword()
        },
        register () {
            if (this.nickname === '') {
                showError('昵称不能为空')
                return
            }

            if (this.email === '') {
                showError('邮箱不能为空')
                return
            }

            if (this.emailCode === '') {
                showError('验证码不能为空')
                return
            }

            if (this.password === '') {
                showError('密码不能为空')
                return
            }

            if (this.confirmPassword === '') {
                showError('确认密码不能为空')
                return
            }

            if (this.password !== this.confirmPassword) {
                showError('两次密码输入不一致, 请重新输入')
                return
            }

            UserModel.register({
                name: this.nickname,
                email: this.email,
                password: this.password,
                code: this.emailCode
            }).then((data) => {
                showSuccess('注册成功')
                this.clearInputContent()
                this.loginSuccess()
                this.$store.commit('loginSuccess', data)
            })
        },
        login () {
            if (this.email === '') {
                showError('邮箱不能为空')
                return
            }

            if (this.password === '') {
                showError('密码不能为空')
                return
            }

            UserModel.login({
                'email': this.email,
                'password': this.password
            }).then((data) => {
                showSuccess('登录成功')
                this.clearInputContent()
                this.loginSuccess()
                this.$store.commit('loginSuccess', data)
            })
        },
        resetPassword () {
            if (this.email === '') {
                showError('邮箱不能为空')
                return
            }

            if (this.emailCode === '') {
                showError('邮箱验证码不能为空')
                return
            }

            if (this.password === '') {
                showError('密码不能为空')
                return
            }

            if (this.confirmPassword === '') {
                showError('确认密码不能为空')
                return
            }

            if (this.password !== this.confirmPassword) {
                showError('两次密码输入不一致, 请重新输入')
                return
            }

            UserModel.resetPassword({
                'email': this.email,
                'emailCode': this.emailCode,
                'password': this.password
            }).then((data) => {
                showSuccess('重置成功')
                this.clearInputContent()
                this.loginSuccess()
                this.$store.commit('loginSuccess', data)
            })
        },
        clearInputContent () {
            this.nickname = ''
            this.email = ''
            this.emailCode = ''
            this.password = ''
            this.confirmPassword = ''
            this.type = 'EmailLogin'
            this.leftStyle = true
        },
        closeLoginBox () {
            this.clearInputContent()
            clearTimeout(this.pollingTask)
            this.$store.commit('closeLoginBox')
        },
        showResetPassword () {
            this.changeStyle()
            this.type = 'ResetPassword'
            this.boxTitle = '重置密码'
            this.secondTitle = '不是吧'
            this.secondSubTitle = '朋友'
            this.buttonText = '重置'
        },
        showRegister () {
            this.changeStyle()
            this.type = 'Register'
            this.boxTitle = '注册账号'
            this.secondTitle = '欢迎'
            this.secondSubTitle = '加入'
            this.buttonText = '注册'
        },
        showLogin () {
            this.changeStyle()
            this.type = 'EmailLogin'
            this.boxTitle = '账号登录'
            this.secondTitle = '欢迎'
            this.secondSubTitle = '回来'
            this.buttonText = '登录'
        },
        changeStyle () {
            this.leftStyle = !this.leftStyle
        },
        loginSuccess () {
            this.reload()
        },
        toWechatPage () {
            this.changeStyle()
            this.type = 'Wechat'
            this.boxTitle = '微信扫码登录'

            this.refreshWechatCode()
        },
        toQQPage () {
            window.location.href = UserModel.qqAuth()
        },
        checkCodeStatus (uuid) {
            if (uuid) {
                this.getCodeInfo(uuid)
            }
        },
        getCodeInfo (uuid) {
            UserModel.getCodeInfo({
                Uuid: uuid
            }).then((data) => {
                this.codeStatus = data.status
                if (data.status === 'LOGIN') {
                    this.wechatCodeLoginSuccess(data)
                    return
                }

                if (data.status === 'EXPIRED') {
                    this.wechatCodeExpired()
                    return
                }

                // 开启轮训, 请求后台 二维码状态
                this.pollingTask = setTimeout(() => {
                    clearTimeout(this.pollingTask)
                    this.getCodeInfo(uuid)
                }, 1000)
            })
        },
        wechatCodeLoginSuccess (data) {
            clearTimeout(this.pollingTask)

            showSuccess('登录成功')
            this.clearInputContent()
            this.loginSuccess()
            data.name = ''
            this.$store.commit('loginSuccess', data)
        },
        wechatCodeExpired () {
            clearTimeout(this.pollingTask)
        },
        refreshWechatCode () {
            UserModel.getWechatCode({
                responseType: 'blob',
            }).then((res) => {
                let blob = new Blob([res.data], {type: 'image/jpeg'})
                this.codeImg = window.URL.createObjectURL(blob)
                this.checkCodeStatus(res.headers['uuid'])
            })
        }
    },
    mounted () {
        let cid = this.$route.query.cid
        if (cid) {
            UserModel.qqCheck({
                cid
            }).then((data) => {
                this.$router.push('/')
                showSuccess('登录成功')
                this.clearInputContent()
                this.loginSuccess()
                this.$store.commit('loginSuccess', data)
            })
        }
    },
    destroyed () {
        clearTimeout(this.pollingTask)
    }
}
</script>

<style scoped lang="scss">
@import "src/assets/Colors";

.login-box-background {
    display: flex;
    align-items: center;
    justify-content: center;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, .3);
    z-index: 500;
    position: fixed;

    .login-box {
        width: 600px;
        display: flex;
        position: relative;

        .login-form {
            position: relative;
            display: flex;
            flex-direction: column;
            padding: 40px;
            width: 420px;
            border-top-left-radius: 20px;
            border-bottom-left-radius: 20px;
            background-color: #fff;
            box-sizing: border-box;
            transition: all 1s;

            &.active {
                border-radius: 0 20px 20px 0;
                transform: translateX(43%);
            }

            .login-bar {
                margin-bottom: 20px;
                font-weight: bold;
                font-size: 24px;
                display: flex;
                align-items: center;
                justify-content: space-between;

                img {
                    width: 20px;
                    height: 20px;
                    padding: 10px 0 10px 10px;

                    &:hover {
                        cursor: pointer;
                    }
                }
            }

            .input {
                padding-top: 10px;
                display: flex;
                border-radius: 2px;
                justify-content: center;
                align-items: center;
                position: relative;

                img {
                    width: 20px;
                    position: absolute;
                    left: 0;
                    padding: 10px;
                }

                input {
                    padding: 14px 14px 14px 40px;
                    flex: 1;
                    outline: none;
                    border: 1px solid #CECECE;

                    &:focus {
                        border: 2px solid $primary;
                        border-radius: 2px;
                    }
                }
            }

            .email-code {
                margin-left: 10px;
            }

            .code-container {
                width: 100%;
                height: 260px;
                display: flex;
                flex-direction: column;
                align-items: center;
                justify-content: center;
                position: relative;

                .code-tips {
                    color: #9f9f9f;
                    margin-bottom: 10px;
                }

                .code {
                    width: 200px;
                    height: 200px;
                }

                .code-scan-success-container {
                    width: 200px;
                    height: 200px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    margin-top: 30px;
                    background-color: #eeeeee;

                    .scan-success-title-container {
                        display: flex;
                        align-items: center;

                        img {
                            width: 30px;
                            height: 30px;
                            margin-right: 10px;
                        }

                        .scan-success-title {
                            font-size: 30px;
                            font-weight: bold;
                        }
                    }

                    .scan-tips {
                        margin-top: 10px;
                    }
                }

                .code-expired-container {
                    width: 200px;
                    height: 200px;
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    position: absolute;
                    margin-top: 30px;
                    background-color: #eeeeee;
                    cursor: pointer;

                    .code-expired-title {
                        font-weight: bold;
                    }
                }
            }

            .btn-action {
                margin-top: 20px;
                background-color: $primary;
                border-radius: 20px;
                padding: 10px;
                font-weight: bold;
                color: white;
                cursor: pointer;
            }

            .or-text {
                margin-top: 10px;
                margin-bottom: 10px;
            }

            .other-login-container {
                display: flex;
                justify-content: space-around;

                .wechat-button-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 10px 20px;
                    border-radius: 20px;
                    color: white;
                    font-weight: bold;
                    background-color: #5AE386;
                    cursor: pointer;

                    .wechat-icon {
                        width: 24px;
                        height: 24px;
                        margin-right: 10px;
                    }
                }

                .qq-button-container {
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 10px 20px;
                    border-radius: 20px;
                    color: white;
                    font-weight: bold;
                    background-color: #1296db;
                    cursor: pointer;

                    .qq-icon {
                        width: 20px;
                        height: 20px;
                        margin-right: 10px;
                    }
                }
            }

        }

        .second-sheets {
            display: flex;
            flex-direction: column;
            justify-content: center;
            align-items: center;
            flex: 1;
            background-color: $primary;
            color: white;
            border-top-right-radius: 20px;
            border-bottom-right-radius: 20px;
            transition: all 1s;

            &.active {
                border-radius: 20px 0 0 20px;
                transform: translateX(-233%);
            }

            .title {
                font-weight: bold;
                font-size: 40px;
                margin-top: 40px;
            }

            .friends {
                font-size: 24px;
                font-weight: bold;
            }

            .action-area {
                display: flex;
                flex-direction: column;
                align-items: center;
                font-size: 14px;
                margin-top: 20px;
                cursor: pointer;

                img {
                    width: 32px;
                    height: 32px;
                    margin-top: 10px;

                    &:hover {
                        cursor: pointer;
                    }
                }

                .action-content {
                    margin-top: 14px;
                    padding: 4px 10px;

                    &:hover {
                        color: #2c3e50;
                        font-weight: bold;
                        background-color: white;
                        border-radius: 6px;
                    }
                }
            }
        }
    }
}
</style>
