<template>
    <div class="personal">

        <div class="personal-summary">
            <div class="personal-sub-info">
                <div class="personal-avatar" title="点击更换头像">
                    <el-avatar :size="size" :src="circleUrl" v-loading="loadingAvatar"/>
                    <label for="input"/>
                    <input id="input" type="file" @change="changeAvatar" hidden :disabled="loadingAvatar"/>
                </div>
                <div class="personal-info">
                    <div class="personal-name">{{this.$store.state.login.username}}</div>
                    <div class="personal-introduction">
                        {{introduction}}
                    </div>
                </div>

                <div class="personal-header-right-box">
                    <el-tooltip class="item" effect="dark" content="实际打卡天数, 例如 1天 多次发布, 只算打卡 1天" placement="bottom">
                        <personal-data-item name="打卡天数" :quantity="cardDayQuantity"/>
                    </el-tooltip>
                    <el-tooltip class="item" effect="dark" content="打卡总次数, 例如 1天 发布 N 次, 算打卡 N次" placement="bottom">
                        <personal-data-item name="累计打卡次数" :quantity="cardQuantity"/>
                    </el-tooltip>
                    <personal-data-item name="累计签到天数" :quantity="signInQuantity"/>
                    <personal-data-item name="当前金币" :quantity="coinQuantity"/>
                </div>
            </div>
        </div>

        <div class="personal-content">
            <div class="personal-navigation">
                <part-navigation :navigations="navigations" @selectItem="selectItem"/>
            </div>

            <div class="personal-content-detail">
                <router-view/>
            </div>

            <div class="right-box" v-if="false">
                <div class="personal-honour">
                    <div class="personal-data">第23位会员</div>
                    <div class="personal-data">2021-07-21 00:00:00</div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
  import PartNavigation from '@/components/PartNavigation'
  import PersonalDataItem from '@/pages/personal/card/PersonalDataItem'
  import {showSuccess} from '@/utils/Message'
  import {UserInfoModel} from '@/model/UserInfoModel'

  export default {
    name: 'Personal',
    components: {PersonalDataItem, PartNavigation},
    data () {
      return {
        navigations: ['个人设置', '打卡管理'],
        selectIndex: 0,
        circleUrl: '',
        size: 120,
        loadingAvatar: false,
        cardQuantity: 0,
        signInQuantity: 0,
        coinQuantity: 0,
        cardDayQuantity: 0
      }
    },
    computed: {
      introduction () {
        const introduction = this.$store.state.login.introduction
        return (introduction === '' || introduction === null) ? '这个人很懒' : introduction
      }
    },
    created () {
      this.getPersonalInfo()
    },
    methods: {
      getPersonalInfo () {
        UserInfoModel.getUserInfo()
          .then((data) => {
            this.circleUrl = data.avatarUrl
            this.cardQuantity = data.cardQuantity
            this.signInQuantity = data.signInQuantity
            this.coinQuantity = data.coinQuantity
            this.cardDayQuantity = data.cardDayQuantity
            this.$store.commit('updateUserInfo', data)
          })
      },
      selectItem (index) {
        if (index === 0) {
          this.goToPersonalSetting()
        } else if (index === 1) {
          this.$router.push('/personal/cardManager')
        }
      },
      goToPersonalSetting () {
        this.$router.push('/personal/personalSetting')
      },
      changeAvatar (e) {

        if (e.target.files.length <= 0) {
          return
        }

        this.loadingAvatar = true
        const file = e.target.files[0]

        UserInfoModel.uploadAvatar({file})
          .then((data) => {
            this.circleUrl = data.avatarUrl
            this.loadingAvatar = false
            showSuccess('更换成功')
          })
      }
    }
  }
</script>

<style scoped lang="scss">
    @import "src/assets/Sizes";
    @import "src/assets/Colors";
    @import "src/assets/Styles";

    .personal {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        position: relative;
        margin-top: $global-content-margin-top;

        .personal-summary {
            width: 100%;
            height: 200px;
            display: flex;
            justify-content: center;
            text-align: start;
            box-shadow: 2px 2px 2px #CECECE;
            background-color: $primary;

            .personal-sub-info {
                width: 1260px;
                height: 100%;
                display: flex;
                align-items: center;
                margin-left: 60px;
                color: white;

                .personal-avatar {
                    width: 120px;
                    height: 120px;
                    margin-right: 20px;
                    position: relative;
                    border-radius: 50%;
                    background-color: black;

                    &:hover:after {
                        content: attr(title);
                        position: absolute;
                        left: 10px;
                        top: 50px;
                        color: white;
                    }

                    &:hover {
                        .el-avatar {
                            opacity: 0.5;
                        }
                    }

                    label {
                        position: absolute;
                        top: 0;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        z-index: 10;

                        &:hover {
                            cursor: pointer;
                        }
                    }
                }

                .personal-info {
                    height: 120px;
                    display: flex;
                    flex-direction: column;
                    justify-content: space-between;
                    position: relative;

                    .personal-name {
                        font-size: 30px;
                        font-weight: bold;
                    }

                    .personal-introduction {
                        width: 500px;
                        display: -webkit-box;
                        overflow: hidden;
                        text-overflow: ellipsis;
                        -webkit-line-clamp: 3; /* 可以显示的行数，超出部分用...表示*/
                        -webkit-box-orient: vertical;
                        margin-right: 10px;
                    }
                }

                .personal-header-right-box {
                    flex: 1;
                    display: flex;
                    justify-content: center;
                    align-items: center;

                    .personal-data-item {
                        display: flex;
                        flex-direction: column;
                        justify-content: center;
                        align-items: center;
                        margin-right: 20px;

                        .personal-data-number {
                            font-size: 22px;
                            font-weight: bold;
                        }
                    }
                }
            }
        }

        .personal-content {
            display: flex;
            position: relative;
            justify-content: center;
            margin-top: 10px;

            .personal-navigation {
                width: 200px;
                height: auto;
                display: flex;
                position: absolute;
                flex-direction: column;
                left: -220px;
                padding-top: 20px;
                padding-bottom: 20px;
                background-color: white;
                box-shadow: 2px 2px 2px #CECECE;
                border-radius: 4px;

                .home-navigation-item {
                    vertical-align: center;
                    padding-top: 10px;
                    padding-bottom: 10px;
                    cursor: pointer;

                    &:hover {
                        background-color: #f6f6f6;
                    }

                    &.select {
                        color: white;
                        background-color: $primary;
                    }
                }
            }

            .personal-content-detail {
                width: $card-width;
                background-color: white;
            }

            .right-box {
                width: 260px;
                right: -280px;
                display: flex;
                flex-direction: column;
                position: absolute;
                margin-left: 30px;

                .personal-honour {
                    @include home-box-shadow;
                    height: auto;
                    text-align: start;
                    font-weight: bold;
                    padding: 20px;
                    background-color: white;

                    .personal-data {
                        margin-top: 10px;
                    }
                }
            }
        }

    }
</style>
