<template>
    <el-pagination
        background
        @current-change="changeData"
        :current-page.sync="page"
        :page-size="size"
        layout="total, prev, pager, next"
        :total="total"
    >
    </el-pagination>
</template>

<script>
export default {
    name: 'DoPagination',
    props: {
        requestMethod: Function,
    },
    data () {
        return {
            total: 0,
            page: 1,
            size: 20,
        }
    },
    methods: {
        changeData(page) {
            this.requestMethod()({
                page: page - 1,
                size: this.size,
            }).then((data) => {
                this.total = data.total
                this.$emit('updateData', data.list)
            })
        }
    }
}
</script>

<style scoped>

</style>
