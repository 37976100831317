<template>
    <el-tooltip
            :disabled="disabled"
            effect="dark"
            :content="tooltipContent || content"
            placement="bottom-start"
    >

        <div ref="overflowTooltipContent"
             :class="className"
             class="overflow-content"
             @mouseover="isOverflow"
        >
            {{this.content}}
        </div>
    </el-tooltip>
</template>

<script>
  export default {
    name: 'DoOverflowTooltip',
    props: {
      className: {
        type: Array,
      },
      content: {
        type: String,
        default: ''
      },
      tooltipContent: {
        type: String,
        default: ''
      }
    },
    data () {
      return {
        disabled: true
      }
    },
    methods: {
      isOverflow () {
        if (this.$refs.overflowTooltipContent) {
          this.disabled = this.$refs.overflowTooltipContent.offsetWidth >= this.$refs.overflowTooltipContent.scrollWidth
        }
      },
    }
  }
</script>

<style scoped lang="scss">
    .overflow-content {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }
</style>
