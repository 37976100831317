<template>
    <div class="personal-data-item">
        <div class="personal-data-number">{{this.quantity}}</div>
        <div class="personal-data-sub-title">{{this.name}}</div>
    </div>
</template>

<script>
  export default {
    name: 'PersonalDataItem',
    props: {
      quantity: Number,
      name: String
    }
  }
</script>

<style scoped lang="scss">

    .personal-data-item {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin-right: 20px;
        cursor: pointer;

        .personal-data-number {
            font-size: 26px;
            font-weight: bold;
        }

        .personal-data-sub-title {
            color: #999aaa;
        }
    }
</style>
