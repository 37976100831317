<template>
    <div class="bb-coin-record-container">
        <div class="bb-coin-record-content">

            <el-table
                ref="multipleTable"
                :data="tableData"
                stripe
                height="800"
                style="width: 100%">

                <el-table-column
                    align="center"
                    label="来源"
                    prop="reasonDesc">
                </el-table-column>

                <el-table-column
                    align="center"
                    label="数量"
                    prop="quantity">
                </el-table-column>

                <el-table-column
                    align="center"
                    property="createTime"
                    label="日期"
                    width="200">
                    <template slot-scope="scope">
                        {{ formatCreateTime(scope.row.createTime) }}
                    </template>
                </el-table-column>

            </el-table>

            <do-pagination
                ref="doPagination"
                :request-method=requestMethod
                @updateData="updateData"
            />
        </div>
    </div>
</template>

<script>
import {formatDate} from '@/utils/Date'
import CoinRecordModel from '@/pages/bulletin-board/coin/pages/CoinRecordModel'
import DoPagination from '@/components/DoPagination'

export default {
    name: 'BbCoinRecord',
    components: {DoPagination},
    data () {
        return {
            tableData: [],
        }
    },
    mounted () {
        this.$refs.doPagination.changeData(1)
    },
    methods: {
        formatCreateTime (createTime) {
            return formatDate(createTime)
        },
        requestMethod() {
          return CoinRecordModel.get
        },
        updateData (data) {
            this.tableData = data
        }
    }
}
</script>

<style scoped lang="scss">
.bb-coin-record-container {
    flex: 1;

    .bb-coin-record-content {
        display: flex;
        flex-direction: column;
        height: 820px;

        .el-pagination {
            margin-top: 10px;
            margin-bottom: 10px;
        }
    }
}
</style>
