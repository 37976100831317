<template>
    <el-collapse-transition>
        <div class="card-input-area" v-show="this.$store.state.login.isLogin">
            <card-input ref="inputContent"/>
            <card-navigation @triggerInput="getInputContent" @publishSuccess="publishSuccess"/>
        </div>
    </el-collapse-transition>
</template>

<script>
  import CardInput from './CardInput'
  import CardNavigation from './CardNavigation'
  import Card from '../Card'

  export default {
    name: 'CardInputArea',
    components: {Card, CardNavigation, CardInput},
    data () {
      return {
        inputContent: '',
        fileIds: [],
        totalSize: 0,
      }
    },
    methods: {
      getInputContent () {
        const data = this.$refs.inputContent.getInputContent()
        this.inputContent = data.content
        this.fileIds = data.fileIds
        this.totalSize = data.totalSize
      },
      publishSuccess (newCard) {
        this.$refs.inputContent.clearInput()
        this.$emit('refreshCardContent', newCard)
      }
    }
  }
</script>

<style scoped lang="scss">
    @import "src/assets/Sizes";

    .card-input-area {
        display: flex;
        flex-direction: column;
        align-items: center;
        border-radius: 2px;
        background-color: white;
        padding: 20px;
        margin-top: $global-content-margin-top;
        box-shadow: 2px 2px 2px #CECECE;
    }
</style>
