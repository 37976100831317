import Home from '@/pages/home/Home'
import Personal from '@/pages/personal/Personal'
import VueRouter from 'vue-router'
import CardManager from '@/pages/personal/card/CardManager'
import PersonalSetting from '@/pages/personal/setting/PersonalSetting'
import Blog from '@/pages/blog/Blog'
import ApplyBlog from '@/pages/blog/ApplyBlog'
import BlogList from '@/pages/blog/BlogList'
import ShareBlog from '@/pages/blog/ShareBlog'
import Rank from '@/pages/rank/Rank'
import Notice from '@/pages/notice/Notice'
import NoticeDetail from '@/pages/notice/NoticeDetail'
import BulletinBoard from '@/pages/bulletin-board/BulletinBoard'
import BbSurvey from '@/pages/bulletin-board/survey/BbSurvey'
import BbTodo from '@/pages/bulletin-board/todo/BbTodo'
import BbWorkbench from '@/pages/bulletin-board/workbench/BbWorkbench'
import BbProgress from '@/pages/bulletin-board/progress/BbProgress'
import BbDayMatter from '@/pages/bulletin-board/day-matter/BbDayMatter'
import BbLuckDraw from '@/pages/bulletin-board/luck-draw/page/BbLuckDraw'
import BbGiftManagement from '@/pages/bulletin-board/luck-draw/page/BbGiftManagement'
import BbWinningRecord from '@/pages/bulletin-board/luck-draw/page/BbWinningRecord'
import BbLuckDrawManagement from '@/pages/bulletin-board/luck-draw/BbLuckDrawManagement'
import BbCoin from '@/pages/bulletin-board/coin/BbCoin'
import BbCoinRecord from '@/pages/bulletin-board/coin/pages/BbCoinRecord'

const routes = [
  {
    path: '/',
    redirect: '/bulletin-board/bb-survey'
  },
  {
    path: '/bulletin-board',
    component: BulletinBoard,
    redirect: '/bulletin-board/bb-survey',
    children: [
      {
        path: 'bb-survey',
        component: BbSurvey
      },
      {
        path: 'bb-workbench',
        component: BbWorkbench
      },
      {
        path: 'bb-todo',
        component: BbTodo
      },
      {
        path: 'bb-day-matter',
        component: BbDayMatter
      },
      {
        path: 'bb-progress',
        component: BbProgress
      },
      {
        path: 'bb-luck-draw-management',
        component: BbLuckDrawManagement,
        redirect: '/bulletin-board/bb-luck-draw-management/bb-luck-draw',
        children: [
          {
            path: 'bb-luck-draw',
            component: BbLuckDraw
          },
          {
            path: 'bb-gift-management',
            component: BbGiftManagement
          },
          {
            path: 'bb-winning-record',
            component: BbWinningRecord
          },
        ]
      },
      {
        path: 'bb-coin',
        component: BbCoin,
        redirect: '/bulletin-board/bb-coin/bb-coin-record',
        children: [
          {
            path: 'bb-coin-record',
            component: BbCoinRecord
          }
        ]
      },
    ],
  },
  {
    path: '/card',
    component: Home
  },
  {
    path: '/rank',
    component: Rank
  },
  {
    path: '/personal',
    component: Personal,
    children: [
      {
        path: 'personalSetting',
        name: 'PersonalSetting',
        component: PersonalSetting
      },
      {
        path: 'cardManager',
        name: 'CardManager',
        component: CardManager
      },
    ]
  },
  {
    path: '/blog',
    component: Blog,
    children: [
      {
        path: '/blog-list',
        component: BlogList
      },
      {
        path: '/apply',
        component: ApplyBlog
      },
      {
        path: '/share',
        component: ShareBlog
      }
    ]
  },
  {
    path: '/notice',
    component: Notice,
  },
  {
    path: '/notice-detail',
    component: NoticeDetail
  },

]

const router = new VueRouter({
  routes,
})

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}

router.beforeEach((to, from, next) => {
  if (window._hmt) {
    if (to.path) {
      window._hmt.push(['_trackPageview', '/#' + to.fullPath])
    }
  }
  next()
})


export default router
