<template>
    <div class="card-manager">
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane
                    v-loading="loading"
                    v-for="(header, index) in navigationHeaders" :key="index"
                    :label="header.label"
                    :name="header.name"
                    :disabled="loading"
            >
                <card-content
                        :right-label="rightLabel"
                        :card-contents="cardContents"
                        :total="total"
                        @rightLabelClick="deleteCardContent"
                />

                <el-pagination
                        background
                        @current-change="getCardContents"
                        :current-page.sync="page"
                        :page-size="size"
                        layout="total, prev, pager, next"
                        :total="total"
                        v-show="!showEmptyContent"
                >
                </el-pagination>

                <div class="card-content-empty" v-show="showEmptyContent">
                    你还没发布 [{{tabName}}] 打卡
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
  import CardContentItem from '@/pages/home/card/content/CardContentItem'
  import CardModel from '@/model/CardModel'
  import CardContent from '@/pages/home/card/content/CardContent'
  import {deleteByKey, getByKey} from '@/utils/Array'
  import {showSuccess} from '@/utils/Message'
  import {showCloseDialog} from '@/utils/Dialog'
  import TodoModel from '@/model/TodoModel'

  export default {
    name: 'CardManager',
    components: {CardContent, CardContentItem},
    data () {
      return {
        activeName: 'ALL',
        cardContents: [],
        page: 0,
        size: 5,
        total: 0,
        loading: true,
        rightLabel: '删除',
        navigationHeaders: [
          {
            label: '全部',
            name: 'ALL',
          },
          {
            label: '学习',
            name: 'STUDY',
          },
          {
            label: '运动',
            name: 'SPORT',
          },
          {
            label: '上班摸鱼',
            name: 'LAZY'
          },
          {
            label: '今天学到了',
            name: 'LEARNED_TODAY',
          },
          {
            label: '建议',
            name: 'ADVICE',
          },
          {
            label: 'BUG',
            name: 'BUG',
          },
        ]
      }
    },
    created () {
      this.loading = true
      this.page = 0
      this.getCardContents(this.page)
    },
    computed: {
      tabName () {
        const header = getByKey(this.navigationHeaders, (header) => {
          return header.name === this.activeName
        })
        return header.label
      },
      showEmptyContent () {
        return this.cardContents.length === 0 && !this.loading
      },
    },
    methods: {
      handleClick (tab, event) {
        this.page = 0
        this.loading = true
        this.getCardContents(this.page)
      },
      getCardContents (page) {
        this.page = page
        this.loading = true
        CardModel.getContentByUser({
          page: page - 1,
          size: this.size,
          cardType: this.getCardType()
        }).then(data => {
          this.total = data.total
          this.cardContents = data.list
          this.loading = false
        }).catch((error) => {
          this.loading = false
        })
      },
      deleteCardContent (id) {
        showCloseDialog('确定要删除吗？')
          .then(() => {
            CardModel.delete({
              id
            }).then(data => {
              deleteByKey(this.cardContents, (card) => {
                return card.id === id
              })
              showSuccess('删除成功')
            })
          })
      },
      getCardType () {
        return this.activeName === 'ALL' ? '' : this.activeName
      }
    }
  }
</script>

<style lang="scss">
    @import "src/assets/Styles";

    .card-manager {

        .el-tabs__header {
            @include custom-tab
        }

        .el-tabs__content {
            min-height: 200px;

            .card-content-item {
                margin-top: 20px;
            }

            .el-pagination {
                padding-top: 30px;
                padding-bottom: 50px;
                background-color: #f4f5f5;
            }

            .card-content-empty {
                padding: 160px 0;
                text-align: center;
                font-size: 16px;
                color: #93999f;
                line-height: 24px;
            }
        }
    }


</style>
