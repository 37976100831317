<template>
    <div class="notice-item-container">
        <el-badge class="notice-dot" :is-dot="showUnreadDot"/>
        <img class="notice-icon" src="../../assets/logo.png" alt="">
        <div class="notice-content">
            <div class="notice-title">
                <div class="notice-name">{{noticeData.sourceUsername}}</div>
                <div class="notice-tips">{{noticeTip}}{{noticeResource}}</div>
                <div class="notice-target" @click="toResourceDetail">{{noticeData.resourceContent}}</div>
            </div>

            <div>
                {{noticeData.content}}
            </div>
            <div class="notice-time">
                {{noticeTime}}
            </div>
        </div>
    </div>
</template>

<script>
  import {beautifulTime} from '@/utils/Date'

  export default {
    name: 'NoticeItem',
    props: {
      noticeData: Object
    },
    data () {
      return {}
    },
    computed: {
      noticeTime () {
        return beautifulTime(this.noticeData.createTime)
      },
      noticeTip () {
        switch (this.noticeData.noticeType) {
          case 'PRAISE':
            return '点赞了你的'
          case 'COMMENT':
            return '评论了你的'
          default:
            return ''
        }
      },
      noticeResource () {
        switch (this.noticeData.resourceType) {
          case 'CARD':
            return '打卡'
          case 'COMMENT':
            return '评论'
          default:
            return ''
        }
      },
      showUnreadDot () {
        return this.noticeData.noticeState === 'UNREAD'
      }
    },
    methods: {
      toResourceDetail () {

        this.$store.commit('refreshUnreadNotice')

        this.$router.push({
          path: '/notice-detail',
          query: {
            noticeId: this.noticeData.id,
            cardId: this.noticeData.resourceId,
          }
        })
      }
    }
  }
</script>

<style scoped lang="scss">
    @import "../../assets/Sizes";
    @import "../../assets/Colors";

    .notice-item-container {
        display: flex;
        padding: 10px 10px 10px 4px;
        margin-left: 10px;
        margin-right: 10px;
        margin-bottom: 10px;
        background-color: white;
        border-radius: 10px;
        border: 2px solid #f3f4f6;

        &:hover {
            background-color: #f5f5f5;
        }

        .notice-dot {
            position: absolute;
            padding-left: -6px;
        }

        .notice-icon {
            width: 30px;
            height: 30px;
            margin-left: 8px;
            margin-right: 10px;
        }

        .notice-content {
            display: flex;
            flex-direction: column;
            text-align: start;

            .notice-title {
                display: flex;
                margin-bottom: 6px;
            }

            .notice-name {
                font-weight: bold;
                color: $primary;
            }

            .notice-tips {
                margin-left: 4px;
                margin-right: 4px;
                color: #999aaa;
            }

            .notice-target {
                font-weight: bold;

                &:hover {
                    cursor: pointer;
                }
            }

            .notice-time {
                margin-top: 4px;
                font-size: 14px;
                color: #bbbbbb;
            }
        }
    }

</style>
