<template>
    <div class="header-bar" @click.stop>
        <div class="header-content">
            <div class="title" @click="toBulletinBoardPage">
                <img class="logo" src="../../../assets/logo.png" alt="">
                <span>Let's Do it</span>
            </div>

            <div class="header-navigation">
                <div class="header-btn" @click="toBulletinBoardPage">首页</div>
                <div class="header-btn"
                     @mouseenter="showAppletCode = true"
                     @mouseleave="showAppletCode = false"
                >小程序</div>
                <div class="applet-code" v-show="showAppletCode"/>
                <div class="header-btn" @click="toCardPage">广场</div>
                <div class="header-btn" @click="toBlogPage">博客</div>

                <el-badge class="notice-area"
                        :value="noticeCount"
                          :max="99"
                          :hidden="hideNoticeCount"
                          @click.native="toNoticePage"
                >
                    <i class="el-icon-message-solid"/>
                </el-badge>
                <div class="login header-btn" @click="showLoginBox">
                    <img alt="" src="@/assets/icons/login.png">
                    <span>{{this.$store.state.login.username}}</span>
                </div>
                <user-navigation v-show="this.$store.state.login.userNavigationState"/>
            </div>
        </div>
    </div>
</template>

<script>
  import UserNavigation from '@/pages/home/header/UserNavigation'
  import {showSuccess} from '@/utils/Message'
  import NoticeModel from '@/model/NoticeModel'

  export default {
    name: 'HeaderBar',
    components: {UserNavigation},
    data () {
      return {
          showAppletCode: false,
      }
    },
    mounted () {

      // 点击外部的时候, 隐藏 userNavigation
      document.body.addEventListener('click', () => {
        if (this.$store.state.login.userNavigationState) {
          this.$store.commit('hideUserNavigation')
        }
      }, false)
    },
    created () {
      this.loadUnreadCount()
    },
    computed: {
      hideNoticeCount () {
        return this.noticeCount <= 0
      },
      noticeCount () {
        return this.$store.state.notice.unreadCount
      },
      isLogin () {
        return this.$store.state.login.isLogin
      },
    },
    methods: {
      showLoginBox () {
        if (!this.$store.state.login.isLogin) {
          this.$store.commit('showLoginBox')
          return
        }

        this.showUserNavigation()
      },
      showUserNavigation () {
        const now = this.$store.state.login.userNavigationState
        this.$store.commit('updateUserNavigationState')
        return now
      },
      toCardPage () {
        this.$router.push('/card')
      },
      toBlogPage () {
        this.$router.push('/blog-list')
      },
      toBulletinBoardPage () {
        this.$router.push('/bulletin-board/bb-survey')
      },
      toNoticePage () {
        this.$router.push('/notice')
      },
      loadUnreadCount () {

        if (!this.isLogin) {
          return
        }

        NoticeModel.queryUnread()
          .then(data => {
            this.$store.commit('updateNoticeCount', data)
          })
      },

    },
  }
</script>

<style scoped lang="scss">
    @import "src/assets/Sizes";

    .header-bar {
        width: 100%;
        height: 60px;
        background-color: white;
        display: flex;
        justify-content: center;

        &.fixed {
            position: fixed;
            z-index: 100;
        }

        .header-content {
            width: $global-width;
            display: flex;
            justify-content: space-between;
            align-items: center;

            .title {
                display: flex;
                margin-left: 30px;
                font-weight: bold;
                font-size: 30px;
                cursor: pointer;

                .logo {
                    width: 38px;
                    height: 38px;
                    margin-right: 10px;
                }
            }

            .header-navigation {
                display: flex;
                margin-right: 30px;
                font-size: 16px;
                font-weight: bold;
                align-items: center;
                justify-content: flex-end;
                position: relative;

                .header-btn {
                    margin-left: 20px;
                    cursor: pointer;
                    padding: 4px;

                    &:hover {
                        background-color: #ececec;
                        border-radius: 6px;
                    }
                }

                .applet-code {
                    width: 258px;
                    height: 258px;
                    position: absolute;
                    top: 46px;
                    left: 0;
                    background: url("../../../assets/applet_code.jpg") no-repeat 0 0;
                    z-index: 22;
                }

                .notice-area {
                    margin-right: 10px;
                }

                .el-icon-message-solid {
                    font-size: 20px;
                    margin-left: 10px;
                    cursor: pointer;
                }

                .login {
                    display: flex;
                    align-items: center;
                    margin-left: 12px;
                    position: relative;

                    img {
                        width: 24px;
                        height: 24px;
                        margin-right: 4px;
                    }
                }

                .user-navigation {
                    position: absolute;
                    top: 0;
                    margin-top: 50px;
                    right: -60px;
                }
            }
        }
    }
</style>
