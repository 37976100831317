<template>
    <div class="card-input">
        <div ref="textarea" class="textarea">
            <div ref="cardContent"
                 class="input-content"
                 @paste="pasting"
                 @input="computeCardContentSize"
                 contenteditable="true"
                 placeholder="随便说点啥？"/>
            <pic-edit-area :pics="pics" :showAddIcon="true"/>
            <div class="input-tag-area">
                <part-tag @click.native="showTagSelector"
                          @selectTag="showSelectTag"
                          :label="this.$store.state.card.cardTag"
                          :tags="this.$store.state.card.cardTags"
                          :show-selector="showList"
                />
            </div>
            <div class="input-content-limit">{{cardContentTotalSize}}/1000</div>
        </div>
    </div>
</template>

<script>
  import {clipboardAction} from '@/utils/Clipboard'
  import PicEditArea from '@/pages/home/card/pic/PicEditArea'
  import {showError, showSuccess} from '@/utils/Message'
  import PartTag from '@/components/PartTag'

  export default {
    name: 'CardInput',
    components: {PartTag, PicEditArea},
    data () {
      return {
        pics: [],
        maxPicNumber: 8,
        fileIds: [],
        showTag: false,
        showList: false,
        cardContentTotalSize: 0,
      }
    },
    props: {
      tags: Array
    },
    methods: {
      pasting (e) {
        clipboardAction(e, (file) => {
          this.$store.commit('addCardImage', file)

          const reader = new FileReader()
          reader.onload = value => this.pics.push(value.target.result)
          reader.readAsDataURL(file)
        })
      },
      getInputContent () {
        const content = this.$refs.cardContent.innerText

        return {
          'content': content,
          'fileIds': this.fileIds,
          'totalSize': this.cardContentTotalSize
        }
      },
      clearInput () {
        this.$refs.cardContent.innerText = ''
        this.cardContentTotalSize = 0
        this.pics = []
        showSuccess('发布成功')
      },
      showTagSelector () {
        this.showList = !this.showList
      },
      showSelectTag (tag) {
        this.$store.commit('setPublishTag', tag)
      },
      computeCardContentSize () {
        this.cardContentTotalSize = this.$refs.cardContent.innerText.length
      }
    },
  }
</script>

<style scoped lang="scss">
    @import "src/assets/Sizes";
    @import "src/assets/Colors";

    .card-input {
        .textarea {
            width: $card-input-width;
            min-height: 80px;
            height: auto;
            text-align: left;
            border: 1px solid $primary;
            background-color: #f6f6f6;
            position: relative;

            .input-content {
                flex: 1;
                outline: none;
                padding: 12px;
                min-height: 80px;

                &:empty:before {
                    content: attr(placeholder);
                    color: #b4b4b4;
                }
            }

            .input-tag-area {
                display: flex;
                flex-wrap: wrap;

                .part-tag {
                    margin-left: 12px;
                    margin-bottom: 12px;
                }
            }

            .input-content-limit {
                position: absolute;
                right: 10px;
                bottom: 10px;
            }
        }
    }
</style>
