<template>
    <div class="luck-draw-container">
        <div class="luck-draw-title-container">
            <span class="title">幸运大抽奖</span>
        </div>
        <div class="luck-draw-content-container">
            <div class="luck-draw-prizes-container">

                <div class="coin">金币：{{coinTotal}}</div>

                <LuckyGrid
                        class="luck-grid"
                        ref="myLucky"
                        width="440px"
                        height="440px"
                        :prizes="prizes"
                        :blocks="blocks"
                        :defaultStyle="defaultStyle"
                        @start="startCallback"
                        @end="endCallback"
                />

                <div class="luck-draw-button-container">
                    <el-button :disabled="singleLuckDrawDisabled"
                               type="primary"
                               @click="startCallback"
                    >单抽(10金币)</el-button>
                    <el-button v-show="false" type="primary" @click="startTenConsecutive">十连抽</el-button>
                </div>
            </div>

            <div class="luck-draw-right-container">
                <span>
                </span>
            </div>
        </div>

        <el-dialog
                width="600px"
                :visible.sync="dialogVisible">

            <div class="prize-content-container">
                <span class="prize-title">恭喜你获得</span>

                <span class="prize-content">{{winningTips}}</span>
            </div>

            <span slot="footer" class="prize-button-container">
                <el-button type="primary" @click="dialogVisible = false">收下奖励</el-button>
            </span>
        </el-dialog>

        <el-dialog
                width="600px"
                :visible.sync="tenConsecutiveDialogVisible">

            <div class="ten-prize-content-container">
                <span class="prize-title">十连奖励</span>

                <div class="ten-prize-container">
                    <div v-for="(prize, index) in tenPrizes" :key="index">
                        <div class="prize-item">{{prize}}</div>
                    </div>
                </div>
            </div>

            <span slot="footer" class="prize-button-container">
                <el-button type="primary" @click="tenConsecutiveDialogVisible = false">收下奖励</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
  import LuckDrawModel from '@/pages/bulletin-board/luck-draw/model/LuckDrawModel'
  import CoinModel from '@/pages/bulletin-board/luck-draw/model/CoinModel'
  import {showAlert} from '@/utils/MessageBox'

  export default {
    name: 'BbLuckDraw',
    data () {
      return {
        dialogVisible: false,
        tenConsecutiveDialogVisible: false,
        blocks: [
          {
            padding: '20px',
            background: 'rgba(44,62,80,0.1)',
            borderRadius: '10px'
          },
          {
            padding: '20px',
            background: '#2c3e50',
            borderRadius: '10px'
          },
        ],
        prizes: [
          {x: 0, y: 0, fonts: [{text: '', top: '40%', fontColor: '#2c3e50'}]},
          {x: 1, y: 0, fonts: [{text: '', top: '40%', fontColor: '#2c3e50'}]},
          {x: 2, y: 0, fonts: [{text: '', top: '40%', fontColor: '#2c3e50'}]},
          {x: 2, y: 1, fonts: [{text: '', top: '40%', fontColor: '#2c3e50'}]},
          {x: 2, y: 2, fonts: [{text: '', top: '40%', fontColor: '#2c3e50'}]},
          {x: 1, y: 2, fonts: [{text: '', top: '40%', fontColor: '#2c3e50'}]},
          {x: 0, y: 2, fonts: [{text: '', top: '40%', fontColor: '#2c3e50'}]},
          {x: 0, y: 1, fonts: [{text: '', top: '40%', fontColor: '#2c3e50'}]},
          {x: 1, y: 1, fonts: [{text: '', top: '40%', fontColor: '#2c3e50'}]},
        ],
        defaultStyle: {
          background: 'white'
        },
        winningTips: '',
        btnStyle: 'ONE',
        tenPrizes: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
        coinTotal: 0,
        singleLuckDrawDisabled: false,
        isEmptyPrize: false
      }
    },
    mounted () {
      this.initPrize()
      this.initCoin()
    },
    methods: {
      initPrize () {
        LuckDrawModel.getPrize()
          .then((data) => {
            this.isEmptyPrize = data.length <= 0

            if (this.isEmptyPrize) {
              showAlert("提示", "请先到礼物管理, 配置礼物～")
              return
            }

            let i = 0
            this.prizes.forEach((value) => {
              if (data[i]) {
                value.fonts[0].text = data[i].name
                value.fonts[0].id = data[i].id
                i++
              }
            })
          })
      },
      initCoin () {
        CoinModel.get()
          .then((data) => {
            this.coinTotal = data.total
          })
      },
      startCallback () {
        if (this.isEmptyPrize) {
          showAlert("提示", "请先到「礼物管理」, 配置礼物～")
          return
        }

        this.btnStyle = 'ONE'
        this.singleLuckDrawDisabled = true
        LuckDrawModel.luckDraw()
          .then((data) => {
            this.coinTotal = this.coinTotal - 10
            this.$refs.myLucky.play()
            setTimeout(() => {
              const index = this.getIndexFromPrizes(data.id)
              this.$refs.myLucky.stop(index)
            }, 2000)
          })
          .catch(() => {
            this.singleLuckDrawDisabled = false
          })
      },
      startTenConsecutive () {
        this.btnStyle = 'TEN'
        this.$refs.myLucky.play()
        setTimeout(() => {
          this.$refs.myLucky.stop(0)
        }, 1000)
      },
      getIndexFromPrizes (id) {
        const total = this.prizes.length
        for (let i = 0; i < total; i++) {
          const prize = this.prizes[i]
          if (prize.fonts[0].id === id) {
            return i
          }
        }

        return 0
      },
      // 抽奖结束会触发end回调
      endCallback (data) {

        if (this.btnStyle === 'ONE') {

          this.dialogVisible = true
          const result = this.prizes.filter(prize => prize.x === data.x && prize.y === data.y)
          this.winningTips = result[0].fonts[0].text

          this.singleLuckDrawDisabled = false
          return
        }

        this.tenConsecutiveDialogVisible = true
      },
    }
  }
</script>

<style scoped lang="scss">
    @import "src/assets/Colors";

    .luck-draw-container {
        flex: 1;
        display: flex;
        flex-direction: column;

        .luck-draw-title-container {
            height: 120px;
            display: flex;
            align-items: center;
            justify-content: center;

            .title {
                font-size: 50px;
                font-weight: bold;
            }
        }

        .luck-draw-content-container {
            flex: 1;
            display: flex;

            .luck-draw-prizes-container {
                flex: 1;
                display: flex;
                flex-direction: column;
                align-items: center;

                .coin {
                    color: white;
                    background-color: $primary;
                    padding: 10px 20px;
                    border-radius: 30px;
                }

                .luck-grid {
                    margin-top: 20px;
                }

                .luck-draw-button-container {
                    width: 400px;
                    display: flex;
                    justify-content: space-evenly;
                    margin-top: 20px;
                }
            }

            .luck-draw-right-container {
                width: 600px;
            }
        }

        .prize-content-container {
            display: flex;
            flex-direction: column;
            align-items: center;
            justify-content: center;

            .prize-title {
                font-size: 30px;
                font-weight: bold;
                margin-bottom: 20px;
            }

            .prize-content {
                font-size: 20px;
            }
        }

        .ten-prize-content-container {
            display: flex;
            flex-direction: column;

            .prize-title {
                font-size: 30px;
                font-weight: bold;
                margin-bottom: 20px;
            }

            .ten-prize-container {
                display: flex;
                justify-content: space-between;
                flex-wrap: wrap;

                .prize-item {
                    width: 100px;
                    height: 100px;
                    line-height: 100px;
                    border-radius: 10px;
                    background-color: rgba(44, 62, 80, 0.2);
                    margin-bottom: 15px;
                }
            }
        }

        .prize-button-container {
            display: flex;
            justify-content: center;
        }

    }
</style>
