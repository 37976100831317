<template>
    <div id="app">
        <header-bar/>
        <!--解决重新加载页面问题-->
        <router-view v-if="!$route.meta.keepAlive && isRouterAlive"/>
        <!--        <footer-bar/>-->
        <login-box v-show="showLoginBox"/>

        <div v-show="showBackTop" class="back-top" @click="backTop">
            <i class="el-icon-caret-top"/>
        </div>
    </div>
</template>

<script>
  import HeaderBar from '@/pages/home/header/HeaderBar'
  import FooterBar from '@/pages/FooterBar'
  import LoginBox from '@/pages/login/LoginBox'

  export default {
    name: 'App',
    components: {
      LoginBox,
      HeaderBar,
      FooterBar
    },
    provide () {
      return {
        reload: this.reload
      }
    },
    data () {
      return {
        showBackTop: false,
        isRouterAlive: true
      }
    },
    created () {
    },
    computed: {
      showLoginBox () {
        if (this.$store.state.login.isLogin) {
          return
        }

        return this.$store.state.login.loginBoxState
      },
      key () {
        console.log(this.$route.fullPath)
        return this.$route.fullPath
      }
    },
    mounted () {
      window.addEventListener('scroll', this.scrollToTop)
    },
    destroyed () {
      window.removeEventListener('scroll', this.scrollToTop)
    },
    methods: {
      // 点击回到顶部方法，加计时器是为了过渡顺滑
      backTop () {
        let timer = setInterval(() => {
          let isPeed = Math.floor(-this.scrollTop / 5)
          document.documentElement.scrollTop = document.body.scrollTop = this.scrollTop + isPeed
          if (this.scrollTop === 0) {
            clearInterval(timer)
          }
        }, 16)
      },

      scrollToTop () {
        this.scrollTop = window.pageYOffset || document.documentElement.scrollTop || document.body.scrollTop
        this.showBackTop = this.scrollTop > 200
      },
      reload () {
        this.isRouterAlive = false
        this.$nextTick(() => (this.isRouterAlive = true))
      }
    }
  }
</script>

<style lang="scss">
    @import "assets/Sizes";

    body {
        margin: 0;
        padding: 0;
        background-color: #f4f5f5;
    }

    #app {
        font-family: Avenir, Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: center;
        color: #2c3e50;
        display: flex;
        flex-direction: column;
        align-items: center;

        .header-bar {
            position: sticky;
            z-index: 100;
            top: 0;
        }

        .back-top {
            width: 50px;
            height: 50px;
            display: flex;
            justify-content: center;
            align-items: center;
            position: fixed;
            right: 40px;
            bottom: 40px;
            background-color: white;
            border-radius: 50%;
            cursor: pointer;
            box-shadow: 2px 2px #CECECE;
            font-size: 24px;
        }
    }

</style>
