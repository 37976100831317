<template>
    <div>
        BBWorkbench
    </div>
</template>

<script>
  export default {
    name: 'BbWorkbench'
  }
</script>

<style scoped>

</style>
