<template>
    <div class="personal-setting-item">
        <div class="name">昵称</div>
        <el-input
                type="text"
                placeholder="请输入内容"
                v-model="username"
                maxlength="20"
                show-word-limit
        >
        </el-input>
    </div>
</template>

<script>
  export default {
    name: 'PersonalSettingItem',
    data () {
      return {
        username: ''
      }
    },
    created () {
      this.username = this.$store.state.login.username
    }
  }
</script>

<style scoped lang="scss">
    .personal-setting-item {
        display: flex;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;

        .name {
            margin-right: 52px;
        }

        .el-input {
            width: 400px;
        }
    }
</style>
