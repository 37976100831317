<template>
    <viewer class="pic-edit-area" :images="pics">
        <div v-for="(picUrl, index) in pics" :key="index">
            <div class="pic" v-show="pics.length > 0">
                <img class="pic-content" :src="picUrl" alt=""/>
                <div @click="deletePic(index)" class="pic-delete"/>
            </div>
        </div>

        <div class="add-pic" v-show="hasShowAddIcon()">
            <img :src="addImage" alt=""/>
            <label for="input"/>
            <input id="input" type="file" @change="addPic" hidden/>
        </div>
    </viewer>
</template>

<script>
  export default {
    name: 'PicEditArea',
    props: {
      pics: Array,
      showAddIcon: Boolean,
      paddingLeftSize: Number
    },
    data () {
      return {
        addImage: require('@/assets/icons/add.png'),
      }
    },
    methods: {
      hasShowAddIcon () {
        return this.pics.length < 8
      },
      addPic (e) {
        const file = e.target.files[0]
        this.$store.commit('addCardImage', file)

        const reader = new FileReader()
        reader.onload = value => this.pics.push(value.target.result)
        reader.readAsDataURL(file)
      },
      deletePic(index) {
        this.pics.splice(index, 1)
        this.$store.commit('deleteCardImage', index)
      }
    }
  }
</script>

<style scoped lang="scss">
    @import "src/assets/Sizes";
    @import "src/assets/Styles";

    .pic-edit-area {
        @include pic-area;
        margin-left: 12px;

        .pic {
            position: relative;

            .pic-delete {
                width: 20px;
                height: 20px;
                position: absolute;
                top: -10px;
                right: -10px;
                cursor: pointer;
                z-index: 10;
                content: ' ';
                background-image: url("../../../../assets/icons/circle_delete.png");
                background-size: 20px 20px;
            }
        }

        .add-pic {
            display: flex;
            align-items: center;
            justify-content: center;
            position: relative;
            width: $card-image-with;
            height: $card-image-with;
            margin-top: 4px;
            background-color: #ececec;

            img {
                width: 32px;
                height: 32px;
            }

            label {
                position: absolute;
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                z-index: 10;
            }
        }
    }
</style>
