<template>
    <div class="bb-todo-container">

        <do-sidebar
            menu-title="待办列表"
            :add-menu-click="showAddDialog"
            :menus="menus"
            :menu-item-click="menuItemClick"
        />

        <div class="bb-todo-content">

            <do-table-option-header
                :add-click="handleAdd"
                :batch-move-click="handleBatchMove"
                :batch-delete-click="handleBatchDelete"
                :options="todoStateOptions"
                v-model="todoSelectState"
                @input="todoStateChange"
            />

            <el-table
                ref="multipleTable"
                :data="tableData"
                stripe
                height="300"
                @row-click="showEditDialog"
                style="width: 100%">
                <el-table-column
                    align="center"
                    type="selection"
                    prop="date">
                </el-table-column>

                <el-table-column
                    prop="content"
                    label="待办事项"
                >
                </el-table-column>
                <el-table-column
                    prop="vetoReason"
                    label="否决理由"
                    width="150"
                >
                </el-table-column>

                <el-table-column
                    align="center"
                    prop="state"
                    label="状态"
                    width="70"
                >
                    <template slot-scope="scope">
                        <div class="state-container" :class="[scope.row.state]">
                            {{ formatState(scope.row.state) }}
                        </div>
                    </template>
                </el-table-column>

                <el-table-column
                    align="center"
                    label="是否专注"
                    width="100">
                    <template slot-scope="scope">
                        <el-switch
                            :value="scope.row.flag"
                            :active-value="activeFlagValue"
                            :active-color="primaryColor"
                            inactive-color="#dcdfe6"
                            @change="changeFocusState($event, scope.row)"
                        >
                        </el-switch>
                    </template>
                </el-table-column>

                <el-table-column
                    align="center"
                    property="createTime"
                    label="日期"
                    width="100">
                    <template slot-scope="scope">
                        {{ formatCreateTime(scope.row.createTime) }}
                    </template>
                </el-table-column>

                <el-table-column align="center" label="操作" width="100">
                    <template slot-scope="scope">
                        <el-dropdown>
                            <i class="el-icon-more-outline"></i>

                            <el-dropdown-menu slot="dropdown">

                                <div v-for="(data) in operationData" :key="data.state">
                                    <el-dropdown-item
                                        v-show="scope.row.state !== data.state"
                                        @click.native="handleOperation(data, scope.$index, scope.row)"
                                    >
                                        {{ data.content }}
                                    </el-dropdown-item>
                                </div>
                            </el-dropdown-menu>
                        </el-dropdown>
                    </template>
                </el-table-column>
            </el-table>

            <el-pagination
                background
                @current-change="getTodo"
                :current-page.sync="page"
                :page-size="size"
                layout="total, prev, pager, next"
                :total="total"
            >
            </el-pagination>
        </div>

        <el-dialog
            class="el-dialog-add-folder-container"
            title="添加列表"
            :visible.sync="folderDialogVisible"
            width="30%"
            :before-close="() => this.folderDialogVisible = false"
            @keyup.enter.native="confirmFolder"
        >
            <div>
                <column-input title="列表名称" title-placeholder="请输入列表名称" v-model="editTitle" maxlength="8"/>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="folderDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmFolder">确 定</el-button>
            </span>
        </el-dialog>

        <el-dialog
            class="el-dialog-move-container"
            title="移动待办"
            :visible.sync="moveDialogVisible"
            width="30%"
            :before-close="() => this.moveDialogVisible = false"
            @keyup.enter.native="confirmMove"
        >

            <div class="folder-container">
                <div class="folder-title">文件夹</div>

                <el-select v-model="moveFolderId">
                    <el-option
                        v-for="item in folderData"
                        :key="item.id"
                        :label="item.name"
                        :value="item.id">
                    </el-option>
                </el-select>
            </div>

            <span slot="footer" class="dialog-footer">
                <el-button @click="moveDialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmMove">确 定</el-button>
            </span>
        </el-dialog>

        <BbTodoAddDialog ref="addDialog" @addTodoContentSuccess="addTodoContentSuccess"/>

        <do-todo-detail
            ref="todoDetail"
            :list="tableData"
            @deleteTodo="deleteTodoSuccess"
            @vetoTodo="vetoTodoSuccess"
        />

    </div>
</template>

<script>
import ColumnInput from '@/components/ColumnInput'
import TodoItem from '@/pages/home/todo/TodoItem'
import TodoModel from '@/model/TodoModel'
import BbTodoModel from '@/pages/bulletin-board/todo/BbTodoModel'
import {formatDate} from '@/utils/Date'
import {showError, showSuccess} from '@/utils/Message'
import {showCloseDialog, showPrompt} from '@/utils/Dialog'
import {deleteByKey, getByKey} from '@/utils/Array'
import FolderModel from '@/pages/bulletin-board/todo/FolderModel'
import BbTodoAddDialog from '@/pages/bulletin-board/todo/BbTodoAddDialog'
import DoTodoDetail from '@/pages/home/todo/DoTodoDetail'
import DoSidebar from '@/components/DoSidebar'
import DoTableOptionHeader from '@/components/DoTableOptionHeader'

export default {
    name: 'BbTodo',
    components: {DoTableOptionHeader, DoSidebar, DoTodoDetail, BbTodoAddDialog, TodoItem, ColumnInput},
    data () {
        return {
            activeFlagValue: 'FOCUS',
            primaryColor: '#2c3e50',
            folderDialogVisible: false,
            menuSelectId: '0',
            menusSelectName: '',
            menus: [
                {
                    id: '0',
                    name: '默认',
                },
            ],
            menusSize: 20,
            editTitle: '',
            tableData: [],
            page: 1,
            size: 10,
            total: 0,
            operationData: [
                {
                    content: '编辑',
                    state: 'EDIT'
                },
                {
                    content: '完成',
                    state: 'FINISH'
                },
                {
                    content: '否决',
                    state: 'VETO'
                },
                {
                    content: '删除',
                    state: 'DELETE'
                },
                {
                    content: '移动',
                    state: 'MOVE'
                },
            ],
            moveDialogVisible: false,
            folderData: [],
            moveFolderId: '',
            moveTodoId: 0,
            moveTodoIds: [],
            todoSelectState: 'CREATE',
            todoStateOptions: [
                {
                    label: '全部',
                    key: 'ALL'
                }, {
                    label: '待办',
                    key: 'CREATE'
                }, {
                    label: '完成',
                    key: 'FINISH'
                },
                {
                    label: '否决',
                    key: 'VETO'
                }
            ]
        }
    },
    mounted () {
        this.menusSelectName = this.menus[0].name
        this.getMenu()
        this.getTodo(1)
    },
    methods: {
        formatCreateTime (createTime) {
            return formatDate(createTime, 'YY-MM-DD')
        },
        formatState (selectState) {
            const curState = getByKey(this.todoStateOptions, (state) => state.key === selectState)
            return curState.label
        },
        getMenu () {
            FolderModel.getMenu({
                page: 0,
                size: this.menusSize,
                type: 'TODO'
            }).then((response) => {
                this.menus = this.menus.concat(response.list)
            })
        },
        getTodo (page) {
            BbTodoModel.getContent({
                page: page - 1,
                size: this.size,
                folderId: this.menuSelectId,
                state: this.todoSelectState === 'ALL' ? null : this.todoSelectState
            }).then((data) => {
                this.total = data.total
                this.tableData = data.list
            })
        },
        menuItemClick (curMenuId) {
            if (curMenuId === this.menuSelectId) {
                return
            }

            this.menuSelectId = curMenuId

            const menu = getByKey(this.menus, (menu) => menu.id.toString() === curMenuId)
            this.menusSelectName = menu.name
            this.getTodo(1)
        },
        todoStateChange (label) {
            this.getTodo(1)
        },
        showAddDialog () {
            this.folderDialogVisible = true
        },
        confirmFolder () {

            FolderModel.add({
                name: this.editTitle,
                type: 'TODO'
            }).then((data) => {

                this.menus.splice(1, 0, {
                    id: data.id,
                    name: this.editTitle
                })
                this.editTitle = ''
                this.folderDialogVisible = false
            })

        },
        handleOperation (operation, index, todoData) {
            switch (operation.state) {
                case 'EDIT':
                    this.handleEdit(index, todoData)
                    break
                case 'FINISH':
                    this.handleFinish(index, todoData)
                    break
                case 'VETO':
                    this.handleVeto(index, todoData)
                    break
                case 'DELETE':
                    this.handleDelete(index, todoData)
                    break
                case 'MOVE':
                    this.handleMove(index, todoData)
                    break
            }
        },
        handleEdit (index, todoData) {
            this.$refs.todoDetail.showDetailContent(todoData)
        },
        showEditDialog (row, column) {
            if (column && column.label === '操作') {
                return
            }

            this.$refs.todoDetail.showDetailContent(row)
        },
        modifyTodoContent (oldTodoData, newContent) {
            const modifyContent = getByKey(this.tableData, (todo) => todo.id === oldTodoData.id)
            modifyContent.content = newContent
        },
        deleteTodoSuccess (todoId) {
            this.deleteTableData(todoId)
        },
        vetoTodoSuccess (todoId, vetoReason) {
            this.refreshTableData(todoId, 'VETO', vetoReason)
        },
        handleFinish (index, todoData) {
            TodoModel.finish({id: todoData.id})
                .then((data) => {
                    showSuccess('完成成功')
                    this.refreshTableData(todoData.id, 'FINISH', todoData.vetoReason)
                })
        },
        handleVeto (index, todoData) {
            showPrompt('确定要否决吗？', '为啥咧？')
                .then(({value}) => {
                    TodoModel.veto({
                        id: todoData.id,
                        vetoReason: value
                    }).then((data) => {
                        showSuccess('否决成功')
                        this.vetoTodoSuccess(todoData.id, value)
                    })
                })
        },
        handleDelete (index, todoData) {
            showCloseDialog('确定要删除吗？')
                .then(() => {
                    TodoModel.delete({
                        id: todoData.id
                    }).then((data) => {
                        showSuccess('删除成功')
                        this.deleteTodoSuccess(todoData.id)
                    })
                })
        },
        refreshTableData (todoId, newState, vetoReason = '') {

            if (this.todoSelectState === 'ALL') {
                const curData = getByKey(this.tableData, (todo) => todo.id === todoId)
                if (curData) {
                    curData.state = newState
                    curData.vetoReason = vetoReason
                }
                return
            }

            this.deleteTableData(todoId)
        },
        deleteTableData (todoId) {
            this.total = this.total - 1
            deleteByKey(this.tableData, (curTodo) => curTodo.id === todoId)
        },
        handleMove (index, todoData) {

            FolderModel.getMenu({
                page: 0,
                size: this.menusSize,
                type: 'TODO'
            }).then((response) => {
                this.refreshFolderData(response.list)
                this.moveDialogVisible = true
                this.moveTodoId = todoData.id
                this.moveType = 'SINGLE'
            })
        },
        handleAdd () {
            this.$refs.addDialog.showDialog(this.menuSelectId)
        },
        addTodoContentSuccess () {
            this.getTodo(1)
        },
        handleBatchMove () {

            const selectData = this.$refs.multipleTable.selection
            if (selectData.length <= 0) {
                showError('请先勾选Todo 再操作')
                return
            }

            FolderModel.getMenu({
                page: 0,
                size: this.menusSize,
                type: 'TODO'
            }).then((response) => {
                this.refreshFolderData(response.list)
                this.moveDialogVisible = true
                this.moveTodoIds = selectData.map((data) => data.id)
                this.moveType = 'BATCH'
            })
        },
        confirmMove () {
            if (this.moveFolderId === '') {
                showError('请选择移动目录')
                return
            }

            if (this.moveType === 'SINGLE') {
                BbTodoModel.move({
                    folderId: this.moveFolderId,
                    todoId: this.moveTodoId,
                }).then((data) => {
                    this.tableData = this.tableData.filter(data => this.moveTodoId !== data.id)

                    this.moveFolderId = ''
                    this.moveTodoId = 0
                    this.moveDialogVisible = false
                })
                return
            }

            BbTodoModel.moveBatch({
                folderId: this.moveFolderId,
                todoIds: this.moveTodoIds,
            }).then((data) => {
                this.tableData = this.tableData.filter(data => this.moveTodoIds.indexOf(data.id) === -1)

                this.moveFolderId = ''
                this.moveTodoIds = 0
                this.moveDialogVisible = false
            })
        },
        handleBatchDelete () {
            const selectData = this.$refs.multipleTable.selection
            if (selectData.length <= 0) {
                showError('请先勾选Todo 再操作')
                return
            }

            const todoIds = selectData.map((data) => data.id)
            showCloseDialog('确定要删除吗？')
                .then(() => {
                    BbTodoModel.deleteBatch({
                        todoIds: todoIds
                    }).then((data) => {
                        showSuccess('删除成功')
                        const newTotal = this.total - todoIds.length
                        this.total = newTotal > 0 ? newTotal : 0
                        this.tableData = this.tableData.filter(data => todoIds.indexOf(data.id) === -1)
                    })
                })
        },
        refreshFolderData (curFolderData) {
            curFolderData.unshift({
                name: '默认',
                id: 0
            })
            this.folderData = curFolderData
        },
        changeFocusState (curFlag, todoData) {
            if (curFlag === this.activeFlagValue) {
                TodoModel.focus({id: todoData.id})
                    .then(data => this.refreshFlag(todoData.id, curFlag))
                return
            }

            TodoModel.unfocused({id: todoData.id})
                .then(data => this.refreshFlag(todoData.id, curFlag))
        },
        refreshFlag (id, curFlag) {
            const curData = getByKey(this.tableData, (data) => data.id === id)
            curData.flag = curFlag
        },
    }
}
</script>

<style scoped lang="scss">
@import "src/assets/Colors";
@import "src/assets/Sizes";

.bb-todo-container {
    width: 1300px;
    height: 800px;
    display: flex;
    background-color: white;

    .bb-todo-sidebar {
        width: 180px;
        height: 800px;
        padding-left: 10px;
        padding-top: 20px;

        .sidebar-title-area {
            display: flex;
            align-items: center;
            justify-content: space-evenly;

            span {
                font-size: 18px;
                font-weight: bold;
            }

            .el-icon-circle-plus-outline {
                font-size: 24px;
                cursor: pointer;
            }
        }

        .el-menu {
            margin-top: 14px;
        }

        .el-menu-item {
            text-align: left;
            margin-bottom: 6px !important;
            font-weight: bold !important;

            &:hover {
                animation: myTranslateX 0.8s;
                background-color: rgba(0, 0, 0, 0) !important;
            }

            &.is-active {
                background-color: rgba(0, 0, 0, 0) !important;
            }

            @keyframes myTranslateX {
                0% {
                    transform: translateX(4px);
                }
                100% {
                    transform: translateX(0);
                }
            }
        }

        .menu-item-select-background {
            padding-left: 10px;
            border-radius: 12px;

            &:hover {
                background-color: #f3f3f3;
            }

            &.select {
                color: white;
                background-color: $primary;
            }
        }
    }

    .bb-todo-content {
        flex: 1;
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 10px;

        .state-container {
            text-align: center;
            border-radius: 4px;
            color: white;

            &.FINISH {
                background-color: #00b8a9;
            }

            &.VETO {
                background-color: #f5acac;
            }

            &.CREATE {
                background-color: #cecece;
            }
        }


        .el-icon-more-outline {
            padding: 6px;
            border-radius: 6px;
            font-size: 20px;

            &:hover {
                background-color: #dcdcdc;
            }
        }

        .el-pagination {
            margin-top: 10px;
        }
    }

    .el-dialog-move-container {

        .folder-container {
            display: flex;
            align-items: center;

            .folder-title {
                margin-right: 10px;
            }
        }
    }
}


</style>
