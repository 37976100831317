<template>
    <div class="progress-add-item-container">

        <el-dialog
                :title="dialogTitle"
                :visible.sync="dialogVisible"
                :before-close="() => this.dialogVisible = false"
                width="30%">

            <div slot="title" class="custom-dialog-title">
                <span>{{dialogTitle}}</span>
            </div>

            <div class="progress-flag-container">
                <div class="progress-flag-title">专注</div>
                <el-switch
                        v-model="flag"
                        active-value="FOCUS"
                        :inactive-value="null"
                        active-color="#2c3e50"
                        inactive-color="#dcdfe6"
                >
                </el-switch>
            </div>

            <div class="progress-input-container">
                <span class="title">标题</span>
                <el-input
                    v-bind="$attrs"
                    type="text"
                    placeholder="请输入标题"
                    v-model="title"
                    maxlength="255"
                    show-word-limit
                >
                </el-input>
            </div>

            <div class="progress-add-item-progress-box">
                <span class="progress-box-title">进度</span>

                <div class="progress-detail-area">
                    <row-input
                            title="总量"
                            v-model="total"
                            :input-width="150"
                    />
                    <row-input
                            title="进度"
                            v-model="progress"
                            :input-width="150"
                    />
                </div>

                <div class="progress-detail-area">
                    <row-input
                        title="增量"
                        v-model="increment"
                        :input-width="150"
                    />

                    <row-input
                        title="单位"
                        v-model="unit"
                        :input-width="150"
                    />
                </div>
            </div>

            <div class="progress-input-container">
              <span class="title">备注</span>
              <el-input
                  v-bind="$attrs"
                  type="textarea"
                  placeholder="请输入备注"
                  v-model="remark"
                  maxlength="100"
                  show-word-limit
                  autosize
              >
              </el-input>
            </div>

            <span slot="footer">
                <el-button @click="() => this.dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="addProgressContent">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
  import ColumnInput from '@/components/ColumnInput'
  import RowInput from '@/components/RowInput'
  import {showError, showSuccess} from '@/utils/Message'
  import ProgressModel from '@/model/ProgressModel'
  import {updateByKey} from '@/utils/Array'

  const EDIT = 'edit'
  const ADD = 'add'

  export default {
    name: 'ProgressAddItem',
    components: {RowInput, ColumnInput},
    props: {
      list: Array,
    },
    data () {
      return {
        progressTaskId: 0,
        total: 0,
        increment: 1,
        unit: '',
        title: '',
        progress: 0,
        type: 'ADD',
        flag: 'FOCUS',
        dialogVisible: false,
        folderId: 0,
        remark: '',
        dialogTitle: ''
      }
    },
    computed: {},
    methods: {
      addProgressContent () {
        if (this.title === '') {
          showError('标题不能为空')
          return
        }

        if (this.total <= 0) {
          showError('总量输入有误')
          return
        }

        if (this.increment <= 0) {
          showError('增量输入有误')
          return
        }

        if (this.type === 'ADD') {

          const params = {
            folderId: this.folderId,
            title: this.title,
            total: this.total,
            unit: this.unit,
            increment: this.increment,
            progress: this.progress,
            flag: this.flag,
            remark: this.remark
          }

          ProgressModel.add(params)
            .then(data => {
              this.dialogVisible = false
              this.clearInput()
              this.list.unshift(data)
            })
          return
        }

        const params = {
          progressTaskId: this.progressTaskId,
          title: this.title,
          total: this.total,
          unit: this.unit,
          increment: this.increment,
          progress: this.progress,
          flag: this.flag,
          remark: this.remark
        }

        ProgressModel.update(params)
          .then((newData) => {
            showSuccess('更新成功')
            this.dialogVisible = false

            updateByKey(
              this.list,
              (task) => task.id === newData.id,
              (i) => this.list.splice(i, 1, newData)
            )

            this.$emit('updateProgressSuccess', newData)
          })

      },
      clearInput () {
        this.title = ''
        this.total = 0
        this.increment = 1
        this.unit = ''
        this.progress = 0
        this.folderId = 0
        this.remark = ''
      },
      showEditContent (data) {
        this.dialogVisible = true

        this.type = 'EDIT'
        this.dialogTitle = '修改进度'
        this.progressTaskId = data.id
        this.title = data.title
        this.total = data.total
        this.increment = data.increment
        this.unit = data.unit
        this.progress = data.progress
        this.flag = data.flag
        this.remark = data.remark
      },
      showDialog (folderId) {
        this.type = 'ADD'
        this.clearInput()
        this.dialogTitle = '添加进度'
        this.folderId = folderId
        this.dialogVisible = true
      }
    }
  }
</script>

<style scoped lang="scss">

    .progress-add-item-container {

        .custom-dialog-title {
            font-size: 24px;
            font-weight: bold;
        }

        .progress-flag-container {
            display: flex;
            align-items: center;

            .progress-flag-title {
                text-align: left;
                font-size: 20px;
                font-weight: bold;
                margin-right: 10px;
            }
        }

        .progress-input-container {
            display: flex;
            flex-direction: column;
            margin-top: 20px;

            .title {
                font-size: 20px;
                font-weight: bold;
                margin-bottom: 10px;
            }
        }

        .progress-add-item-progress-box {
            display: flex;
            flex-direction: column;
            margin-top: 20px;

            .progress-box-title {
                text-align: left;
                font-size: 20px;
                font-weight: bold;
            }

            .progress-detail-area {
                display: flex;
                flex-wrap: wrap;
                justify-content: space-between;
            }
        }
    }
</style>
