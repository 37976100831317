<template>
    <div class="bb-gift-container">
        <div class="bb-gift-content">

            <div class="gift-table-header-container">
                <div class="gift-content-batch-button-container">
                    <el-button size="small" @click="showAddDialog">新增</el-button>
                    <el-button size="small">批量删除</el-button>
                </div>
                <div class="gift-right-prob-container">
                    <span>礼物总概率: {{totalProb}}</span>
                </div>
            </div>

            <el-table
                    @row-click="rowClick"
                    ref="multipleTable"
                    :data="tableData"
                    stripe
                    height="300"
                    style="width: 100%">

                <el-table-column
                        align="center"
                        type="selection">
                </el-table-column>

                <el-table-column
                        align="center"
                        label="名称"
                        prop="name">
                </el-table-column>

                <el-table-column
                        align="center"
                        label="概率"
                        width="100"
                        prop="prob">
                </el-table-column>

                <el-table-column
                        align="center"
                        label="抽奖奖品"
                        width="100">
                    <template slot-scope="scope">
                        <el-switch
                                :value="scope.row.flag"
                                :active-value="activeFlagValue"
                                :inactive-value="null"
                                :active-color="primaryColor"
                                inactive-color="#dcdfe6"
                                @change="changeFlagState($event, scope.row)"
                        >
                        </el-switch>
                    </template>
                </el-table-column>

            </el-table>

            <el-pagination
                    background
                    @current-change="getGift"
                    :current-page.sync="page"
                    :page-size="size"
                    layout="total, prev, pager, next"
                    :total="total"
            >
            </el-pagination>
        </div>

        <el-dialog
                class="el-dialog-add-gift-container"
                title="添加礼物"
                :visible.sync="dialogVisible"
                width="500px"
                :before-close="() => this.dialogVisible = false"
        >
            <div class="dialog-content-container">
                <div class="flag-container">
                    <div class="flag-title">奖品</div>
                    <el-switch
                            v-model="flag"
                            active-value="PRIZE"
                            :inactive-value="null"
                            active-color="#2c3e50"
                            inactive-color="#dcdfe6"
                    >
                    </el-switch>
                </div>


                <column-input
                        title="礼物名称"
                        width="400"
                        title-placeholder="请输入礼物名称"
                        v-model="giftName"
                        maxlength="250"/>

                <column-input class="column-prob"
                              title="礼物概率 (请输入 0~1 的小数)"
                              width="100"
                              title-placeholder="请输入礼物概率(0-1的小数)"
                              v-model="giftProb"
                />
            </div>

            <span slot="footer" class="dialog-footer">
                <div class="footer-left-container">
                    <el-button
                            v-show="this.dialogType==='UPDATE'"
                            type="danger"
                            @click="deleteGift">删 除</el-button>
                </div>
                <el-button @click="dialogVisible = false">取 消</el-button>
                <el-button type="primary" @click="confirmAddGift">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
  import ColumnInput from '@/components/ColumnInput'
  import BbGiftModel from '@/pages/bulletin-board/luck-draw/model/BbGiftModel'
  import {showError, showSuccess} from '@/utils/Message'
  import {deleteByKey, getByKey} from '@/utils/Array'
  import {showCloseDialog} from '@/utils/Dialog'

  export default {
    name: 'BbGiftManagement',
    components: {ColumnInput},
    data () {
      return {
        tableData: [],
        dialogVisible: false,
        giftName: '',
        giftProb: 0,
        activeFlagValue: 'PRIZE',
        primaryColor: '#2c3e50',
        dialogType: 'ADD',
        selectId: 0,
        flag: 'PRIZE',
        page: 1,
        size: 20,
        total: 0,
        totalProb: 0
      }
    },
    mounted () {
      this.getGift(1)
      this.initTotalProb()
    },
    methods: {
      getGift (page) {
        BbGiftModel.getContent({
          page: page - 1,
          size: this.size,
        }).then((data) => {
          this.total = data.total
          this.tableData = data.list
        })
      },
      initTotalProb () {
        BbGiftModel.getTotalProb()
          .then((data) => {
            this.totalProb = data
          })
      },
      showAddDialog () {
        this.giftProb = 0
        this.giftName = ''
        this.dialogType = 'ADD'
        this.dialogVisible = true
      },
      confirmAddGift () {

        if (this.giftName === '') {
          showError('礼物名称不能为空')
          return
        }

        if (this.giftProb === '') {
          showError('礼物概率不能为空')
          return
        }

        const curProb = parseFloat(this.giftProb)
        if (0 >= curProb || curProb >= 1) {
          showError('礼物概率错误, 请重新输入')
          return
        }

        if (this.dialogType === 'ADD') {
          BbGiftModel.add({
            flag: this.flag,
            name: this.giftName,
            prob: this.giftProb
          }).then((data) => {
            this.tableData.unshift(data)
            this.dialogVisible = false
            this.initTotalProb()
          })
          return
        }

        this.updateGift()
      },
      updateGift () {
        BbGiftModel.update({
          id: this.selectId,
          flag: this.flag,
          name: this.giftName,
          prob: this.giftProb
        }).then((data) => {
          const curData = getByKey(this.tableData, (data) => data.id === this.selectId)
          curData.name = this.giftName
          curData.prob = this.giftProb
          curData.flag = this.flag
          this.initTotalProb()
          this.dialogVisible = false
        })
      },
      changeFlagState (curFlag, row) {
        this.selectId = row.id
        this.giftName = row.name
        this.giftProb = row.prob
        this.flag = curFlag
        this.updateGift()
      },
      deleteGift () {
        showCloseDialog('确定要删除吗？')
          .then(() => {
            BbGiftModel.delete({id: this.selectId})
              .then((data) => {
                deleteByKey(this.tableData, (data) => data.id === this.selectId)
                showSuccess('删除成功')
                this.dialogVisible = false
              })
          })
      },
      rowClick (row, column, event) {

        if (column && column.label === '抽奖奖品') {
          return
        }

        this.dialogType = 'UPDATE'
        this.dialogVisible = true
        this.selectId = row.id
        this.giftName = row.name
        this.giftProb = row.prob
      }
    }
  }
</script>

<style scoped lang="scss">

    .bb-gift-container {
        flex: 1;

        .bb-gift-content {
            display: flex;
            flex-direction: column;
            height: 820px;
            overflow-x: hidden;
            overflow-y: scroll;

            .gift-table-header-container {
                width: 100%;
                display: flex;
                align-items: flex-start;
                justify-content: space-between;
                margin: 20px;

                .gift-content-title {
                    font-size: 24px;
                    font-weight: bold;
                    margin-bottom: 14px;
                }

                .gift-right-prob-container {
                    margin-right: 130px;
                    font-size: 20px;
                    font-weight: bold;
                }
            }

            .el-pagination {
                margin-top: 10px;
                margin-bottom: 10px;
            }
        }

        .el-dialog-add-gift-container {

            .dialog-content-container {

                .flag-container {
                    display: flex;
                    align-items: center;
                    padding-bottom: 10px;

                    .flag-title {
                        text-align: left;
                        font-size: 16px;
                        font-weight: bold;
                        margin-right: 10px;
                    }
                }

                .column-prob {
                    margin-top: 10px;
                }

            }


            .dialog-footer {
                display: flex;

                .footer-left-container {
                    display: flex;
                    flex: 1;
                }
            }
        }
    }
</style>
