import httpClient from '@/http/Axios'

export default class CommentModel {

  static get (param) {
    return httpClient.post('/comment/query', param)
  }

  static publish (param) {
    return httpClient.post('/comment/publish', param)
  }

  static reply (param) {
    return httpClient.post('/reply-comment/publish', param)
  }

  static getReplyComments (param) {
    return httpClient.post('/reply-comment/query', param)
  }
}
