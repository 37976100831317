<template>
    <div class="card-navigation">
        <div class="right-label">
        </div>
        <el-button class="publish-button"
                   type="primary"
                   :disabled="isDisabled"
                   :loading="loading"
                   round
                   @click="submit()"
        >
            发布
        </el-button>
    </div>
</template>

<script>
  import {showError} from '@/utils/Message'
  import CardModel from '@/model/CardModel'

  export default {
    name: 'CardNavigation',
    data () {
      return {
        isDisabled: false,
        loading: false,
      }
    },
    methods: {
      submit () {
        this.$emit('triggerInput')

        let inputContent = this.$parent.inputContent
        if (inputContent === '') {
          showError('请输入内容')
          return
        }

        if (this.$parent.totalSize > 1000) {
          showError('输入内容超出1000字')
          return
        }

        this.isDisabled = true
        this.loading = true

        const files = this.$store.state.image.files
        if (files.length === 0) {
          this.uploadCardContent(inputContent, [])
          return
        }

        CardModel.uploadImages(files).then((data) => {
          const filesIds = data.map(fileVO => fileVO.id)
          this.uploadCardContent(inputContent, filesIds)
        }).catch((error) => {
          this.resetLoading()
        })
      },
      uploadCardContent (inputContent, filesIds) {

        const tag = this.$store.state.card.cardTag
        const tagType = this.getTagType(tag)

        CardModel.publish({
          content: inputContent,
          fileIds: filesIds,
          type: tagType
        }).then((data) => {
          this.$emit('publishSuccess', data)
          this.$store.commit('publishSuccess')
          this.resetLoading()
        }).catch((error) => {
          this.resetLoading()
        })

      },
      getTagType (tag) {
        switch (tag) {
          case '学习':
            return 'STUDY'
          case '运动':
            return 'SPORT'
          case '上班摸鱼':
            return 'LAZY'
          case '今天学到了':
            return 'LEARNED_TODAY'
          case 'BUG':
            return 'BUG'
          case '建议':
            return 'ADVICE'
        }
      },
      resetLoading () {
        this.isDisabled = false
        this.loading = false
      },
    }
  }
</script>

<style scoped lang="scss">
    @import "src/assets/Sizes";
    @import "src/assets/Colors";

    .card-navigation {
        width: $card-input-width;
        display: flex;
        margin-top: 10px;
        align-items: center;
        justify-content: space-between;

        .right-label {
            width: 260px;
        }

        .submit {
            width: 72px;
            height: 32px;
            line-height: 32px;
            cursor: pointer;
            color: white;
            padding: 6px;
            background-color: $primary;
        }

        .click-disable {
            pointer-events: none;
        }
    }

</style>
