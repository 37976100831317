<template>
    <div class="card-summary">
        <div class="name">{{username}}</div>
        <div class="tag">
            <div>沸点主理人</div>
            <div class="source">@Do</div>
            <div>{{publishTime}}</div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'CardSummary',
    props: {
      username: String,
      publishTime: String
    }
  }
</script>

<style scoped lang="scss">
    .card-summary {
        display: flex;
        flex: 1;
        margin-left: 10px;
        height: 60px;
        flex-direction: column;
        align-items: flex-start;
        justify-content: space-between;

        .name {
            font-weight: bold;
            font-size: 18px;
        }

        .tag {
            width: 100%;
            display: flex;
            font-size: 14px;

            .source {
                margin-left: 4px;
            }

            div:last-child {
                flex: 1;
                text-align: right;
                margin-right: 20px;
            }
        }
    }
</style>
