<template>
    <div class="card" v-loading="loading">
        <card-input-area @refreshCardContent="refreshCardContent"/>

        <card-content
                :right-label="rightLabel"
                :card-contents="cardContents"
                :total="total"
                :page="page"
                @initData="getCardContents"
                @infiniteScroll="getCardContents"
        />
    </div>
</template>

<script>
  import CardContentItem from '@/pages/home/card/content/CardContentItem'
  import VueDataLoading from 'vue-data-loading'
  import CardInputArea from '@/pages/home/card/input/CardInputArea'
  import CardModel from '@/model/CardModel'
  import CardContent from '@/pages/home/card/content/CardContent'

  export default {
    name: 'Card',
    components: {CardContent, CardInputArea, CardContentItem, VueDataLoading},
    data () {
      return {
        cardContents: [],
        page: 0,
        size: 10,
        total: 0,
        loading: true,
        rightLabel: '关注'
      }
    },
    created () {
      this.loading = true
      this.$store.commit('clearImageFiles')
    },
    methods: {
      getCardContents (page) {
        this.page = page
        CardModel.getContent({
          page: page,
          size: this.size,
          cardType: this.$store.getters.getTagType
        }).then(data => {
          this.total = data.total
          this.cardContents = this.cardContents.concat(data.list)
          this.loading = false
        }).catch((error) => {
          this.loading = false
        })
      },
      refreshCardContent (newCard) {
        this.cardContents.unshift(newCard)
      },
    },
    watch: {
      '$store.state.card.selectTag': function () {
        this.page = 0
        this.loading = true

        CardModel.getContent({
          page: this.page,
          size: this.size,
          cardType: this.$store.getters.getTagType
        }).then((data) => {
          this.total = data.total
          this.cardContents = data.list
          this.loading = false
        }).catch((error) => {
          this.loading = false
        })
      }
    }
  }
</script>

<style scoped lang="scss">
    @import "src/assets/Sizes";

    .card {
        width: $card-width;

        .no-more {
            height: 100px;
            margin-top: 30px;
        }
    }

</style>
