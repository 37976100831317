<template>
    <div class="blog-single-input">
        <div class="name">{{title}}</div>
        <el-input
                type="text"
                :value="value"
                v-bind="$attrs"
                @input="updateVal"
        >
        </el-input>
    </div>
</template>

<script>
  export default {
    name: 'BlogSingleInput',
    props: {
      value: [String, Number],
      title: String,
    },
    methods: {
      updateVal: function (val) {
        this.$emit('input', val)
      }
    }
  }
</script>

<style scoped lang="scss">
    .blog-single-input {
        width: 500px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-top: 10px;
        margin-bottom: 10px;

        .name {
            margin-right: 20px;
        }

        .el-input {
            width: 400px;
        }
    }

</style>
