import httpClient from '@/http/Axios'
import {alienBaseUrl} from "@/http/AirshipAlienUrl";
import {doAppId} from '@/http/appId'

export class UserModel {

  static tokenRefreshUrl = "/token/refresh"

  static login (data) {
    return httpClient.post(alienBaseUrl() + '/email-account/login', data)
  }

  static register(data) {
    return httpClient.post(alienBaseUrl() + '/email-account', data)
  }

  static refreshToken(data) {
    return httpClient.post(alienBaseUrl() + this.tokenRefreshUrl, data)
  }

  static signIn() {
    return httpClient.post('/user/sign-in', {})
  }

  static getSignInState () {
    return httpClient.get('/user/sign-in/state')
  }

  static sendEmailCode (params) {
    return httpClient.post(alienBaseUrl() + '/email-account/code', params)
  }

  static resetPassword (params) {
    return httpClient.post('/user/reset/password', params)
  }

  static updateName(params) {
    return httpClient.put(alienBaseUrl() + '/api/user', params)
  }

  static getWechatCode(params) {
    return httpClient.get(alienBaseUrl() + '/wechat/qr-code', params)
  }

  static getCodeInfo(params) {
    return httpClient.get(alienBaseUrl() + '/wechat/qr-code/info', {params: params})
  }

  static qqAuth() {
    return alienBaseUrl() + '/qq-connect/auth?appId=' + doAppId
  }

  static qqCheck(params) {
    return httpClient.get(alienBaseUrl() + '/qq-connect/check', {params: params})
  }
}
