import {MessageBox} from 'element-ui'

export function showCloseDialog (message) {
  return MessageBox.confirm(message, '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    type: 'warning'
  })
}

export function showPrompt (message, inputPlaceholder = '') {
  return MessageBox.prompt(message, '提示', {
    confirmButtonText: '确定',
    cancelButtonText: '取消',
    inputPlaceholder: inputPlaceholder,
    type: 'info'
  })
}
