<template>
    <div class="part-navigation">
        <div v-for="(navigation, index) in navigations" :key="index">
            <div @click="clickItem(index)" class="home-navigation-item" :class="{select:selectIndex===index}">
                {{navigation}}
            </div>
        </div>
    </div>
</template>

<script>
  export default {
    name: 'PartNavigation',
    props: {
      navigations: Array
    },
    data () {
      return {
        selectIndex: 0
      }
    },
    methods: {
      clickItem (index) {
        this.selectIndex = index
        this.$emit('selectItem', index)
      }
    }
  }
</script>

<style scoped lang="scss">
    @import "src/assets/Colors";

    .home-navigation-item {
        vertical-align: center;
        padding-top: 10px;
        padding-bottom: 10px;
        cursor: pointer;

        &:hover {
            background-color: #f6f6f6;
        }

        &.select {
            color: white;
            background-color: $primary;
        }
    }
</style>
