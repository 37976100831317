<template>
    <div class="footer-bar">Foot-bar</div>
</template>

<script>
  export default {
    name: 'FooterBar'
  }
</script>

<style scoped lang="scss">
    .footer-bar {
        width: 100%;
        background: #fff;
        border-top: 1px solid #f3f5f6;
        font-size: 12px;
        color: #93999F;
        padding: 36px 0;
        position: relative;
        z-index: 21;
        font-weight: 300;
    }
</style>
