<template>
    <div class="almanac-desc-item-container">
        <div class="name">{{ nameStr() }}: </div>
        <div :class="name"/>
    </div>
</template>

<script>
export default {
    name: 'almanac-desc-item',
    props: {
        name: String
    },
    methods: {
        nameStr () {
            switch (this.name) {
                case 'middle':
                    return '偷偷卷一下'
                case 'more':
                    return '卷到离谱'
                case 'most':
                    return '卷的飞起'
            }
        }
    },
}
</script>

<style scoped lang="scss">
@import "src/assets/Colors";

.almanac-desc-item-container {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-left: 20px;

    .name {
        font-weight: bold;
        margin-right: 6px;
    }

    .most {
        width: 20px;
        height: 20px;
        background-color: $primary;
    }

    .more {
        width: 20px;
        height: 20px;
        background-color: rgba(44, 62, 80, 0.8);
    }

    .middle {
        width: 20px;
        height: 20px;
        background-color: rgba(44, 62, 80, 0.5);
    }
}


</style>
