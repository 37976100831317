<template>
    <div class="user-navigation">
        <div class="personal-home-page" @click="goToPersonalPage">
            <img src="../../../assets/icons/personal_home.png" alt="">
            <span>个人主页</span>
        </div>
        <div class="logout" @click="logout">
            <img src="../../../assets/icons/exit.png" alt="">
            <span>退出</span>
        </div>
    </div>
</template>

<script>
  import {MessageBox} from 'element-ui'
  import {showSuccess, showTip} from '@/utils/Message'

  export default {
    name: 'UserNavigation',
    inject:  ['reload'],
    methods: {
      logout () {

        MessageBox.confirm('确定要退出登录吗？', '提示', {
          confirmButtonText: '确定',
          cancelButtonText: '取消',
          type: 'warning'
        }).then(() => {
          showSuccess('退出成功')
          this.$store.commit('logout')

          if (this.$router.currentRoute.path === '/personal/personalSetting') {
            this.$router.push('/')
          }

          this.reload()
        }).catch(() => {
          this.$store.commit('hideUserNavigation')
        })
      },
      goToPersonalPage () {
        this.$router.push('/personal/personalSetting')
        this.$store.commit('hideUserNavigation')
      }
    }
  }
</script>

<style scoped lang="scss">
    .user-navigation {
        width: 160px;
        display: flex;
        z-index: 100;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        background-color: white;
        border-radius: 4px;
        box-shadow: 2px 2px 2px 2px #CECECE;

        div {
            width: 90%;
            padding: 10px;
            font-size: 14px;
            font-weight: normal;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            img {
                width: 20px;
                margin-right: 10px;
            }

            &:hover {
                background-color: #ececec;
                cursor: pointer;
            }
        }

        .personal-home-page {
            margin-top: 10px;
        }
    }
</style>
